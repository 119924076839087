import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "../ui/card.jsx";
import { Button } from "../ui/button.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { Input } from "../ui/input.jsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.jsx";
import { cn } from "../../lib/utils.js";
import { motion } from "framer-motion";
import { 
  Plus, 
  Search, 
  Calendar,
  Clock,
  Users,
  Music,
  ArrowUpDown,
  Filter,
  CheckCircle2,
  CircleDashed,
  CircleDot,
  Trash2,
  Edit,
  ActivityIcon,
  AlertCircle
} from "lucide-react";
import {
  getProjects,
  addProject,
  updateProject,
  deleteProject,
  addTaskToProject,
  toggleTaskCompletion,
  deleteTask,
  addCollaborator,
  removeCollaborator,
  addTrackToProject,
  updateTrack,
  deleteTrack
} from '../../lib/supabase.js';
import {
  PROJECT_STATUSES,
  INITIAL_PROJECT
} from '../../data/projectConstants.js';
import MobileProjectDetails from './MobileProjectDetails.js';

const getStatusIcon = (status) => {
  switch (status.toLowerCase()) {
    case 'COMPLETE':
      return <CheckCircle2 className="h-4 w-4 text-green-500" />;
    case 'IN_PROGRESS':
      return <CircleDot className="h-4 w-4 text-blue-500" />;
    default:
      return <CircleDashed className="h-4 w-4 text-muted-foreground" />;
  }
};

const getStatusColor = (status) => {
  const statusInfo = PROJECT_STATUSES[status] || PROJECT_STATUSES.PLANNING;
  return `${statusInfo.lightColor} ${statusInfo.textColor} border ${statusInfo.borderColor}`;
};

const MobileProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [sortOrder, setSortOrder] = useState('desc');
  const [showAddForm, setShowAddForm] = useState(false);
  const [newProject, setNewProject] = useState(INITIAL_PROJECT);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const [editingTrack, setEditingTrack] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await getProjects();
      setProjects(data);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching projects:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddProject = async (e) => {
    e.preventDefault();
    if (!newProject.title || !newProject.artist || !newProject.status) {
      setError('Please fill in all required fields (Title, Artist, and Status)');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const formattedProject = {
        ...newProject,
        deadline: newProject.deadline || null,
        startDate: newProject.startDate || null,
        budget: newProject.budget ? parseFloat(newProject.budget) : null,
        tasks: Array.isArray(newProject.tasks) ? newProject.tasks : [],
        collaborators: Array.isArray(newProject.collaborators) ? newProject.collaborators : [],
        tracks: Array.isArray(newProject.tracks) ? newProject.tracks : []
      };

      if (isEditMode) {
        const updatedProject = await updateProject(formattedProject);
        if (updatedProject) {
          await fetchProjects();
        }
      } else {
        const newProjectResult = await addProject(formattedProject);
        if (newProjectResult) {
          await fetchProjects();
        }
      }
      setShowAddForm(false);
      setIsEditMode(false);
      setNewProject(INITIAL_PROJECT);
      setSelectedProject(null);
    } catch (err) {
      setError(err.message);
      console.error('Error adding/updating project:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditProject = (project) => {
    setNewProject({
      ...INITIAL_PROJECT,
      ...project,
    });
    setIsEditMode(true);
    setSelectedProject(project);
    setShowAddForm(true);
    setError(null);
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await deleteProject(projectId);
        if (success) {
          await fetchProjects();
        }
      } catch (err) {
        setError(err.message);
        console.error('Error deleting project:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const filteredProjects = projects
    .filter(project => {
      const matchesSearch = 
        project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.artist.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.producer?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = statusFilter === 'ALL' || project.status === statusFilter;
      
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => new Date(b.deadline) - new Date(a.deadline));

  const calculateProgress = (project) => {
    let totalWeight = 0;
    let completedWeight = 0;

    if (project.tasks && project.tasks.length > 0) {
      const completedTasks = project.tasks.filter(task => task.completed).length;
      totalWeight += 40;
      completedWeight += (completedTasks / project.tasks.length) * 40;
    }

    if (project.tracks && project.tracks.length > 0) {
      totalWeight += 60;
      project.tracks.forEach(track => {
        let trackProgress = 0;
        switch (track.status) {
          case 'WRITING': trackProgress = 0.2; break;
          case 'RECORDING': trackProgress = 0.4; break;
          case 'MIXING': trackProgress = 0.6; break;
          case 'MASTERING': trackProgress = 0.8; break;
          case 'COMPLETE': trackProgress = 1; break;
          default: trackProgress = 0;
        }
        completedWeight += (trackProgress * 60) / project.tracks.length;
      });
    }

    return totalWeight === 0 ? 0 : Math.round((completedWeight / totalWeight) * 100);
  };

  const getProgressBarColor = (progress) => {
    if (progress === 100) return 'bg-green-500';
    if (progress >= 80) return 'bg-emerald-500';
    if (progress >= 60) return 'bg-blue-500';
    if (progress >= 40) return 'bg-amber-500';
    if (progress >= 20) return 'bg-orange-500';
    return 'bg-red-500';
  };

  const handleViewDetails = (project) => {
    setSelectedProject(project);
  };

  const handleCloseDetails = () => {
    setSelectedProject(null);
  };

  const handleAddTask = async (task) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await addTaskToProject(selectedProject.id, task);
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error adding task:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleTask = async (taskId) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await toggleTaskCompletion(selectedProject.id, taskId);
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error toggling task:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTask = async (taskId) => {
    setIsLoading(true);
    setError(null);
    try {
      const updatedTasks = selectedProject.tasks.filter(task => task.id !== taskId);
      const success = await updateProject({
        ...selectedProject,
        tasks: updatedTasks
      });
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error deleting task:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCollaborator = async (collaborator) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await addCollaborator(selectedProject.id, collaborator);
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error adding collaborator:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveCollaborator = async (collaborator) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await removeCollaborator(selectedProject.id, collaborator);
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error removing collaborator:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTrack = async (track) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await addTrackToProject(selectedProject.id, {
        ...track,
        status: track.status || 'WRITING'
      });
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === selectedProject.id);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error adding track:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTrack = async (projectId, trackId, updatedTrack) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await updateTrack(projectId, trackId, updatedTrack);
      if (success) {
        await fetchProjects();
        const updatedProject = projects.find(p => p.id === projectId);
        setSelectedProject(updatedProject);
      }
    } catch (err) {
      setError(err.message);
      console.error('Error updating track:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTrack = async (projectId, trackId) => {
    if (window.confirm('Are you sure you want to delete this track?')) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await deleteTrack(projectId, trackId);
        if (success) {
          await fetchProjects();
          const updatedProject = projects.find(p => p.id === projectId);
          setSelectedProject(updatedProject);
        }
      } catch (err) {
        setError(err.message);
        console.error('Error deleting track:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (selectedProject) {
    return (
      <MobileProjectDetails
        project={selectedProject}
        onClose={handleCloseDetails}
        onAddTask={handleAddTask}
        onToggleTask={handleToggleTask}
        onDeleteTask={handleDeleteTask}
        onAddTrack={handleAddTrack}
        onEditTrack={handleEditTrack}
        onDeleteTrack={handleDeleteTrack}
        onAddCollaborator={handleAddCollaborator}
        onRemoveCollaborator={handleRemoveCollaborator}
      />
    );
  }

  return (
    <div className="space-y-4">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold">Projects</h2>
          <p className="text-muted-foreground">Manage your releases and projects</p>
        </div>
        <Button 
          onClick={() => {
            setShowAddForm(!showAddForm);
            if (!showAddForm) {
              setNewProject(INITIAL_PROJECT);
              setIsEditMode(false);
              setError(null);
            }
          }}
          className="gap-2"
          disabled={isLoading}
        >
          <Plus className="h-4 w-4" />
          New Project
        </Button>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex items-center justify-center p-8">
          <ActivityIcon className="h-6 w-6 animate-spin" />
          <span className="ml-2">Loading...</span>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="bg-destructive/10 text-destructive p-4 rounded-lg flex items-center gap-2">
          <AlertCircleIcon className="h-5 w-5" />
          {error}
        </div>
      )}

      {/* Add Project Form */}
      {showAddForm && (
        <Card>
          <CardContent className="p-4 space-y-4">
            <form onSubmit={handleAddProject} className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Title</label>
                <Input
                  value={newProject.title}
                  onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
                  placeholder="Project title"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Artist</label>
                <Input
                  value={newProject.artist}
                  onChange={(e) => setNewProject({ ...newProject, artist: e.target.value })}
                  placeholder="Artist name"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label className="text-sm font-medium">Deadline</label>
                  <Input
                    type="date"
                    value={newProject.deadline}
                    onChange={(e) => setNewProject({ ...newProject, deadline: e.target.value })}
                    required
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-sm font-medium">Budget</label>
                  <Input
                    type="number"
                    value={newProject.budget}
                    onChange={(e) => setNewProject({ ...newProject, budget: e.target.value })}
                    placeholder="Budget amount"
                    required
                  />
                </div>
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Status</label>
                <Select
                  value={newProject.status}
                  onValueChange={(value) => setNewProject({ ...newProject, status: value })}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(PROJECT_STATUSES).map(([key, { label }]) => (
                      <SelectItem key={key} value={key}>{label}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex gap-2">
                <Button type="submit" className="flex-1" disabled={isLoading}>
                  {isEditMode ? 'Update Project' : 'Add Project'}
                </Button>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => {
                    setShowAddForm(false);
                    setIsEditMode(false);
                    setNewProject(INITIAL_PROJECT);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      {/* Filters */}
      <Card>
        <CardContent className="p-4 space-y-4">
          <div className="flex flex-col gap-4">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search projects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
            </div>
            <div className="flex gap-2">
              <Select value={statusFilter} onValueChange={setStatusFilter}>
                <SelectTrigger className="w-[140px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ALL">All Status</SelectItem>
                  {Object.entries(PROJECT_STATUSES).map(([key, { label }]) => (
                    <SelectItem key={key} value={key}>{label}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setSortOrder(order => order === 'asc' ? 'desc' : 'asc')}
                className="shrink-0"
              >
                <ArrowUpDown className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Projects List */}
      <ScrollArea className="h-[calc(100vh-24rem)]">
        <div className="space-y-4">
          {filteredProjects.map((project) => (
            <Card key={project.id}>
              <CardContent className="p-4">
                <div className="space-y-4">
                  <div className="flex items-start justify-between">
                    <div>
                      <h3 className="font-semibold">{project.title}</h3>
                      <p className="text-sm text-muted-foreground">{project.artist}</p>
                    </div>
                    <Badge className={cn("", getStatusColor(project.status))}>
                      <span className="flex items-center gap-1.5">
                        {getStatusIcon(project.status)}
                        {PROJECT_STATUSES[project.status]?.label || project.status}
                      </span>
                    </Badge>
                  </div>
                  <div className="space-y-2">
                    <div className="w-full bg-muted rounded-full h-2 overflow-hidden">
                      <div
                        className={`${getProgressBarColor(calculateProgress(project))} h-2 rounded-full transition-all duration-500 ease-in-out`}
                        style={{ width: `${calculateProgress(project)}%` }}
                      ></div>
                    </div>
                    <div className="flex items-center justify-between text-xs">
                      <span className="font-medium">{calculateProgress(project)}%</span>
                      {project.tracks?.length > 0 && (
                        <span className="text-muted-foreground">
                          {project.tracks.filter(t => t.status === 'COMPLETE').length}/{project.tracks.length} Tracks
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-sm">
                    <div className="flex items-center gap-2 text-muted-foreground">
                      <Calendar className="h-4 w-4" />
                      <span>{new Date(project.deadline).toLocaleDateString()}</span>
                    </div>
                    <div className="flex items-center gap-2 text-muted-foreground">
                      <Clock className="h-4 w-4" />
                      <span>{project.tracks?.length || 0} songs</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <Button 
                      variant="outline" 
                      className="w-full gap-2"
                      onClick={() => handleViewDetails(project)}
                    >
                      <Music className="h-4 w-4" />
                      View Details
                    </Button>
                    <Button 
                      variant="outline" 
                      className="w-full gap-2"
                      onClick={() => handleEditProject(project)}
                    >
                      <Edit className="h-4 w-4" />
                      Edit
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}

          {filteredProjects.length === 0 && (
            <div className="text-center py-8 text-muted-foreground">
              <Music className="h-12 w-12 mx-auto mb-4 opacity-50" />
              <p className="font-medium">No projects found</p>
              <p className="text-sm">
                {searchTerm || statusFilter !== 'ALL' 
                  ? "Try adjusting your filters"
                  : "Start by adding a new project"}
              </p>
            </div>
          )}
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileProjectManagement; 