import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs.jsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion.jsx";
import { motion } from "framer-motion";
import { cn } from "../../lib/utils.js";
import { 
  Music, 
  Calendar, 
  Building2, 
  Users, 
  TrendingUp, 
  ChevronRight, 
  History,
  Globe,
  Clock
} from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const StatCard = ({ title, value, icon: Icon, description }) => (
  <Card className="bg-card">
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <div>
        <CardTitle className="text-sm font-medium text-card-foreground">{title}</CardTitle>
        {description && (
          <CardDescription className="text-xs mt-1">{description}</CardDescription>
        )}
      </div>
      <div className="p-2 bg-primary/10 rounded-full">
        <Icon className="h-4 w-4 text-primary" />
      </div>
    </CardHeader>
    <CardContent>
      <div className="text-2xl font-bold text-card-foreground">{value}</div>
    </CardContent>
  </Card>
);

const MobileSummary = ({ catalog }) => {
  const getSummary = () => {
    const summary = {
      totalWorks: 0,
      recentReleases: [],
      upcomingReleases: [],
      artistStats: {},
      monthlyReleases: Array(12).fill(0),
      totalPublishers: new Set(),
      totalSongwriters: new Set(),
      languageDistribution: {}
    };

    const now = new Date();
    const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);

    Object.entries(catalog).forEach(([artist, songs]) => {
      summary.totalWorks += songs.length;
      summary.artistStats[artist] = { 
        totalSongs: songs.length, 
        recentReleases: 0, 
        upcomingReleases: 0,
        publishers: new Set(),
        songwriters: new Set(),
        languages: {}
      };

      songs.forEach(song => {
        const releaseDate = new Date(song.songDetails.releaseDate);
        if (!isNaN(releaseDate.getTime())) {
          if (releaseDate <= now && releaseDate >= thirtyDaysAgo) {
            summary.recentReleases.push({ ...song, artist });
            summary.artistStats[artist].recentReleases++;
          } else if (releaseDate > now) {
            summary.upcomingReleases.push({ ...song, artist });
            summary.artistStats[artist].upcomingReleases++;
          }

          if (releaseDate.getFullYear() === now.getFullYear()) {
            summary.monthlyReleases[releaseDate.getMonth()]++;
          }
        }

        song.publishingDetails.roles.forEach(role => {
          summary.totalPublishers.add(role.publisher);
          summary.totalSongwriters.add(role.songwriter);
          summary.artistStats[artist].publishers.add(role.publisher);
          summary.artistStats[artist].songwriters.add(role.songwriter);
        });

        const language = song.songDetails.language;
        summary.languageDistribution[language] = (summary.languageDistribution[language] || 0) + 1;
        summary.artistStats[artist].languages[language] = (summary.artistStats[artist].languages[language] || 0) + 1;
      });

      summary.artistStats[artist].publishers = summary.artistStats[artist].publishers.size;
      summary.artistStats[artist].songwriters = summary.artistStats[artist].songwriters.size;
    });

    summary.recentReleases.sort((a, b) => new Date(b.songDetails.releaseDate) - new Date(a.songDetails.releaseDate));
    summary.upcomingReleases.sort((a, b) => new Date(a.songDetails.releaseDate) - new Date(b.songDetails.releaseDate));

    summary.totalPublishers = summary.totalPublishers.size;
    summary.totalSongwriters = summary.totalSongwriters.size;
    summary.totalArtists = Object.keys(catalog).length;

    return summary;
  };

  const summary = getSummary();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const chartData = summary.monthlyReleases.map((count, index) => ({
    month: monthNames[index],
    releases: count
  }));

  const getLastUpdated = () => {
    let latestDate = new Date(0);
    Object.values(catalog).forEach(songs => {
      songs.forEach(song => {
        const releaseDate = new Date(song.songDetails.releaseDate);
        if (releaseDate > latestDate) {
          latestDate = releaseDate;
        }
      });
    });
    return latestDate;
  };

  const formatLastUpdated = (date) => {
    const now = new Date();
    const diff = now - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else {
      return 'Today';
    }
  };

  const lastUpdated = getLastUpdated();

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold">Catalog Summary</h1>
          <Badge 
            variant="outline" 
            className="flex items-center gap-1 bg-primary/5 border-primary/20 text-primary"
          >
            <History className="h-3 w-3" />
            {formatLastUpdated(lastUpdated)}
          </Badge>
        </div>
      </div>

      <ScrollArea className="flex-grow">
        <div className="p-4 space-y-6">
          {/* Stats Grid */}
          <div className="grid grid-cols-2 gap-3">
            <StatCard
              title="Total Works"
              value={summary.totalWorks}
              icon={Music}
              description="Total songs"
            />
            <StatCard
              title="Recent Releases"
              value={summary.recentReleases.length}
              icon={Calendar}
              description="Last 30 days"
            />
            <StatCard
              title="Publishers"
              value={summary.totalPublishers}
              icon={Building2}
              description="Publishing companies"
            />
            <StatCard
              title="Songwriters"
              value={summary.totalSongwriters}
              icon={Users}
              description="Active writers"
            />
          </div>

          {/* Monthly Releases Chart */}
          <Card className="bg-card">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-base">
                <TrendingUp className="h-4 w-4 text-primary" />
                Monthly Releases
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-[200px] w-full">
                <ResponsiveContainer>
                  <BarChart data={chartData}>
                    <XAxis 
                      dataKey="month" 
                      stroke="hsl(var(--muted-foreground))"
                      fontSize={10}
                      tickLine={false}
                    />
                    <YAxis 
                      stroke="hsl(var(--muted-foreground))"
                      fontSize={10}
                      tickLine={false}
                      axisLine={false}
                    />
                    <Tooltip 
                      cursor={{ fill: 'hsl(var(--muted))' }}
                      contentStyle={{ 
                        backgroundColor: "hsl(var(--card))",
                        border: "1px solid hsl(var(--border))",
                        borderRadius: "var(--radius)",
                        color: "hsl(var(--card-foreground))",
                        boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
                      }}
                    />
                    <Bar 
                      dataKey="releases" 
                      fill="hsl(var(--primary))"
                      radius={[4, 4, 0, 0]}
                      maxBarSize={30}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          {/* Recent and Upcoming Releases */}
          <Card className="bg-card">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-base">
                <Calendar className="h-4 w-4 text-primary" />
                Releases
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <Tabs defaultValue="recent" className="w-full">
                <TabsList className="w-full grid grid-cols-2">
                  <TabsTrigger value="recent">Recent</TabsTrigger>
                  <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
                </TabsList>
                <TabsContent value="recent" className="p-4">
                  <div className="space-y-2">
                    {summary.recentReleases.slice(0, 5).map((song, index) => (
                      <div 
                        key={index} 
                        className="flex items-center justify-between py-2 px-3 rounded-lg hover:bg-muted/50 transition-colors group"
                      >
                        <div className="flex items-center gap-2 min-w-0">
                          <Music className="h-4 w-4 text-primary flex-shrink-0" />
                          <div className="flex flex-col min-w-0">
                            <span className="truncate">{song.songDetails.title}</span>
                            <span className="text-sm text-muted-foreground truncate">{song.artist}</span>
                          </div>
                        </div>
                        <Badge variant="outline" className="flex-shrink-0 ml-2">
                          {song.songDetails.releaseDate}
                        </Badge>
                      </div>
                    ))}
                  </div>
                </TabsContent>
                <TabsContent value="upcoming" className="p-4">
                  <div className="space-y-2">
                    {summary.upcomingReleases.slice(0, 5).map((song, index) => (
                      <div 
                        key={index} 
                        className="flex items-center justify-between py-2 px-3 rounded-lg hover:bg-muted/50 transition-colors group"
                      >
                        <div className="flex items-center gap-2 min-w-0">
                          <Clock className="h-4 w-4 text-primary flex-shrink-0" />
                          <div className="flex flex-col min-w-0">
                            <span className="truncate">{song.songDetails.title}</span>
                            <span className="text-sm text-muted-foreground truncate">{song.artist}</span>
                          </div>
                        </div>
                        <Badge variant="outline" className="flex-shrink-0 ml-2">
                          {song.songDetails.releaseDate}
                        </Badge>
                      </div>
                    ))}
                  </div>
                </TabsContent>
              </Tabs>
            </CardContent>
          </Card>

          {/* Artist Statistics */}
          <Card className="bg-card">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-base">
                <Users className="h-4 w-4 text-primary" />
                Artist Statistics
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0">
              <Accordion type="single" collapsible>
                {Object.entries(summary.artistStats).map(([artist, stats], index) => (
                  <AccordionItem 
                    value={`item-${index}`} 
                    key={index}
                    className="border-border/50 px-4"
                  >
                    <AccordionTrigger className="hover:no-underline group py-3">
                      <span className="text-card-foreground group-hover:text-primary transition-colors flex items-center gap-2">
                        <Users className="h-4 w-4" />
                        {artist}
                      </span>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-3 pb-3">
                        <div className="grid grid-cols-2 gap-2">
                          <div className="p-2 rounded-lg bg-muted/50">
                            <p className="text-xs text-muted-foreground">Total Songs</p>
                            <p className="text-sm font-medium">{stats.totalSongs}</p>
                          </div>
                          <div className="p-2 rounded-lg bg-muted/50">
                            <p className="text-xs text-muted-foreground">Recent</p>
                            <p className="text-sm font-medium">{stats.recentReleases}</p>
                          </div>
                        </div>
                        <div className="space-y-2">
                          <p className="text-xs text-muted-foreground">Languages</p>
                          <div className="flex flex-wrap gap-2">
                            {Object.entries(stats.languages).map(([lang, count]) => (
                              <Badge 
                                key={lang} 
                                variant="secondary" 
                                className="bg-primary/10 text-primary"
                              >
                                {lang} ({count})
                              </Badge>
                            ))}
                          </div>
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </CardContent>
          </Card>

          {/* Language Distribution */}
          <Card className="bg-card">
            <CardHeader>
              <CardTitle className="flex items-center gap-2 text-base">
                <Globe className="h-4 w-4 text-primary" />
                Language Distribution
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-2 gap-3">
                {Object.entries(summary.languageDistribution).map(([language, count], index) => (
                  <div 
                    key={index} 
                    className="flex items-center p-2 rounded-lg bg-muted/30"
                  >
                    <Globe className="h-4 w-4 text-primary mr-2" />
                    <span className="mr-2">{language}</span>
                    <Badge 
                      variant="secondary" 
                      className="bg-primary/10 text-primary ml-auto"
                    >
                      {count}
                    </Badge>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileSummary; 