import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.jsx";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";
import { Label } from "../ui/label.jsx";
import { Loader2, Plus, Trash2, Save } from 'lucide-react';
import { cn } from "../../lib/utils.js";
import { openWhatsAppNotification } from '../../lib/utils.js';

const AddTrackForm = ({ onAddTrack, onCancel, artist }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [newTrack, setNewTrack] = useState({
    publishingDetails: {
      roles: [{ role: '', songwriter: '', percentage: '', publisher: '' }]
    },
    songDetails: {
      title: '',
      artist: artist,
      language: '',
      duration: '',
      releaseDate: ''
    },
    masterRecordingDetails: {
      version: '',
      isrc: '',
      upc: '',
      repertoireOwner: ''
    },
    link: ''
  });

  useEffect(() => {
    // Load temporary tracks from local storage on mount
    const existingTracks = JSON.parse(localStorage.getItem('temporaryTracks')) || [];
    setNewTrack(existingTracks[existingTracks.length - 1] || newTrack);
  }, []);

  const handleNewTrackChange = (field, value) => {
    setNewTrack(prev => {
      const updated = { ...prev };
      const fields = field.split('.');
      let current = updated;
      for (let i = 0; i < fields.length - 1; i++) {
        if (fields[i] === 'roles' && !isNaN(fields[i + 1])) {
          const index = parseInt(fields[i + 1]);
          if (!current.roles[index]) {
            current.roles[index] = {};
          }
          current = current.roles[index];
          i++;
        } else {
          if (!current[fields[i]]) {
            current[fields[i]] = {};
          }
          current = current[fields[i]];
        }
      }
      current[fields[fields.length - 1]] = value;
      return updated;
    });
  };

  const addRole = () => {
    setNewTrack(prev => ({
      ...prev,
      publishingDetails: {
        ...prev.publishingDetails,
        roles: [...prev.publishingDetails.roles, { role: '', songwriter: '', percentage: '', publisher: '' }]
      }
    }));
  };

  const removeRole = (index) => {
    setNewTrack(prev => ({
      ...prev,
      publishingDetails: {
        ...prev.publishingDetails,
        roles: prev.publishingDetails.roles.filter((_, i) => i !== index)
      }
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newTrack.songDetails.title) newErrors['title'] = 'Title is required';
    if (!newTrack.songDetails.releaseDate) newErrors['releaseDate'] = 'Release date is required';
    
    // Validate roles
    const roleErrors = newTrack.publishingDetails.roles.map(role => {
      const errors = {};
      if (!role.role) errors.role = 'Role is required';
      if (!role.songwriter) errors.songwriter = 'Songwriter is required';
      if (!role.percentage) errors.percentage = 'Percentage is required';
      if (role.percentage && (isNaN(role.percentage) || Number(role.percentage) < 0 || Number(role.percentage) > 100)) {
        errors.percentage = 'Percentage must be between 0 and 100';
      }
      return errors;
    });
    
    if (roleErrors.some(errors => Object.keys(errors).length > 0)) {
      newErrors.roles = roleErrors;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setIsLoading(true);
    try {
      const existingTracks = JSON.parse(localStorage.getItem('temporaryTracks')) || [];
      existingTracks.push(newTrack);
      localStorage.setItem('temporaryTracks', JSON.stringify(existingTracks));
      await onAddTrack(newTrack);
      openWhatsAppNotification(artist, newTrack.songDetails.title);
    } catch (error) {
      console.error('Error adding track:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="bg-card">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Plus className="h-5 w-5" />
          Add New Track
        </CardTitle>
        <CardDescription>Add a new track to {artist}'s catalog</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Song Details Section */}
          <section className="space-y-4">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">Song Details</h3>
              <div className="flex-grow h-px bg-border" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="title" className="font-medium">
                  Title <span className="text-destructive">*</span>
                </Label>
                <Input
                  id="title"
                  value={newTrack.songDetails.title}
                  onChange={(e) => handleNewTrackChange('songDetails.title', e.target.value)}
                  className={cn(errors.title && "border-destructive")}
                  placeholder="Enter song title"
                  required
                />
                {errors.title && (
                  <p className="text-sm text-destructive">{errors.title}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="artist">Artist</Label>
                <Input
                  id="artist"
                  value={newTrack.songDetails.artist}
                  className="bg-muted"
                  disabled
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="language">Language</Label>
                <Input
                  id="language"
                  value={newTrack.songDetails.language}
                  onChange={(e) => handleNewTrackChange('songDetails.language', e.target.value)}
                  placeholder="Enter language"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="duration">Duration</Label>
                <Input
                  id="duration"
                  value={newTrack.songDetails.duration}
                  onChange={(e) => handleNewTrackChange('songDetails.duration', e.target.value)}
                  placeholder="e.g., 3:45"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="releaseDate">
                  Release Date <span className="text-destructive">*</span>
                </Label>
                <Input
                  id="releaseDate"
                  type="date"
                  value={newTrack.songDetails.releaseDate}
                  onChange={(e) => handleNewTrackChange('songDetails.releaseDate', e.target.value)}
                  className={cn(errors.releaseDate && "border-destructive")}
                  required
                />
                {errors.releaseDate && (
                  <p className="text-sm text-destructive">{errors.releaseDate}</p>
                )}
              </div>
            </div>
          </section>

          {/* Publishing Details Section */}
          <section className="space-y-4">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">Publishing Details</h3>
              <div className="flex-grow h-px bg-border" />
            </div>
            {newTrack.publishingDetails.roles.map((role, index) => (
              <Card key={index} className="p-4 border bg-card/50">
                <div className="flex items-center justify-between mb-4">
                  <h4 className="font-medium">Role {index + 1}</h4>
                  {index > 0 && (
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => removeRole(index)}
                      className="text-destructive hover:text-destructive/90"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor={`role-${index}`}>
                      Role <span className="text-destructive">*</span>
                    </Label>
                    <Input
                      id={`role-${index}`}
                      value={role.role}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.role`, e.target.value)}
                      className={cn(errors.roles?.[index]?.role && "border-destructive")}
                      placeholder="e.g., Composer"
                    />
                    {errors.roles?.[index]?.role && (
                      <p className="text-sm text-destructive">{errors.roles[index].role}</p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`songwriter-${index}`}>
                      Songwriter <span className="text-destructive">*</span>
                    </Label>
                    <Input
                      id={`songwriter-${index}`}
                      value={role.songwriter}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.songwriter`, e.target.value)}
                      className={cn(errors.roles?.[index]?.songwriter && "border-destructive")}
                      placeholder="Enter songwriter name"
                    />
                    {errors.roles?.[index]?.songwriter && (
                      <p className="text-sm text-destructive">{errors.roles[index].songwriter}</p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`percentage-${index}`}>
                      Percentage <span className="text-destructive">*</span>
                    </Label>
                    <Input
                      id={`percentage-${index}`}
                      value={role.percentage}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.percentage`, e.target.value)}
                      className={cn(errors.roles?.[index]?.percentage && "border-destructive")}
                      placeholder="e.g., 50"
                      type="number"
                      min="0"
                      max="100"
                    />
                    {errors.roles?.[index]?.percentage && (
                      <p className="text-sm text-destructive">{errors.roles[index].percentage}</p>
                    )}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`publisher-${index}`}>Publisher</Label>
                    <Input
                      id={`publisher-${index}`}
                      value={role.publisher}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.publisher`, e.target.value)}
                      placeholder="Enter publisher name"
                    />
                  </div>
                </div>
              </Card>
            ))}
            <Button
              type="button"
              variant="outline"
              onClick={addRole}
              className="w-full"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Another Role
            </Button>
          </section>

          {/* Master Recording Details Section */}
          <section className="space-y-4">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">Master Recording Details</h3>
              <div className="flex-grow h-px bg-border" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="version">Version</Label>
                <Input
                  id="version"
                  value={newTrack.masterRecordingDetails.version}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.version', e.target.value)}
                  placeholder="e.g., Original Mix"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="isrc">ISRC</Label>
                <Input
                  id="isrc"
                  value={newTrack.masterRecordingDetails.isrc}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.isrc', e.target.value)}
                  placeholder="Enter ISRC code"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="upc">UPC</Label>
                <Input
                  id="upc"
                  value={newTrack.masterRecordingDetails.upc}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.upc', e.target.value)}
                  placeholder="Enter UPC code"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="repertoireOwner">Repertoire Owner</Label>
                <Input
                  id="repertoireOwner"
                  value={newTrack.masterRecordingDetails.repertoireOwner}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.repertoireOwner', e.target.value)}
                  placeholder="Enter repertoire owner"
                />
              </div>
            </div>
          </section>

          {/* YouTube Link Section */}
          <section className="space-y-4">
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">YouTube Link</h3>
              <div className="flex-grow h-px bg-border" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="link">Link</Label>
              <Input
                id="link"
                value={newTrack.link}
                onChange={(e) => handleNewTrackChange('link', e.target.value)}
                placeholder="Enter YouTube link"
                type="url"
              />
            </div>
          </section>

          <div className="flex justify-end gap-2 pt-4 border-t">
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Adding...
                </>
              ) : (
                <>
                  <Save className="mr-2 h-4 w-4" />
                  Add Track
                </>
              )}
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTrackForm;