import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../lib/AuthContext.js'
import { Button } from '../ui/button.jsx'
import { Input } from '../ui/input.jsx'
import { Label } from '../ui/label.jsx'
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card.jsx'
import { AlertCircleIcon, UserIcon } from 'lucide-react'

const Login = () => {
  const navigate = useNavigate()
  const { signIn, signInAsGuest } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setLoading(true)

    try {
      const { error } = await signIn({ email, password })
      if (error) throw error
      navigate('/')
    } catch (error) {
      console.error('Error signing in:', error)
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleGuestLogin = () => {
    signInAsGuest()
    navigate('/')
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <Card className="w-[350px] animate-glow-pulse">
        <CardHeader>
          <CardTitle>Sign In</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            {error && (
              <div className="bg-destructive/10 text-destructive p-3 rounded-lg flex items-center gap-2 text-sm">
                <AlertCircleIcon className="h-4 w-4" />
                {error}
              </div>
            )}
            
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <Button 
              type="submit" 
              className="w-full"
              disabled={loading}
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>

            <div className="relative my-4">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t" />
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-background px-2 text-muted-foreground">Or continue with</span>
              </div>
            </div>

            <Button 
              type="button"
              variant="outline" 
              className="w-full"
              onClick={handleGuestLogin}
            >
              <UserIcon className="mr-2 h-4 w-4" />
              Guest Login
            </Button>
          </form>
        </CardContent>
      </Card>
      <p className="text-xs text-muted-foreground mt-4">
        For access to the dashboard, please contact{' '}
        <a 
          href="mailto:min@slatan.xyz?subject=Request%20for%200108 SLATAN%20Dashboard%20Access&body=Hello%2C%0A%0AI%20would%20like%20to%20request%20access%20to%20the%200108 SLATAN%20Dashboard.%0A%0AThank%20you."
          className="text-primary hover:underline"
        >
          min@slatan.xyz
        </a>
      </p>
    </div>
  )
}

export default Login 