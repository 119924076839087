import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../lib/AuthContext.js'
import { ActivityIcon } from 'lucide-react'

const ProtectedRoute = ({ children }) => {
  const { user, loading, isGuestMode } = useAuth()

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center gap-2">
          <ActivityIcon className="h-5 w-5 animate-spin" />
          <span>Loading...</span>
        </div>
      </div>
    )
  }

  if (!user && !isGuestMode) {
    return <Navigate to="/login" />
  }

  return children
}

export default ProtectedRoute 