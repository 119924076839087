import React, { useState } from 'react';
import { useSearch } from '../../hooks/useSearch.js';
import { useCatalog } from '../../hooks/useCatalog.js';
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";
import { Card, CardContent } from "../ui/card.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { motion, AnimatePresence } from "framer-motion";
import SongDetails from '../catalogmanagement/SongDetails.js';
import AddTrackForm from '../catalogmanagement/AddTrackForm.js';
import MobileSongDetails from './MobileSongDetails.js';
import CopyrightTrackingSection from '../copyright/CopyrightTrackingSection.js';
import { LabelValuePair } from '../catalogmanagement/SongDetails.js';
import { 
  Search, 
  ArrowUp, 
  ArrowDown, 
  Edit, 
  Plus, 
  Download, 
  Music, 
  Save,
  ChevronLeft,
  Filter,
  X,
  ChevronRight
} from "lucide-react";
import { Badge } from "../ui/badge.jsx";
import { cn } from "../../lib/utils.js";

const MobileArtistTab = ({ artist, isGuestMode }) => {
  const { 
    catalog,
    setCatalog,
    editMode,
    setEditMode,
    saveChanges,
    downloadArtistCatalog,
    handleAddTrack 
  } = useCatalog();
  
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const { searchTerm, setSearchTerm, filteredCatalog, setFilteredCatalog, handleSearch, sortByReleaseDate, sortOrder } = useSearch(catalog);

  const handleEdit = (artist, index, field, value) => {
    if (isGuestMode) return;
    
    const updatedCatalog = { ...catalog };
    const updatedFilteredCatalog = { ...filteredCatalog };
    const fields = field.split('.');

    updatedCatalog[artist] = [...updatedCatalog[artist]];
    updatedCatalog[artist][index] = JSON.parse(JSON.stringify(updatedCatalog[artist][index]));

    let currentOriginal = updatedCatalog[artist][index];
    for (let i = 0; i < fields.length - 1; i++) {
      if (!currentOriginal[fields[i]]) {
        currentOriginal[fields[i]] = {};
      }
      currentOriginal = currentOriginal[fields[i]];
    }
    currentOriginal[fields[fields.length - 1]] = value;

    if (updatedFilteredCatalog[artist] && updatedFilteredCatalog[artist][index]) {
      updatedFilteredCatalog[artist] = [...updatedFilteredCatalog[artist]];
      updatedFilteredCatalog[artist][index] = JSON.parse(JSON.stringify(updatedCatalog[artist][index]));
    }

    setCatalog(updatedCatalog);
    setFilteredCatalog(updatedFilteredCatalog);
  };

  const handleAddNewTrack = () => {
    if (isGuestMode) return;
    setIsAddingTrack(true);
  };

  const handleTrackAdded = (newTrack) => {
    if (isGuestMode) return;
    handleAddTrack(artist, newTrack);
    setIsAddingTrack(false);
    
    if (searchTerm) {
      handleSearch(searchTerm);
    }
  };

  const unsortedSongs = searchTerm ? (filteredCatalog[artist] || []) : (catalog[artist] || []);
  const songs = [...unsortedSongs].map((song, originalIndex) => ({
    ...song,
    originalIndex
  })).sort((a, b) => {
    if (!a.songDetails.releaseDate || !b.songDetails.releaseDate) return 0;
    const dateA = new Date(a.songDetails.releaseDate);
    const dateB = new Date(b.songDetails.releaseDate);
    return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
  });

  const handleEditModeToggle = async () => {
    if (isGuestMode) return;
    
    if (editMode) {
      await saveChanges();
    }
    setEditMode(!editMode);
  };

  if (selectedSong) {
    const renderSongDetailsSection = (section) => {
      const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
      };

      return (
        <motion.div
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.3 }}
          className="space-y-4"
        >
          {section === 'details' && (
            <Card className="bg-card">
              <CardContent className="p-4 space-y-4">
                <div className="space-y-4">
                  <LabelValuePair
                    label="Title"
                    value={selectedSong.songDetails.title}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'songDetails.title', value)}
                  />
                  <LabelValuePair
                    label="Language"
                    value={selectedSong.songDetails.language}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'songDetails.language', value)}
                  />
                  <LabelValuePair
                    label="Duration"
                    value={selectedSong.songDetails.duration}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'songDetails.duration', value)}
                  />
                  <LabelValuePair
                    label="Release Date"
                    value={selectedSong.songDetails.releaseDate}
                    editMode={editMode}
                    type="date"
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'songDetails.releaseDate', value)}
                  />
                </div>
              </CardContent>
            </Card>
          )}

          {section === 'publishing' && (
            <Card className="bg-card">
              <CardContent className="p-4 space-y-4">
                {selectedSong.publishingDetails.roles.map((role, roleIndex) => (
                  <div 
                    key={roleIndex} 
                    className="p-4 rounded-lg bg-muted/30 hover:bg-muted/50 transition-all duration-200 border border-border/50"
                  >
                    <div className="font-medium text-sm text-primary/80 mb-3">Role {roleIndex + 1}</div>
                    <div className="space-y-4">
                      <LabelValuePair
                        label="Role"
                        value={role.role}
                        editMode={editMode}
                        onChange={(value) => handleEdit(artist, selectedSong.originalIndex, `publishingDetails.roles.${roleIndex}.role`, value)}
                      />
                      <LabelValuePair
                        label="Songwriter"
                        value={role.songwriter}
                        editMode={editMode}
                        onChange={(value) => handleEdit(artist, selectedSong.originalIndex, `publishingDetails.roles.${roleIndex}.songwriter`, value)}
                      />
                      <LabelValuePair
                        label="Percentage"
                        value={role.percentage}
                        editMode={editMode}
                        type="number"
                        onChange={(value) => handleEdit(artist, selectedSong.originalIndex, `publishingDetails.roles.${roleIndex}.percentage`, value)}
                      />
                      <LabelValuePair
                        label="Publisher"
                        value={role.publisher}
                        editMode={editMode}
                        onChange={(value) => handleEdit(artist, selectedSong.originalIndex, `publishingDetails.roles.${roleIndex}.publisher`, value)}
                      />
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}

          {section === 'master' && (
            <Card className="bg-card">
              <CardContent className="p-4 space-y-4">
                <div className="space-y-4">
                  <LabelValuePair
                    label="Version"
                    value={selectedSong.masterRecordingDetails.version}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'masterRecordingDetails.version', value)}
                  />
                  <LabelValuePair
                    label="ISRC"
                    value={selectedSong.masterRecordingDetails.isrc}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'masterRecordingDetails.isrc', value)}
                  />
                  <LabelValuePair
                    label="UPC"
                    value={selectedSong.masterRecordingDetails.upc}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'masterRecordingDetails.upc', value)}
                  />
                  <LabelValuePair
                    label="Repertoire Owner"
                    value={selectedSong.masterRecordingDetails.repertoireOwner}
                    editMode={editMode}
                    onChange={(value) => handleEdit(artist, selectedSong.originalIndex, 'masterRecordingDetails.repertoireOwner', value)}
                  />
                </div>
              </CardContent>
            </Card>
          )}

          {section === 'copyright' && (
            <Card className="bg-card">
              <CardContent className="p-4">
                <CopyrightTrackingSection 
                  song={selectedSong} 
                  artist={artist}
                  editMode={editMode}
                  onEdit={(field, value) => handleEdit(artist, selectedSong.originalIndex, field, value)}
                />
              </CardContent>
            </Card>
          )}
        </motion.div>
      );
    };

    return (
      <MobileSongDetails
        song={selectedSong}
        onClose={() => setSelectedSong(null)}
        editMode={editMode && !isGuestMode}
        onEdit={(field, value) => handleEdit(artist, selectedSong.originalIndex, field, value)}
      >
        <div className="details">
          {renderSongDetailsSection('details')}
        </div>
        <div className="publishing">
          {renderSongDetailsSection('publishing')}
        </div>
        <div className="master">
          {renderSongDetailsSection('master')}
        </div>
        <div className="copyright">
          {renderSongDetailsSection('copyright')}
        </div>
      </MobileSongDetails>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="flex items-center justify-between mb-3">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold tracking-tight">{artist}</h1>
            <p className="text-sm text-muted-foreground">
              {songs.length} tracks
            </p>
          </div>
          {!isGuestMode && (
            <div className="flex items-center gap-2">
              <Button 
                variant="outline"
                size="icon"
                onClick={() => downloadArtistCatalog(artist)}
                className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
              >
                <Download className="h-4 w-4" />
              </Button>
              <Button 
                onClick={handleAddNewTrack}
                size="icon"
                className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
              >
                <Plus className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground pointer-events-none" />
            <Input
              placeholder="Search tracks..."
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
              className="pl-9 pr-4 h-10 text-base"
              style={{ fontSize: '16px' }}
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7 rounded-full"
                onClick={() => handleSearch('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
          <Button
            variant={showFilters ? "secondary" : "outline"}
            size="icon"
            onClick={() => setShowFilters(!showFilters)}
            className="rounded-full h-10 w-10 active:scale-95 transition-transform duration-200"
          >
            <Filter className="h-4 w-4" />
          </Button>
        </div>

        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="flex items-center gap-2 mt-3">
                {!isGuestMode && (
                  <Button
                    variant={editMode ? "secondary" : "outline"}
                    size="sm"
                    onClick={handleEditModeToggle}
                    className="flex-1 active:scale-95 transition-transform duration-200"
                  >
                    <Edit className="h-4 w-4 mr-2" />
                    {editMode ? "Save Changes" : "Edit Mode"}
                  </Button>
                )}
                <Button
                  variant="outline"
                  size="sm"
                  onClick={sortByReleaseDate}
                  className="flex-1 active:scale-95 transition-transform duration-200"
                >
                  {sortOrder === 'asc' ? (
                    <ArrowUp className="h-4 w-4 mr-2" />
                  ) : (
                    <ArrowDown className="h-4 w-4 mr-2" />
                  )}
                  Sort by Date
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <ScrollArea className="flex-grow touch-manipulation">
        <div className="p-4 space-y-4">
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="space-y-4"
            >
              {isAddingTrack && !isGuestMode && (
                <Card>
                  <CardContent className="p-4">
                    <div className="flex items-center gap-2 mb-4">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setIsAddingTrack(false)}
                        className="flex items-center gap-1"
                      >
                        <ChevronLeft className="h-4 w-4" />
                        Back to Tracks
                      </Button>
                      <h3 className="font-medium">Add New Track</h3>
                    </div>
                    <AddTrackForm
                      onAddTrack={handleTrackAdded}
                      onCancel={() => setIsAddingTrack(false)}
                      artist={artist}
                    />
                  </CardContent>
                </Card>
              )}

              {songs.length === 0 && !isAddingTrack ? (
                <Card className="bg-muted/50">
                  <CardContent className="flex flex-col items-center justify-center py-8 text-center">
                    <Music className="h-12 w-12 text-muted-foreground mb-4" />
                    <h3 className="text-lg font-medium">No Tracks Found</h3>
                    <p className="text-muted-foreground">
                      {searchTerm ? "Try adjusting your search" : "Start by adding a track"}
                    </p>
                  </CardContent>
                </Card>
              ) : !isAddingTrack && (
                <div className="space-y-3">
                  {songs.map((song) => (
                    <Card 
                      key={song.originalIndex}
                      className="hover:bg-muted/50 transition-colors"
                    >
                      <CardContent className="p-4">
                        <button
                          onClick={() => setSelectedSong(song)}
                          className="w-full text-left"
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                              <h3 className="font-medium truncate">{song.songDetails.title}</h3>
                              <p className="text-sm text-muted-foreground truncate">
                                {new Date(song.songDetails.releaseDate).toLocaleDateString()}
                              </p>
                            </div>
                            <ChevronRight className="h-4 w-4 text-muted-foreground ml-2 flex-shrink-0" />
                          </div>
                        </button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileArtistTab; 