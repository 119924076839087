import React from 'react';
import { Card, CardContent } from "../ui/card.jsx";
import { Button } from "../ui/button.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { ExternalLinkIcon } from "lucide-react";

const MobileBelieveBackstage = () => {
  const openBelieveBackstage = () => {
    window.open('https://backstage.believemusic.com', '_blank');
  };

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <h1 className="text-lg font-semibold">Believe Backstage</h1>
      </div>

      <ScrollArea className="flex-grow touch-manipulation">
        <div className="p-4 space-y-4">
          <Card>
            <CardContent className="p-6 flex flex-col items-center justify-center space-y-4">
              <p className="text-center text-muted-foreground">
                Access your Believe Backstage account to manage your catalog and view detailed analytics.
              </p>
              
              <Button 
                onClick={openBelieveBackstage}
                className="w-full active:scale-95 transition-transform duration-200"
              >
                Open Believe Backstage
                <ExternalLinkIcon className="ml-2 h-4 w-4" />
              </Button>
            </CardContent>
          </Card>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileBelieveBackstage; 