import React, { useState, useTransition } from 'react';
import { Button } from "../ui/button.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from "../../lib/utils.js";
import { useAuth } from '../../lib/AuthContext.js';
import { useTheme } from "next-themes";
import { 
  HomeIcon, 
  CalendarIcon, 
  FolderIcon,
  BarChartIcon,
  InfoIcon,
  AppWindow,
  Menu,
  X,
  Search,
  MoonIcon,
  SunIcon,
  SquareArrowOutUpRight
} from "lucide-react";
import { Badge } from "../ui/badge.jsx";

// Mobile navigation menu items configuration
const getMobileMenuItems = (isGuestMode) => [
  { 
    id: 'summary', 
    icon: <HomeIcon className="h-5 w-5" />, 
    label: 'Summary',
    description: 'Dashboard overview',
    showInGuest: true
  },
  { 
    id: 'shows', 
    icon: <CalendarIcon className="h-5 w-5" />, 
    label: 'Shows',
    description: 'Event management',
    showInGuest: true
  },
  { 
    id: 'project-management', 
    icon: <FolderIcon className="h-5 w-5" />, 
    label: 'Projects',
    description: 'Project tracking',
    showInGuest: false
  },
  { 
    id: 'believe-report', 
    icon: <BarChartIcon className="h-5 w-5" />, 
    label: 'Reports',
    description: 'Financial reports',
    showInGuest: false
  }
].filter(item => !isGuestMode || item.showInGuest);

const MobileNav = ({ selectedArtist, setSelectedArtist, catalog, isGuestMode }) => {
  const [isPending, startTransition] = useTransition();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { theme, setTheme } = useTheme();
  const { signOut } = useAuth();

  const filteredArtists = Object.keys(catalog || {}).filter(artist => 
    artist.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const menuItems = getMobileMenuItems(isGuestMode);

  const handleArtistSelect = (artist) => {
    startTransition(() => {
      setSelectedArtist(artist);
      setIsMenuOpen(false);
    });
  };

  const handleMenuItemSelect = (id) => {
    startTransition(() => {
      setSelectedArtist(id);
      setIsMenuOpen(false);
    });
  };

  return (
    <>
      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-background/95 backdrop-blur-lg border-t border-border md:hidden z-50">
        <div className="flex items-center justify-around p-2 max-w-md mx-auto">
          {menuItems.slice(0, 4).map((item) => (
            <Button
              key={item.id}
              variant="ghost"
              size="sm"
              className={cn(
                "flex flex-col items-center gap-1.5 h-auto py-3 px-2 relative w-[20%] min-w-[64px] touch-manipulation",
                "active:scale-95 transition-transform duration-200",
                selectedArtist === item.id && [
                  "text-primary after:absolute after:bottom-0 after:left-2 after:right-2",
                  "after:h-0.5 after:bg-primary after:rounded-full after:transition-all after:duration-200"
                ]
              )}
              onClick={() => handleMenuItemSelect(item.id)}
            >
              {item.icon}
              <span className="text-[10px] font-medium leading-none">{item.label}</span>
            </Button>
          ))}
          <Button
            variant="ghost"
            size="sm"
            className="flex flex-col items-center gap-1.5 h-auto py-3 px-2 w-[20%] min-w-[64px] touch-manipulation active:scale-95 transition-transform duration-200"
            onClick={() => setIsMenuOpen(true)}
          >
            <Menu className="h-5 w-5" />
            <span className="text-[10px] font-medium leading-none">Menu</span>
          </Button>
        </div>
      </div>

      {/* Mobile Menu Overlay */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-background/98 backdrop-blur-md z-50 md:hidden"
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between p-4 border-b bg-background/50 backdrop-blur-sm sticky top-0">
                <div className="flex items-center gap-3">
                  <img 
                    src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png" 
                    alt="Logo" 
                    className="h-8 w-auto"
                  />
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold tracking-tight">SLATAN</h1>
                    {isGuestMode && (
                      <Badge variant="secondary" className="text-xs">
                        Guest Mode
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                    className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
                  >
                    {theme === "dark" ? 
                      <SunIcon className="h-5 w-5" /> : 
                      <MoonIcon className="h-5 w-5" />
                    }
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setIsMenuOpen(false)}
                    className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
                  >
                    <X className="h-5 w-5" />
                  </Button>
                </div>
              </div>

              <div className="p-4 border-b">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground pointer-events-none" />
                  <input
                    type="text"
                    placeholder="Search artists..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full pl-10 pr-4 py-2.5 rounded-full bg-muted/50 border-none focus:outline-none focus:ring-2 focus:ring-primary/20 placeholder:text-muted-foreground/70 text-base"
                    style={{ fontSize: '16px' }}
                  />
                  {searchQuery && (
                    <Button
                      variant="ghost"
                      size="icon"
                      className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7 rounded-full"
                      onClick={() => setSearchQuery('')}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>

              <ScrollArea className="flex-1 touch-manipulation">
                <div className="p-4 space-y-8">
                  {/* Quick Actions */}
                  <div>
                    <h2 className="text-sm font-semibold text-muted-foreground mb-4 px-2">
                      Quick Actions
                    </h2>
                    <div className="grid grid-cols-2 gap-3">
                      {menuItems.map((item) => (
                        <Button
                          key={item.id}
                          variant={selectedArtist === item.id ? 'secondary' : 'outline'}
                          className={cn(
                            "h-auto py-4 flex flex-col items-center gap-2 touch-manipulation",
                            "active:scale-95 transition-transform duration-200",
                            selectedArtist === item.id && "border-primary/50"
                          )}
                          onClick={() => handleMenuItemSelect(item.id)}
                        >
                          {item.icon}
                          <div className="text-center">
                            <div className="font-medium">{item.label}</div>
                            <div className="text-xs text-muted-foreground">{item.description}</div>
                          </div>
                        </Button>
                      ))}
                    </div>
                  </div>

                  {/* Artist Catalog */}
                  <div>
                    <h2 className="text-sm font-semibold text-muted-foreground mb-3 px-2">
                      Artist Catalog
                    </h2>
                    <div className="grid grid-cols-1 gap-1.5">
                      {filteredArtists.map((artist) => (
                        <Button
                          key={artist}
                          variant={selectedArtist === artist ? 'secondary' : 'ghost'}
                          className="w-full justify-start gap-3 h-12"
                          onClick={() => handleArtistSelect(artist)}
                        >
                          <span className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center text-sm font-medium">
                            {artist[0].toUpperCase()}
                          </span>
                          <span className="font-medium">{artist}</span>
                        </Button>
                      ))}
                      {filteredArtists.length === 0 && (
                        <div className="text-center py-8 text-muted-foreground">
                          No artists found
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Believe Integration */}
                  {!isGuestMode && (
                    <div>
                      <h2 className="text-sm font-semibold text-muted-foreground mb-3 px-2">
                        Believe
                      </h2>
                      <div className="grid grid-cols-1 gap-1.5">
                        <Button
                          variant={selectedArtist === 'believe-backstage' ? 'secondary' : 'ghost'}
                          className="w-full justify-start gap-3 h-12"
                          onClick={() => handleMenuItemSelect('believe-backstage')}
                        >
                          <AppWindow className="h-5 w-5" />
                          <span className="font-medium">Backstage</span>
                        </Button>
                      </div>
                    </div>
                  )}

                  {/* Settings & Info */}
                  <div>
                    <h2 className="text-sm font-semibold text-muted-foreground mb-3 px-2">
                      Settings & Info
                    </h2>
                    <div className="grid grid-cols-1 gap-1.5">
                      <Button
                        variant={selectedArtist === 'info' ? 'secondary' : 'ghost'}
                        className="w-full justify-start gap-3 h-12"
                        onClick={() => handleMenuItemSelect('info')}
                      >
                        <InfoIcon className="h-5 w-5" />
                        <span className="font-medium">About</span>
                      </Button>
                      <Button
                        variant="ghost"
                        className="w-full justify-start gap-3 h-12"
                        onClick={() => signOut()}
                      >
                        <SquareArrowOutUpRight className="h-5 w-5" />
                        <span className="font-medium">Sign Out</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </ScrollArea>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNav; 