import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../lib/AuthContext.js'
import { isAdmin } from '../../lib/supabase.js'
import { ActivityIcon } from 'lucide-react'

const AdminRoute = ({ children }) => {
  const { user, loading } = useAuth()
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [checkingAdmin, setCheckingAdmin] = useState(true)

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const adminStatus = await isAdmin()
        setIsAdminUser(adminStatus)
      }
      setCheckingAdmin(false)
    }

    checkAdminStatus()
  }, [user])

  if (loading || checkingAdmin) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="flex items-center gap-2">
          <ActivityIcon className="h-5 w-5 animate-spin" />
          <span>Loading...</span>
        </div>
      </div>
    )
  }

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!isAdminUser) {
    return <Navigate to="/" />
  }

  return children
}

export default AdminRoute 