import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card.jsx";
import { FileText, Music, Mic, Link as LinkIcon, ExternalLink, Calendar, Clock, Globe, Plus, Trash2, MessageSquare } from 'lucide-react';
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { cn } from "../../lib/utils.js";
import { motion } from "framer-motion";
import { Label } from "../ui/label.jsx";
import { Badge } from "../ui/badge.jsx";
import { Separator } from "../ui/separator.jsx";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs.jsx";
import CopyrightTrackingSection from '../copyright/CopyrightTrackingSection.js';

const MotionCard = motion(Card);

export const LabelValuePair = ({ label, value, editMode, onChange, type = "text", icon: Icon }) => {
  const handleDateChange = (e) => {
    const inputValue = e.target.value;
    
    // If it's a date input type change (from the date picker)
    if (e.target.type === 'date') {
      onChange(inputValue);
      return;
    }
    
    // For manual text input, try to parse the date
    const datePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
    const dateMatch = inputValue.match(datePattern);
    
    if (dateMatch) {
      // If it matches YYYY-MM-DD format, use it directly
      onChange(inputValue);
    } else {
      // Try to parse other date formats
      const parsedDate = new Date(inputValue);
      if (!isNaN(parsedDate.getTime())) {
        // Convert to YYYY-MM-DD format
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const day = String(parsedDate.getDate()).padStart(2, '0');
        onChange(`${year}-${month}-${day}`);
      } else {
        // If can't parse as date, pass the raw input
        onChange(inputValue);
      }
    }
  };

  return (
    <div className="space-y-1.5 group">
      <div className="text-sm font-medium text-muted-foreground flex items-center gap-2">
        {Icon && <Icon className="h-3.5 w-3.5" />}
        {label}
      </div>
      <div className="flex gap-2">
        {editMode ? (
          type === "date" ? (
            <div className="flex-1 relative">
              <Input
                type="text"
                value={value}
                onChange={handleDateChange}
                onBlur={(e) => {
                  if (e.target.type === 'text') {
                    e.target.type = 'date';
                  }
                }}
                onFocus={(e) => {
                  e.target.type = 'text';
                }}
                className={cn(
                  "transition-all duration-200",
                  "focus:ring-2 focus:ring-primary/20",
                  "bg-background/50 hover:bg-background"
                )}
                placeholder="YYYY-MM-DD or any date format"
              />
            </div>
          ) : (
            <Input
              type={type}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              className={cn(
                "transition-all duration-200",
                "focus:ring-2 focus:ring-primary/20",
                "bg-background/50 hover:bg-background"
              )}
              placeholder={`Enter ${label.toLowerCase()}`}
            />
          )
        ) : (
          <div className="flex-1 min-h-[2.5rem] flex items-center px-3 rounded-md bg-muted/30 group-hover:bg-muted/50 transition-colors">
            <span className="text-sm text-foreground">
              {value || <span className="text-muted-foreground italic">Not specified</span>}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const SongDetails = ({ artist, song, index, editMode, handleEdit }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const addNewRole = () => {
    const newRole = { role: '', songwriter: '', percentage: '', publisher: '' };
    const updatedRoles = [...song.publishingDetails.roles, newRole];
    handleEdit(artist, index, 'publishingDetails.roles', updatedRoles);
  };

  const removeRole = (roleIndex) => {
    const updatedRoles = song.publishingDetails.roles.filter((_, i) => i !== roleIndex);
    handleEdit(artist, index, 'publishingDetails.roles', updatedRoles);
  };

  return (
    <div className="space-y-6 py-4">
      <Tabs defaultValue="details">
        <TabsList>
          <TabsTrigger value="details">Song Details</TabsTrigger>
          <TabsTrigger value="publishing">Publishing</TabsTrigger>
          <TabsTrigger value="master">Master Recording</TabsTrigger>
          <TabsTrigger value="copyright">Copyright Tracking</TabsTrigger>
        </TabsList>

        <TabsContent value="publishing" className="space-y-4">
          <MotionCard 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0 }}
            className="group bg-card hover:shadow-md transition-all duration-200"
          >
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-semibold flex items-center justify-between text-primary">
                <div className="flex items-center">
                  <FileText className="mr-2 h-5 w-5 text-primary" />
                  Publishing Details
                </div>
                {editMode && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={addNewRole}
                    className="h-8"
                  >
                    <Plus className="h-4 w-4 mr-1" />
                    Add Role
                  </Button>
                )}
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-0 space-y-4">
              {song.publishingDetails.roles.map((role, roleIndex) => (
                <div 
                  key={roleIndex} 
                  className={cn(
                    "p-4 rounded-lg",
                    "bg-muted/30 hover:bg-muted/50",
                    "transition-all duration-200",
                    "border border-border/50"
                  )}
                >
                  <div className="flex items-center justify-between mb-3">
                    <div className="font-medium text-sm text-primary/80">Role {roleIndex + 1}</div>
                    {editMode && roleIndex > 0 && (
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => removeRole(roleIndex)}
                        className="h-8 text-destructive hover:text-destructive/90"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                  <div className="space-y-4">
                    <LabelValuePair
                      label="Role"
                      value={role.role}
                      editMode={editMode}
                      onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.role`, value)}
                    />
                    <LabelValuePair
                      label="Songwriter"
                      value={role.songwriter}
                      editMode={editMode}
                      onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.songwriter`, value)}
                    />
                    <LabelValuePair
                      label="Percentage"
                      value={role.percentage}
                      editMode={editMode}
                      type="number"
                      onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.percentage`, value)}
                    />
                    <LabelValuePair
                      label="Publisher"
                      value={role.publisher}
                      editMode={editMode}
                      onChange={(value) => handleEdit(artist, index, `publishingDetails.roles.${roleIndex}.publisher`, value)}
                    />
                  </div>
                </div>
              ))}
            </CardContent>
          </MotionCard>
        </TabsContent>

        <TabsContent value="details" className="space-y-4">
          <MotionCard 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.1 }}
            className="group bg-card hover:shadow-md transition-all duration-200"
          >
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-semibold flex items-center text-primary">
                <Music className="mr-2 h-5 w-5 text-primary" />
                Song Details
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-0 space-y-4">
              <div className="space-y-4">
                <LabelValuePair
                  label="Title"
                  value={song.songDetails.title}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'songDetails.title', value)}
                  icon={Music}
                />
                <LabelValuePair
                  label="Language"
                  value={song.songDetails.language}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'songDetails.language', value)}
                  icon={Globe}
                />
                <LabelValuePair
                  label="Duration"
                  value={song.songDetails.duration}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'songDetails.duration', value)}
                  icon={Clock}
                />
                <LabelValuePair
                  label="Release Date"
                  value={song.songDetails.releaseDate}
                  editMode={editMode}
                  type="date"
                  onChange={(value) => handleEdit(artist, index, 'songDetails.releaseDate', value)}
                  icon={Calendar}
                />
              </div>
            </CardContent>
          </MotionCard>
        </TabsContent>

        <TabsContent value="master" className="space-y-4">
          <MotionCard 
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.3, delay: 0.2 }}
            className="group bg-card hover:shadow-md transition-all duration-200"
          >
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-semibold flex items-center text-primary">
                <Mic className="mr-2 h-5 w-5 text-primary" />
                Master Recording Details
              </CardTitle>
            </CardHeader>
            <CardContent className="pt-0 space-y-4">
              <div className="space-y-4">
                <LabelValuePair
                  label="Version"
                  value={song.masterRecordingDetails.version}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.version', value)}
                />
                <LabelValuePair
                  label="ISRC"
                  value={song.masterRecordingDetails.isrc}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.isrc', value)}
                />
                <LabelValuePair
                  label="UPC"
                  value={song.masterRecordingDetails.upc}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.upc', value)}
                />
                <LabelValuePair
                  label="Repertoire Owner"
                  value={song.masterRecordingDetails.repertoireOwner}
                  editMode={editMode}
                  onChange={(value) => handleEdit(artist, index, 'masterRecordingDetails.repertoireOwner', value)}
                />
              </div>
            </CardContent>
          </MotionCard>
        </TabsContent>

        <TabsContent value="copyright" className="space-y-4">
          <CopyrightTrackingSection song={song} artist={artist} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SongDetails;