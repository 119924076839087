export const SHOW_TYPES = {
  FESTIVAL: 'Festival',
  CONCERT: 'Concert',
  CLUB_SHOW: 'Club Show',
  PRIVATE_EVENT: 'Private Event',
  LIVE_STREAM: 'Live Stream',
  SHOWCASE: 'Showcase'
};

export const SHOW_STATUS = {
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export const VENUE_TYPES = {
  OUTDOOR: 'Outdoor',
  INDOOR: 'Indoor',
  VIRTUAL: 'Virtual',
  HYBRID: 'Hybrid'
};

export const SHOW_FILTERS = {
  DATE: 'date',
  TYPE: 'type',
  VENUE: 'venue',
  ARTIST: 'artist'
};

export const DATE_RANGES = {
  TODAY: 'today',
  THIS_WEEK: 'this_week',
  THIS_MONTH: 'this_month',
  NEXT_MONTH: 'next_month',
  CUSTOM: 'custom'
};

export const SETLIST_TYPES = {
  ORIGINAL: 'Original',
  COVER: 'Cover'
};

export const SHOW_FIELDS = {
  REQUIRED: [
    'title',
    'type',
    'artists',
    'date',
    'venue',
    'venueType',
    'location',
    'description'
  ],
  OPTIONAL: [
    'ticketLink',
    'setlist',
    'imageUrl'
  ]
}; 