import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../ui/card.jsx";
import { useToast } from "../ui/use-toast.js";
import { 
  FileIcon, 
  FolderIcon, 
  SearchIcon, 
  ExternalLinkIcon, 
  FileTextIcon, 
  FileSpreadsheetIcon, 
  FileImageIcon,
  ChevronLeftIcon,
  DownloadIcon,
  EyeIcon,
  LockIcon,
  AlertCircleIcon,
  Loader2Icon,
  Clock,
  Calendar
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../ui/breadcrumb.jsx";
import { initDriveApi, getFolderContents, downloadFile } from '../../lib/driveApi.js';
import FilePreviewModal from './FilePreviewModal.jsx';
import { useAuth } from '../../lib/AuthContext.js';
import { Badge } from "../ui/badge.jsx";
import { FOLDERS, FOLDER_CATEGORIES, getFolderIcon } from '../../config/folders.js';
import { cn } from "../../lib/utils.js";

const MotionCard = motion(Card);

const DocumentsManagementTab = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderHistory, setFolderHistory] = useState([]);
  const [contents, setContents] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [initError, setInitError] = useState(null);
  const { toast } = useToast();
  const { isGuestMode, signOut } = useAuth();

  // Root level folders are now managed through the config
  const rootFolders = FOLDERS;

  // Group folders by category
  const groupedFolders = rootFolders.reduce((acc, folder) => {
    if (!acc[folder.category]) {
      acc[folder.category] = [];
    }
    acc[folder.category].push(folder);
    return acc;
  }, {});

  useEffect(() => {
    const initializeApi = async () => {
      try {
        setLoading(true);
        setInitError(null);
        const success = await initDriveApi();
        if (!success) {
          setInitError('Failed to initialize Google Drive API');
          toast({
            title: "Error",
            description: "Failed to initialize Google Drive API",
            variant: "destructive",
          });
        }
      } catch (error) {
        console.error('Initialization error:', error);
        setInitError(error.message);
        toast({
          title: "Error",
          description: "Failed to initialize Google Drive API: " + error.message,
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    initializeApi();
  }, []);

  const loadFolderContents = async (folder) => {
    setLoading(true);
    try {
      console.log('Loading contents for folder:', folder);
      const files = await getFolderContents(folder.folderId);
      
      if (!files) {
        throw new Error('No files returned from Google Drive API');
      }

      console.log('Received files:', files);
      
      // Ensure each file has the required links
      const processedFiles = files.map(file => {
        console.log('Processing file:', file);
        return {
          ...file,
          embedLink: file.embedLink || `https://drive.google.com/file/d/${file.id}/preview`,
          webViewLink: file.webViewLink || `https://drive.google.com/file/d/${file.id}/view`,
          webContentLink: file.webContentLink || `https://drive.google.com/uc?id=${file.id}&export=download`,
          restricted: folder.restricted
        };
      });
      
      console.log('Processed files:', processedFiles);
      setContents(processedFiles);
    } catch (error) {
      console.error('Error loading folder contents:', error);
      console.error('Folder ID:', folder.folderId);
      console.error('Full folder object:', folder);
      
      toast({
        title: "Error",
        description: error.message || "Failed to load folder contents. Please try again.",
        variant: "destructive",
      });
      
      // Clear contents on error to avoid showing stale data
      setContents([]);
    } finally {
      setLoading(false);
    }
  };

  const handleFolderClick = async (folder) => {
    if (isGuestMode && folder.restricted) {
      toast({
        title: "Access Denied",
        description: "You need to be logged in to access this folder",
        variant: "destructive",
      });
      return;
    }

    if (!folder.folderId) {
      toast({
        title: "Error",
        description: "Invalid folder configuration. Please contact support.",
        variant: "destructive",
      });
      return;
    }

    setCurrentFolder(folder);
    setFolderHistory([...folderHistory, folder]);
    await loadFolderContents(folder);
  };

  const handleBackClick = async () => {
    if (folderHistory.length > 0) {
      const newHistory = folderHistory.slice(0, -1);
      setFolderHistory(newHistory);
      const previousFolder = newHistory[newHistory.length - 1];
      setCurrentFolder(previousFolder);
      if (previousFolder) {
        await loadFolderContents(previousFolder);
      } else {
        setContents([]);
      }
    }
  };

  const handleBreadcrumbClick = async (index) => {
    if (index === -1) {
      setCurrentFolder(null);
      setFolderHistory([]);
      setContents([]);
    } else {
      const newHistory = folderHistory.slice(0, index + 1);
      setFolderHistory(newHistory);
      const targetFolder = newHistory[index];
      setCurrentFolder(targetFolder);
      await loadFolderContents(targetFolder);
    }
  };

  const handleFileAction = (file) => {
    if (isGuestMode && file.restricted) {
      toast({
        title: "Access Denied",
        description: "This file is not accessible in guest mode.",
        variant: "destructive",
      });
      return;
    }

    // Ensure we have all required file data
    if (!file.embedLink || !file.webViewLink || !file.webContentLink) {
      toast({
        title: "Error",
        description: "Unable to preview this file type",
        variant: "destructive",
      });
      return;
    }

    setSelectedFile(file);
    setIsPreviewOpen(true);
  };

  const handleDownload = async (file) => {
    if (isGuestMode) {
      toast({
        title: "Access Denied",
        description: "You need to be logged in to download files",
        variant: "destructive",
      });
      return;
    }
    try {
      await downloadFile(file.id, file.name);
      toast({
        title: "Success",
        description: "File download started",
      });
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: "Error",
        description: "Failed to download file",
        variant: "destructive",
      });
    }
  };

  const getCurrentContents = () => {
    if (!currentFolder) return rootFolders;
    return contents;
  };

  const filteredContents = getCurrentContents().filter(item => {
    const searchLower = searchQuery.toLowerCase();
    const nameMatch = item.name.toLowerCase().includes(searchLower);
    const descriptionMatch = item.description?.toLowerCase().includes(searchLower);
    
    // For files, also search in additional metadata
    if (item.type === 'file') {
      const sizeMatch = item.size?.toLowerCase().includes(searchLower);
      const dateMatch = item.modifiedDate?.toLowerCase().includes(searchLower);
      return nameMatch || descriptionMatch || sizeMatch || dateMatch;
    }
    
    return nameMatch || descriptionMatch;
  });

  // If we're at the root level and searching, flatten all folders for search
  const searchResults = !currentFolder && searchQuery ? 
    Object.values(groupedFolders).flatMap(folders => 
      folders.filter(item => {
        const searchLower = searchQuery.toLowerCase();
        return item.name.toLowerCase().includes(searchLower) ||
               item.description?.toLowerCase().includes(searchLower);
      })
    ) : filteredContents;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const renderFileCard = (item, index) => (
    <MotionCard
      key={item.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className={cn(
        "group hover:shadow-md transition-all duration-200",
        isGuestMode && item.restricted && "opacity-50"
      )}
    >
      <CardHeader 
        className="cursor-pointer space-y-2"
        onClick={() => item.type === 'folder' ? handleFolderClick(item) : handleFileAction(item)}
      >
        <div className="flex items-center justify-between">
          <CardTitle className="text-base truncate">
            {item.name}
          </CardTitle>
          {isGuestMode && item.restricted && (
            <LockIcon className="h-4 w-4 text-muted-foreground" />
          )}
        </div>
        {item.description && (
          <CardDescription className="text-sm">
            {item.description}
          </CardDescription>
        )}
      </CardHeader>
      {item.type === 'file' && (
        <>
          <CardContent className="pt-0">
            <div className="grid grid-cols-2 gap-4 text-sm text-muted-foreground">
              <div className="flex items-center gap-2">
                <Calendar className="h-4 w-4" />
                <span>{item.modifiedDate}</span>
              </div>
              <div className="flex items-center gap-2 justify-end">
                <Clock className="h-4 w-4" />
                <span>{item.size}</span>
              </div>
            </div>
          </CardContent>
          <CardFooter className="flex justify-end gap-2 pt-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(item);
              }}
              className="opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <DownloadIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleFileAction(item);
              }}
              className="opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <EyeIcon className="h-4 w-4" />
            </Button>
          </CardFooter>
        </>
      )}
    </MotionCard>
  );

  const renderFolderGroup = (category, folders) => (
    <div key={category} className="space-y-4">
      <h3 className="text-lg font-semibold flex items-center gap-2">
        {React.createElement(getFolderIcon(category), { className: "h-5 w-5 text-primary" })}
        {category.charAt(0).toUpperCase() + category.slice(1)}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {folders.map((item, index) => renderFileCard(item, index))}
      </div>
    </div>
  );

  if (initError) {
    return (
      <div className="h-full p-6">
        <Card className="border-destructive">
          <CardHeader>
            <div className="flex items-center space-x-2">
              <AlertCircleIcon className="h-5 w-5 text-destructive" />
              <CardTitle>Files Management</CardTitle>
            </div>
            <CardDescription className="text-destructive">
              {initError}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex justify-center">
              <Button 
                onClick={() => window.location.reload()}
                variant="outline"
                className="gap-2"
              >
                <Loader2Icon className="h-4 w-4" />
                <span>Retry</span>
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6"
    >
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold">Files Management</h2>
          <p className="text-muted-foreground">
            {isGuestMode 
              ? "Limited access to SLATAN files and documents. Please log in for full access."
              : "Access and manage all your SLATAN files and documents"}
          </p>
        </div>
        {isGuestMode && (
          <Badge variant="secondary" className="gap-2">
            <LockIcon className="h-3 w-3" />
            Guest Mode
          </Badge>
        )}
      </div>

      <Card>
        <CardContent className="p-4 space-y-4">
          {/* Breadcrumb Navigation */}
          <div className="flex items-center space-x-2 bg-muted/50 p-2 rounded-md">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={handleBackClick}
              disabled={!currentFolder}
              className="hover:bg-background"
            >
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink 
                    onClick={() => handleBreadcrumbClick(-1)}
                    className="hover:bg-background px-2 py-1 rounded-md"
                  >
                    Home
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {folderHistory.map((folder, index) => (
                  <React.Fragment key={folder.id}>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                      <BreadcrumbLink 
                        onClick={() => handleBreadcrumbClick(index)}
                        className="hover:bg-background px-2 py-1 rounded-md"
                      >
                        {folder.name}
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  </React.Fragment>
                ))}
              </BreadcrumbList>
            </Breadcrumb>
          </div>

          {/* Search Bar */}
          <div className="relative">
            <SearchIcon className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search files or folders..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-8"
            />
          </div>
        </CardContent>
      </Card>

      <AnimatePresence mode="wait">
        <div className="space-y-6">
          {loading ? (
            <Card className="bg-muted/50">
              <CardContent className="flex flex-col items-center justify-center py-8">
                <Loader2Icon className="h-8 w-8 animate-spin text-primary mb-4" />
                <p className="text-muted-foreground">Loading files...</p>
              </CardContent>
            </Card>
          ) : currentFolder ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {searchResults.map((item, index) => renderFileCard(item, index))}
            </div>
          ) : searchQuery ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {searchResults.map((item, index) => renderFileCard(item, index))}
            </div>
          ) : (
            Object.entries(groupedFolders).map(([category, folders]) => 
              renderFolderGroup(category, folders)
            )
          )}

          {!loading && searchResults.length === 0 && (
            <Card className="bg-muted/50">
              <CardContent className="flex flex-col items-center justify-center py-8 text-center">
                <SearchIcon className="h-12 w-12 text-muted-foreground mb-4" />
                <h3 className="text-lg font-medium">No Results Found</h3>
                <p className="text-muted-foreground">
                  {searchQuery 
                    ? "No files or folders match your search" 
                    : "This folder is empty"}
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </AnimatePresence>

      <FilePreviewModal
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        file={selectedFile}
      />
    </motion.div>
  );
};

export default DocumentsManagementTab; 