import React from 'react';
import { Card, CardContent } from "../ui/card.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { motion } from "framer-motion";
import { 
  FolderIcon,
  UsersIcon,
  CalendarIcon,
  DollarSignIcon,
  Shield,
  Search,
  MusicIcon,
  CheckCircleIcon,
  ClockIcon,
  BellIcon,
  BarChart2Icon,
  HeartIcon,
  Globe
} from "lucide-react";

const MobileInfo = () => {
  const features = [
    {
      icon: <FolderIcon className="h-5 w-5" />,
      title: "Project Management",
      description: "Album/EP releases hub",
      details: [
        "Create projects with title, artist, deadline, budget",
        "Track song stages (Writing → Mastering)",
        "Manage collaborators and features",
        "Monitor timelines and deadlines",
        "Task management with completion tracking"
      ]
    },
    {
      icon: <UsersIcon className="h-5 w-5" />,
      title: "Artist Catalog",
      description: "Artist & content management",
      details: [
        "Maintain detailed artist database",
        "Track releases and discography",
        "Store publishing information",
        "Master recording details"
      ]
    },
    {
      icon: <Shield className="h-5 w-5" />,
      title: "Copyright Tracking",
      description: "Rights monitoring system",
      details: [
        "MACP Work Search integration",
        "Monitor multiple platforms",
        "Track registration status",
        "Content monitoring alerts"
      ]
    },
    {
      icon: <CalendarIcon className="h-5 w-5" />,
      title: "Shows & Events",
      description: "Performance tracking",
      details: [
        "Track live performances",
        "Manage show schedules",
        "Monitor artist appearances",
        "Track upcoming events"
      ]
    },
    {
      icon: <DollarSignIcon className="h-5 w-5" />,
      title: "Believe Integration",
      description: "Financial management",
      details: [
        "Access Believe Backstage",
        "Quarterly financial reports",
        "Revenue & performance metrics",
        "CSV report summaries"
      ]
    },
    {
      icon: <Search className="h-5 w-5" />,
      title: "Platform Monitoring",
      description: "Content tracking",
      details: [
        "YouTube Music & Covers",
        "TikTok content search",
        "Facebook video tracking",
        "SoundCloud monitoring",
        "Google Alerts setup"
      ]
    }
  ];

  const quickStats = [
    { 
      icon: <MusicIcon />, 
      label: "Core Function", 
      value: "Catalog Management" 
    },
    { 
      icon: <HeartIcon />, 
      label: "Content", 
      value: "Artist & Project Management" 
    },
    { 
      icon: <BarChart2Icon />, 
      label: "Analytics", 
      value: "Financial Reports" 
    },
    { 
      icon: <BellIcon />, 
      label: "Monitoring", 
      value: "Copyright Protection" 
    }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold">About SLATAN Dashboard</h1>
          <img 
            src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png" 
            alt="Logo" 
            className="h-8 w-auto" 
          />
        </div>
      </div>

      <ScrollArea className="flex-grow">
        <div className="p-4 space-y-6">
          {/* Description */}
          <Card>
            <CardContent className="p-4">
              <div className="flex items-center gap-2 mb-2">
                <ClockIcon className="h-5 w-5 text-primary" />
                <h3 className="font-semibold">Overview</h3>
              </div>
              <p className="text-sm text-muted-foreground">
                An all-in-one platform that integrates project management, artist roster tracking, and financial 
                reporting through Believe. This centralized command center streamlines day-to-day operations 
                of 0108 SLATAN as a record label and publishing house.
              </p>
            </CardContent>
          </Card>

          {/* Quick Stats */}
          <div className="grid grid-cols-2 gap-3">
            {quickStats.map((stat, index) => (
              <Card key={index}>
                <CardContent className="p-3 flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center text-primary">
                    {stat.icon}
                  </div>
                  <div>
                    <p className="text-xs text-muted-foreground">{stat.label}</p>
                    <p className="text-sm font-medium">{stat.value}</p>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          {/* Features */}
          <div className="space-y-3">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Card className="hover:shadow-md transition-shadow">
                  <CardContent className="p-4">
                    <div className="flex items-start gap-3 mb-3">
                      <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center text-primary flex-shrink-0">
                        {feature.icon}
                      </div>
                      <div>
                        <h3 className="font-semibold">{feature.title}</h3>
                        <p className="text-sm text-muted-foreground">
                          {feature.description}
                        </p>
                      </div>
                    </div>
                    <ul className="space-y-2 ml-11">
                      {feature.details.map((detail, idx) => (
                        <li key={idx} className="flex items-center gap-2 text-sm">
                          <CheckCircleIcon className="h-4 w-4 text-primary flex-shrink-0" />
                          <span className="text-muted-foreground">{detail}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileInfo; 