import React, { useState, Suspense } from 'react';
import { Card, CardContent } from "./ui/card.jsx";
import { ScrollArea } from "./ui/scroll-area.jsx";
import { Button } from "./ui/button.jsx";
import { useTheme } from "next-themes";
import { ErrorBoundary } from 'react-error-boundary';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from "../lib/utils.js";
import { useAuth } from '../lib/AuthContext.js';
import useMediaQuery from '../hooks/useMediaQuery.js';
import {
  MobileNav,
  MobileSummary,
  MobileShows,
  MobileBelieveReport,
  MobileBelieveBackstage,
  MobileInfo,
  MobileProjectManagement
} from './mobile/index.js';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip.jsx";
import {
  MoonIcon,
  SunIcon,
  ChevronLeftIcon,
  HomeIcon,
  CalendarIcon,
  UsersIcon,
  ChevronRightIcon,
  BarChartIcon,
  FolderIcon,
  ActivityIcon,
  AlertCircleIcon,
  InfoIcon,
  AppWindow,
  SquareArrowOutUpRight,
  FileTextIcon
} from "lucide-react";
import MobileArtistTab from './mobile/MobileArtistTab.js';

// Lazy load the tabs for better performance
const ProjectManagementTab = React.lazy(() => import('./projectmanagement/ProjectManagementTab.js'));
const SummaryTab = React.lazy(() => import('./SummaryTab.js'));
const ShowsTab = React.lazy(() => import('./ShowsTab.js'));
const BelieveReportTab = React.lazy(() => import('./BelieveReportTab.js'));
const BelieveBackstageTab = React.lazy(() => import('./BelieveBackstageTab.js'));
const ArtistTab = React.lazy(() => import('./catalogmanagement/ArtistTab.js'));
const InfoModal = React.lazy(() => import('./InfoModal.js'));
const DocumentsManagementTab = React.lazy(() => import('./documentsmanagement/DocumentsManagementTab.js'));

// Loading fallback component
const TabLoadingFallback = () => (
  <div className="flex items-center justify-center p-8">
    <div className="flex items-center gap-2">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
      >
        <ActivityIcon className="h-5 w-5 text-primary" />
      </motion.div>
      <span className="text-muted-foreground">Loading...</span>
    </div>
  </div>
);

// Error fallback component
const TabErrorFallback = ({ error, resetErrorBoundary }) => (
  <div className="flex flex-col items-center justify-center p-8 space-y-4">
    <div className="text-destructive">
      <AlertCircleIcon className="h-8 w-8" />
    </div>
    <h3 className="text-lg font-semibold">Something went wrong</h3>
    <p className="text-sm text-muted-foreground">{error.message}</p>
    <Button variant="outline" onClick={resetErrorBoundary}>
      Try again
    </Button>
  </div>
);

const Dashboard = ({ catalog }) => {
  const [selectedArtist, setSelectedArtist] = useState('summary');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [artistsExpanded, setArtistsExpanded] = useState(false);
  const [believeExpanded, setBelieveExpanded] = useState(false);
  const { theme, setTheme } = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { isGuestMode, signOut } = useAuth();

  const handleSignOut = async () => {
    try {
      await signOut();
      // The redirect will be handled by the AuthContext after successful sign out
    } catch (error) {
      console.error('Error signing out:', error);
      toast({
        title: "Error",
        description: "Failed to sign out. Please try again.",
        variant: "destructive",
      });
    }
  };

  // Add effect to handle auto-expansion
  React.useEffect(() => {
    // Auto expand Artists section if an artist is selected
    if (Object.keys(catalog).includes(selectedArtist)) {
      setArtistsExpanded(true);
    }
    // Auto expand Believe section if believe-related tab is selected
    if (selectedArtist === 'believe-backstage' || selectedArtist === 'believe-report') {
      setBelieveExpanded(true);
    }
  }, [selectedArtist, catalog]);

  const renderMobileContent = () => {
    switch (selectedArtist) {
      case 'summary':
        return (
          <div className="space-y-4">
            <MobileSummary catalog={catalog} />
          </div>
        );
      case 'shows':
        return (
          <div className="space-y-4">
            <MobileShows catalog={catalog} />
          </div>
        );
      case 'project-management':
        return !isGuestMode && (
          <div className="space-y-4">
            <MobileProjectManagement />
          </div>
        );
      case 'believe-report':
        return !isGuestMode && (
          <div className="space-y-4">
            <MobileBelieveReport />
          </div>
        );
      case 'believe-backstage':
        return !isGuestMode && (
          <div className="space-y-4">
            <MobileBelieveBackstage />
          </div>
        );
      case 'info':
        return (
          <div className="space-y-4">
            <MobileInfo />
          </div>
        );
      case 'documents':
        return <MobileDocumentsManagement />;
      default:
        if (Object.keys(catalog).includes(selectedArtist)) {
          return (
            <div className="space-y-4">
              <MobileArtistTab
                artist={selectedArtist}
                isGuestMode={isGuestMode}
              />
            </div>
          );
        }
        return null;
    }
  };

  const renderDesktopContent = () => {
    return (
      <ErrorBoundary
        FallbackComponent={TabErrorFallback}
        onReset={() => {
          setSelectedArtist('summary');
        }}
      >
        <Suspense fallback={<TabLoadingFallback />}>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedArtist}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="min-h-full"
            >
              {selectedArtist === 'summary' && <SummaryTab catalog={catalog} />}
              {selectedArtist === 'shows' && <ShowsTab catalog={catalog} />}
              {!isGuestMode && selectedArtist === 'believe-report' && <BelieveReportTab />}
              {!isGuestMode && selectedArtist === 'believe-backstage' && <BelieveBackstageTab />}
              {!isGuestMode && selectedArtist === 'project-management' && <ProjectManagementTab />}
              {selectedArtist === 'info' && <InfoModal />}
              {selectedArtist === 'documents' && <DocumentsManagementTab />}
              {selectedArtist !== 'summary' && selectedArtist !== 'shows' &&
                selectedArtist !== 'believe-report' && selectedArtist !== 'believe-backstage' &&
                selectedArtist !== 'project-management' && selectedArtist !== 'info' &&
                selectedArtist !== 'documents' && (
                  <ArtistTab
                    artist={selectedArtist}
                    catalog={catalog}
                    isGuestMode={isGuestMode}
                  />
                )}
            </motion.div>
          </AnimatePresence>
        </Suspense>
      </ErrorBoundary>
    );
  };

  const renderIcon = (icon, title) => {
    if (!sidebarOpen) {
      return (
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <div>{icon}</div>
            </TooltipTrigger>
            <TooltipContent side="right" sideOffset={10}>
              <p>{title}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
    return icon;
  };

  if (isMobile) {
    return (
      <div className="min-h-screen bg-background pb-16">
        <div className="p-4">
          {renderMobileContent()}
        </div>
        <MobileNav
          selectedArtist={selectedArtist}
          setSelectedArtist={setSelectedArtist}
          catalog={catalog}
          isGuestMode={isGuestMode}
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background flex">
      {/* Desktop Sidebar */}
      <div className={cn(
        "fixed inset-y-0 z-50 flex transition-all duration-300 ease-in-out sidebar",
        sidebarOpen ? "w-[240px] translate-x-0" : "w-16 -translate-x-full md:translate-x-0",
        "hidden md:flex"
      )}>
        <div className="flex h-full w-full flex-col border-r bg-background">
          <div className="sticky top-0 z-40 flex h-16 items-center justify-between border-b bg-background px-4">
            <div className="flex items-center gap-2">
              <img
                src="https://static.wixstatic.com/media/ebd611_3711b599d89649f093bddf390131d121~mv2.png"
                alt="Logo"
                className="h-8 w-auto"
              />
              {sidebarOpen && (
                <div className="flex flex-col">
                  <h1 className="text-xl font-bold text-foreground">Dashboard</h1>
                </div>
              )}
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              {sidebarOpen ? <ChevronLeftIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4" />}
            </Button>
          </div>

          <ScrollArea className="flex-1">
            <div className="space-y-2 p-2">
              <Button
                variant={selectedArtist === 'summary' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('summary')}
              >
                {renderIcon(<HomeIcon className="h-4 w-4" />, "Summary")}
                {sidebarOpen && <span className="ml-2">Summary</span>}
              </Button>

              <Button
                variant={selectedArtist === 'shows' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('shows')}
              >
                {renderIcon(<CalendarIcon className="h-4 w-4" />, "Shows")}
                {sidebarOpen && <span className="ml-2">Shows</span>}
              </Button>

              <div className="space-y-1">
                <Button
                  variant="ghost"
                  className={cn(
                    "w-full justify-start",
                    !sidebarOpen && "justify-center px-2"
                  )}
                  onClick={() => {
                    setArtistsExpanded(!artistsExpanded);
                    if (!artistsExpanded && !sidebarOpen) {
                      setSidebarOpen(true);
                    }
                  }}
                >
                  {renderIcon(<UsersIcon className="h-4 w-4" />, "Artist Catalog")}
                  {sidebarOpen && (
                    <>
                      <span className="ml-2">Artist Catalog</span>
                      <ChevronRightIcon className={cn(
                        "ml-auto h-4 w-4 transition-transform",
                        artistsExpanded && "rotate-90"
                      )} />
                    </>
                  )}
                </Button>

                {artistsExpanded && (
                  <div className={cn(
                    "space-y-1",
                    sidebarOpen ? "ml-4" : "ml-0"
                  )}>
                    {Object.keys(catalog).map((artist) => (
                      <Button
                        key={artist}
                        variant={selectedArtist === artist ? 'secondary' : 'ghost'}
                        className={cn(
                          "w-full justify-start",
                          !sidebarOpen && "justify-center px-2"
                        )}
                        onClick={() => {
                          setSelectedArtist(artist);
                          if (!sidebarOpen) {
                            setSidebarOpen(true);
                          }
                        }}
                      >
                        {renderIcon(
                          <span className={cn(
                            "h-4 w-4 rounded-full bg-primary/10 flex items-center justify-center text-xs font-medium",
                            selectedArtist === artist && "bg-primary text-primary-foreground"
                          )}>
                            {artist[0].toUpperCase()}
                          </span>,
                          artist
                        )}
                        {sidebarOpen && <span className="ml-2">{artist}</span>}
                      </Button>
                    ))}
                  </div>
                )}
              </div>

              {!isGuestMode && (
                <>
                  <div className="space-y-1">
                    <Button
                      variant="ghost"
                      className={cn(
                        "w-full justify-start",
                        !sidebarOpen && "justify-center px-2"
                      )}
                      onClick={() => {
                        setBelieveExpanded(!believeExpanded);
                        if (!believeExpanded && !sidebarOpen) {
                          setSidebarOpen(true);
                        }
                      }}
                    >
                      {renderIcon(<AppWindow className="h-4 w-4" />, "Believe")}
                      {sidebarOpen && (
                        <>
                          <span className="ml-2">Believe</span>
                          <ChevronRightIcon className={cn(
                            "ml-auto h-4 w-4 transition-transform",
                            believeExpanded && "rotate-90"
                          )} />
                        </>
                      )}
                    </Button>

                    {believeExpanded && (
                      <div className={cn(
                        "space-y-1",
                        sidebarOpen ? "ml-4" : "ml-0"
                      )}>
                        <Button
                          variant={selectedArtist === 'believe-report' ? 'secondary' : 'ghost'}
                          className={cn(
                            "w-full justify-start",
                            !sidebarOpen && "justify-center px-2"
                          )}
                          onClick={() => {
                            setSelectedArtist('believe-report');
                            if (!sidebarOpen) {
                              setSidebarOpen(true);
                            }
                          }}
                        >
                          {renderIcon(<BarChartIcon className="h-4 w-4" />, "Report")}
                          {sidebarOpen && <span className="ml-2">Report</span>}
                        </Button>
                        <Button
                          variant={selectedArtist === 'believe-backstage' ? 'secondary' : 'ghost'}
                          className={cn(
                            "w-full justify-start",
                            !sidebarOpen && "justify-center px-2"
                          )}
                          onClick={() => {
                            setSelectedArtist('believe-backstage');
                            if (!sidebarOpen) {
                              setSidebarOpen(true);
                            }
                          }}
                        >
                          {renderIcon(<FolderIcon className="h-4 w-4" />, "Backstage")}
                          {sidebarOpen && <span className="ml-2">Backstage</span>}
                        </Button>
                      </div>
                    )}
                  </div>

                  <Button
                    variant={selectedArtist === 'project-management' ? 'secondary' : 'ghost'}
                    className={cn(
                      "w-full justify-start",
                      !sidebarOpen && "justify-center px-2"
                    )}
                    onClick={() => setSelectedArtist('project-management')}
                  >
                    {renderIcon(<FolderIcon className="h-4 w-4" />, "Project Management")}
                    {sidebarOpen && <span className="ml-2">Project Management</span>}
                  </Button>
                </>
              )}
              <Button
                variant={selectedArtist === 'documents' ? 'secondary' : 'ghost'}
                className={cn("w-full justify-start", !sidebarOpen && "justify-center px-2")}
                onClick={() => setSelectedArtist('documents')}
              >
                {renderIcon(<FileTextIcon className="h-4 w-4" />, "Files")}
                {sidebarOpen && <span className="ml-2">Files</span>}
              </Button>
              <Button
                variant={selectedArtist === 'info' ? 'secondary' : 'ghost'}
                className={cn(
                  "w-full justify-start",
                  !sidebarOpen && "justify-center px-2"
                )}
                onClick={() => setSelectedArtist('info')}
              >
                {renderIcon(<InfoIcon className="h-4 w-4" />, "About")}
                {sidebarOpen && <span className="ml-2">About</span>}
              </Button>


            </div>
          </ScrollArea>

          <div className={cn(
            "sticky bottom-0 border-t bg-background space-y-2",
            sidebarOpen ? "p-4" : "p-2"
          )}>
            {isGuestMode && sidebarOpen && (
              <div className="px-2 py-1 text-xs text-muted-foreground">
                Guest Mode
              </div>
            )}
            <Button
              variant="ghost"
              className={cn(
                "w-full justify-start",
                !sidebarOpen && "justify-center px-2"
              )}
              onClick={handleSignOut}
            >
              {renderIcon(<SquareArrowOutUpRight className="h-4 w-4" />, "Sign Out")}
              {sidebarOpen && <span className="ml-2">Sign Out</span>}
            </Button>
            <Button
              variant="ghost"
              className={cn(
                "w-full justify-start",
                !sidebarOpen && "justify-center px-2"
              )}
              onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
            >
              {renderIcon(
                theme === "dark" ? <SunIcon className="h-4 w-4" /> : <MoonIcon className="h-4 w-4" />,
                theme === "dark" ? "Light Mode" : "Dark Mode"
              )}
              {sidebarOpen && <span className="ml-2">{theme === "dark" ? "Light Mode" : "Dark Mode"}</span>}
            </Button>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className={cn(
        "flex-1 transition-all duration-300 ease-in-out",
        sidebarOpen ? "md:ml-[240px]" : "md:ml-16",
        "ml-0 w-full"
      )}>
        <main className="p-4 min-h-screen">
          <Card className="bg-card h-full overflow-hidden">
            <CardContent className="p-0 h-full">
              {/* Content Area */}
              <div className="h-[calc(100vh-8rem)] md:h-[calc(100vh-2rem)] overflow-y-auto">
                <div className="p-4">
                  {renderDesktopContent()}
                </div>
              </div>
            </CardContent>
          </Card>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
