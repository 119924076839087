import React, { useState, useTransition } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog.jsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.jsx";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { Plus, ListMusic } from 'lucide-react';
import { cn } from "../../lib/utils.js";
import { SHOW_TYPES, SHOW_STATUS, VENUE_TYPES } from '../../data/constants.js';

const CustomSongDialog = ({ 
  open, 
  onOpenChange, 
  selectedArtists, 
  onAdd 
}) => (
  <Dialog open={open} onOpenChange={onOpenChange}>
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Add Custom Song</DialogTitle>
        <DialogDescription>
          Add a song that's not in the catalog
        </DialogDescription>
      </DialogHeader>
      <form onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onAdd({
          title: formData.get('title'),
          duration: formData.get('duration'),
          type: formData.get('type'),
          artist: formData.get('artist')
        });
      }}>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Title</label>
            <Input
              name="title"
              placeholder="Enter song title"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Duration (e.g., 3'26")</label>
            <Input
              name="duration"
              placeholder="Enter duration"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Type</label>
            <Select name="type" defaultValue="Original">
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Original">Original</SelectItem>
                <SelectItem value="Cover">Cover</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Artist</label>
            <Select name="artist" defaultValue={selectedArtists[0]}>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {selectedArtists.map((artist) => (
                  <SelectItem key={artist} value={artist}>{artist}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button type="submit">Add Song</Button>
        </DialogFooter>
      </form>
    </DialogContent>
  </Dialog>
);

const AddEditShowModal = ({ show, isOpen, onClose, onSave, catalog = {} }) => {
  const [isPending, startTransition] = useTransition();
  const [formData, setFormData] = useState(() => ({
    title: show?.title || '',
    type: show?.type || SHOW_TYPES.CONCERT,
    status: show?.status || SHOW_STATUS.UPCOMING,
    artists: show?.artists || [],
    date: show?.date || '',
    venue: show?.venue || '',
    venueType: show?.venueType || VENUE_TYPES.INDOOR,
    location: show?.location || '',
    description: show?.description || '',
    ticketLink: show?.ticketLink || '',
    setlist: show?.setlist || []
  }));

  const [selectedArtists, setSelectedArtists] = useState(show?.artists || []);
  const [artistSearchTerm, setArtistSearchTerm] = useState('');
  const [songSearchTerm, setSongSearchTerm] = useState('');
  const [showCustomSongDialog, setShowCustomSongDialog] = useState(false);

  // Reset form data when show prop changes
  React.useEffect(() => {
    if (show) {
      startTransition(() => {
        setFormData({
          title: show.title || '',
          type: show.type || SHOW_TYPES.CONCERT,
          status: show.status || SHOW_STATUS.UPCOMING,
          artists: show.artists || [],
          date: show.date || '',
          venue: show.venue || '',
          venueType: show.venueType || VENUE_TYPES.INDOOR,
          location: show.location || '',
          description: show.description || '',
          ticketLink: show.ticketLink || '',
          setlist: show.setlist || []
        });
        setSelectedArtists(show.artists || []);
      });
    }
  }, [show]);

  // Get all artists from the catalog with null check
  const allArtists = catalog ? Object.keys(catalog) : [];

  // Filter artists based on search term
  const filteredArtists = allArtists.filter(artist =>
    artist.toLowerCase().includes(artistSearchTerm.toLowerCase())
  );

  // Get all available songs from selected artists' catalogs with artist information
  const allSongs = catalog ? Object.entries(catalog)
    .filter(([artist]) => selectedArtists.includes(artist))
    .flatMap(([artist, songs]) => 
      songs.map(song => ({
        title: song.songDetails.title,
        duration: song.songDetails.duration || '3:00',
        artist
      }))
    ) : [];

  // Filter songs based on search term
  const filteredSongs = allSongs.filter(song =>
    song.title.toLowerCase().includes(songSearchTerm.toLowerCase()) ||
    song.artist.toLowerCase().includes(songSearchTerm.toLowerCase())
  );

  const handleArtistToggle = (artist) => {
    startTransition(() => {
      setSelectedArtists(prev => {
        const isSelected = prev.includes(artist);
        if (isSelected) {
          return prev.filter(a => a !== artist);
        } else {
          return [...prev, artist];
        }
      });
    });
  };

  const handleAddSong = (songData) => {
    startTransition(() => {
      setFormData(prev => ({
        ...prev,
        setlist: [
          ...prev.setlist,
          {
            title: songData.title,
            duration: songData.duration,
            type: 'Original',
            artist: songData.artist
          }
        ]
      }));
    });
  };

  const handleAddCustomSong = (songData) => {
    startTransition(() => {
      setFormData(prev => ({
        ...prev,
        setlist: [
          ...prev.setlist,
          songData
        ]
      }));
      setShowCustomSongDialog(false);
    });
  };

  const handleRemoveSong = (index) => {
    startTransition(() => {
      setFormData(prev => ({
        ...prev,
        setlist: prev.setlist.filter((_, i) => i !== index)
      }));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startTransition(() => {
      onSave({
        ...formData,
        id: show ? show.id : Date.now(),
        artists: selectedArtists
      });
      onClose();
    });
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>{show ? 'Edit Show' : 'Add New Show'}</DialogTitle>
            <DialogDescription>
              {show ? 'Edit the details of your show' : 'Add a new show to your calendar'}
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Title</label>
                <Input
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="text-sm font-medium">Type</label>
                <Select
                  value={formData.type}
                  onValueChange={(value) => setFormData({ ...formData, type: value })}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(SHOW_TYPES).map((type) => (
                      <SelectItem key={type} value={type}>{type}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="col-span-2 space-y-2">
                <label className="text-sm font-medium">Artists</label>
                <div className="space-y-2">
                  <Input
                    placeholder="Search artists..."
                    value={artistSearchTerm}
                    onChange={(e) => setArtistSearchTerm(e.target.value)}
                    className="mb-2"
                  />
                  <ScrollArea className="h-[150px] border rounded-md p-2">
                    <div className="space-y-1">
                      {filteredArtists.map((artist) => (
                        <div
                          key={artist}
                          className={cn(
                            "flex items-center gap-2 p-2 rounded-md cursor-pointer",
                            "hover:bg-muted/50 transition-colors",
                            selectedArtists.includes(artist) && "bg-primary/10"
                          )}
                          onClick={() => handleArtistToggle(artist)}
                        >
                          <div className={cn(
                            "w-4 h-4 border rounded-sm flex items-center justify-center",
                            selectedArtists.includes(artist) && "bg-primary border-primary"
                          )}>
                            {selectedArtists.includes(artist) && "✓"}
                          </div>
                          {artist}
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Status</label>
                <Select
                  value={formData.status}
                  onValueChange={(value) => setFormData({ ...formData, status: value })}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(SHOW_STATUS).map((status) => (
                      <SelectItem key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Date</label>
                <Input
                  type="date"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Venue Type</label>
                <Select
                  value={formData.venueType}
                  onValueChange={(value) => setFormData({ ...formData, venueType: value })}
                >
                  <SelectTrigger>
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(VENUE_TYPES).map((type) => (
                      <SelectItem key={type} value={type}>{type}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Venue</label>
                <Input
                  value={formData.venue}
                  onChange={(e) => setFormData({ ...formData, venue: e.target.value })}
                  required
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Location</label>
                <Input
                  value={formData.location}
                  onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Description</label>
              <Input
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                required
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium">Ticket Link</label>
              <Input
                value={formData.ticketLink}
                onChange={(e) => setFormData({ ...formData, ticketLink: e.target.value })}
              />
            </div>

            <div className="space-y-2">
              <div className="flex items-center justify-between mb-2">
                <label className="text-sm font-medium">Setlist</label>
                <div className="flex items-center gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={() => setShowCustomSongDialog(true)}
                    disabled={selectedArtists.length === 0}
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Custom Song
                  </Button>
                  <Select
                    value=""
                    onValueChange={(value) => {
                      const songData = allSongs.find(s => s.title === value);
                      if (songData) handleAddSong(songData);
                    }}
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue placeholder="Add from catalog" />
                    </SelectTrigger>
                    <SelectContent>
                      <div className="p-2">
                        <Input
                          placeholder="Search songs..."
                          value={songSearchTerm}
                          onChange={(e) => {
                            e.stopPropagation();
                            setSongSearchTerm(e.target.value);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          className="mb-2"
                        />
                      </div>
                      {filteredSongs.map((song, index) => (
                        <SelectItem key={index} value={song.title}>
                          <div className="flex flex-col">
                            <span>{song.title}</span>
                            <span className="text-xs text-muted-foreground">{song.artist}</span>
                          </div>
                        </SelectItem>
                      ))}
                      {filteredSongs.length === 0 && (
                        <div className="text-center py-2 text-sm text-muted-foreground">
                          No songs found
                        </div>
                      )}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <ScrollArea className="h-[200px] border rounded-md p-2">
                <div className="space-y-2">
                  {formData.setlist.map((song, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 rounded-lg bg-muted/50 hover:bg-muted/70 transition-colors group"
                    >
                      <div className="flex items-center gap-3">
                        <div className="text-sm text-muted-foreground w-6">{index + 1}</div>
                        <div>
                          <div className="font-medium">{song.title}</div>
                          <div className="text-sm text-muted-foreground flex items-center gap-2">
                            <span>{song.duration}</span>
                            <span>•</span>
                            <span>{song.artist}</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <Badge 
                          variant="secondary" 
                          className={cn(
                            "bg-primary/10 text-primary",
                            song.type === "Cover" && "bg-blue-500/10 text-blue-500"
                          )}
                        >
                          {song.type}
                        </Badge>
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity"
                          onClick={() => handleRemoveSong(index)}
                        >
                          ×
                        </Button>
                      </div>
                    </div>
                  ))}

                  {formData.setlist.length === 0 && (
                    <div className="text-center py-4 text-muted-foreground">
                      <ListMusic className="h-8 w-8 mx-auto mb-2 opacity-50" />
                      <p>No songs added to setlist</p>
                    </div>
                  )}
                </div>
              </ScrollArea>
            </div>

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">
                {show ? 'Save Changes' : 'Add Show'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <CustomSongDialog
        open={showCustomSongDialog}
        onOpenChange={setShowCustomSongDialog}
        selectedArtists={selectedArtists}
        onAdd={handleAddCustomSong}
      />
    </>
  );
};

export default AddEditShowModal; 