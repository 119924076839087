import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog.jsx";
import { Button } from "../ui/button.jsx";
import { 
  DownloadIcon, 
  ExternalLinkIcon, 
  Loader2Icon,
  AlertCircleIcon,
  XIcon,
  MaximizeIcon,
  MinimizeIcon
} from "lucide-react";

const FilePreviewModal = ({ isOpen, onClose, file }) => {
  if (!file) return null;

  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const modalId = `file-preview-${file.id}`;

  const handleDownload = async () => {
    try {
      const response = await fetch(file.webContentLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
    setLoadError(false);
  };

  const handleIframeError = () => {
    setIsLoading(false);
    setLoadError(true);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent 
        className={`${isFullscreen ? 'w-screen h-screen max-w-none m-0 rounded-none' : 'max-w-4xl h-[80vh]'}`}
        aria-describedby={modalId}
      >
        <DialogHeader className="relative">
          <div className="flex items-center justify-between">
            <DialogTitle className="flex items-center space-x-2 text-lg">
              <span className="truncate max-w-[300px]">{file.name}</span>
            </DialogTitle>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={handleDownload}
                className="gap-2"
              >
                <DownloadIcon className="h-4 w-4" />
                Download
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => window.open(file.webViewLink, '_blank')}
                className="gap-2"
              >
                <ExternalLinkIcon className="h-4 w-4" />
                Open in Drive
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={toggleFullscreen}
                className="gap-2"
              >
                {isFullscreen ? (
                  <MinimizeIcon className="h-4 w-4" />
                ) : (
                  <MaximizeIcon className="h-4 w-4" />
                )}
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={onClose}
                className="hover:bg-destructive/10"
              >
                <XIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <DialogDescription id={modalId} className="text-sm text-muted-foreground">
            Preview and manage file: {file.name}
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 relative bg-muted rounded-md overflow-hidden">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm">
              <div className="flex flex-col items-center space-y-2">
                <Loader2Icon className="h-8 w-8 animate-spin text-primary" />
                <span className="text-sm text-muted-foreground">Loading preview...</span>
              </div>
            </div>
          )}

          {loadError && (
            <div className="absolute inset-0 flex items-center justify-center bg-background/80">
              <div className="flex flex-col items-center space-y-4 p-6 text-center">
                <AlertCircleIcon className="h-12 w-12 text-destructive" />
                <div className="space-y-2">
                  <h3 className="font-semibold">Failed to load preview</h3>
                  <p className="text-sm text-muted-foreground max-w-md">
                    The file preview could not be loaded. You can try downloading the file or opening it in Google Drive.
                  </p>
                </div>
              </div>
            </div>
          )}

          <iframe
            src={file.embedLink ? `${file.embedLink}?embedded=true&rm=minimal` : ''}
            className="w-full h-full"
            frameBorder="0"
            allowFullScreen
            title={`Preview of ${file.name}`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal; 