import React, { useState } from 'react';
import { Card, CardContent } from "../ui/card.jsx";
import { ScrollArea, ScrollBar } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { cn } from "../../lib/utils.js";
import { Button } from "../ui/button.jsx";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, Music, FileText, Mic, Link as LinkIcon } from 'lucide-react';

const MobileSongDetails = ({ 
  song, 
  onClose,
  editMode,
  onEdit,
  children
}) => {
  const [activeTab, setActiveTab] = useState('details');

  const tabs = [
    { id: 'details', label: 'Details', icon: Music },
    { id: 'publishing', label: 'Publishing', icon: FileText },
    { id: 'master', label: 'Master', icon: Mic },
    { id: 'copyright', label: 'Copyright', icon: LinkIcon }
  ];

  const renderContent = () => {
    const content = React.Children.toArray(children).find(child => 
      child.props.className?.includes(activeTab)
    );
    return content;
  };

  return (
    <div className="fixed inset-0 bg-background z-40 md:relative md:bg-transparent flex flex-col">
      <div className="flex-none sticky top-0 z-10 bg-background/95 backdrop-blur-lg border-b">
        <div className="p-4">
          <div className="flex items-center gap-3 mb-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="shrink-0 rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
            <div className="flex-1 min-w-0">
              <h2 className="font-semibold truncate">{song.songDetails.title}</h2>
              <p className="text-sm text-muted-foreground truncate">
                {Array.isArray(song.songDetails.artist) 
                  ? song.songDetails.artist.join(', ') 
                  : song.songDetails.artist}
              </p>
            </div>
          </div>

          <div className="relative">
            <ScrollArea 
              className="w-full max-w-[calc(100vw-2rem)]" 
              orientation="horizontal"
            >
              <div className="flex gap-2 px-0.5 pb-0.5">
                {tabs.map((tab) => (
                  <Button
                    key={tab.id}
                    variant={activeTab === tab.id ? 'secondary' : 'ghost'}
                    size="sm"
                    onClick={() => setActiveTab(tab.id)}
                    className={cn(
                      "transition-all duration-200 active:scale-95 touch-manipulation whitespace-nowrap flex-shrink-0 min-w-[100px]",
                      activeTab === tab.id && "bg-primary/10"
                    )}
                  >
                    <tab.icon className="h-4 w-4 mr-2 flex-shrink-0" />
                    {tab.label}
                  </Button>
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        </div>
      </div>

      <ScrollArea className="flex-grow touch-manipulation">
        <div className="p-4 pb-24">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.15 }}
            >
              {renderContent()}
            </motion.div>
          </AnimatePresence>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileSongDetails; 