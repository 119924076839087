import React from 'react';
import { Card, CardHeader, CardContent } from "../ui/card.jsx"
import SummaryCards from './SummaryCards.js';
import TopTracksChart from './TopTracksChart.js';
import ArtistBreakdown from './ArtistBreakdown.js';
import { motion } from 'framer-motion';

const Dashboard = ({ data }) => {
  // Sort tracks by revenue in descending order and take top 10
  const topTracks = data.trackData
    .sort((a, b) => b.revenue - a.revenue)
    .slice(0, 10);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="space-y-8"
    >
      <motion.div variants={item}>
        <div className="mb-2">
          <h2 className="text-2xl font-bold text-foreground">Overview</h2>
          <p className="text-sm text-muted-foreground">
            Summary of your revenue and performance metrics
          </p>
        </div>
        <SummaryCards data={data} />
      </motion.div>

      <motion.div variants={item}>
        <div className="mb-2">
          <h2 className="text-2xl font-bold text-foreground">Performance Analysis</h2>
          <p className="text-sm text-muted-foreground">
            Detailed breakdown of track performance and revenue distribution
          </p>
        </div>
        <div className="grid gap-8">
          <TopTracksChart data={topTracks} />
          <Card className="bg-card text-card-foreground transition-colors hover:bg-accent/5">
            <CardHeader className="border-b">
              <div className="flex items-center gap-2">
                <span className="inline-block w-2 h-2 rounded-full bg-chart-3" />
                <h2 className="text-2xl font-bold text-card-foreground">
                  Artist Revenue Breakdown
                </h2>
              </div>
              <p className="text-sm text-muted-foreground">
                Revenue distribution across all artists
              </p>
            </CardHeader>
            <CardContent className="pt-6">
              <ArtistBreakdown data={data.artistData} />
            </CardContent>
          </Card>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Dashboard;