// Load the Google API client
const loadGapiClient = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => {
      window.gapi.load('client', () => resolve());
    };
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

// Initialize the Google Drive API client
export const initDriveApi = async () => {
  try {
    // Load the GAPI client
    await loadGapiClient();

    // Initialize the client
    await window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
    });
    return true;
  } catch (error) {
    console.error('Error initializing Drive API:', error);
    return false;
  }
};

// Get folder contents
export const getFolderContents = async (folderId) => {
  try {
    if (!folderId) {
      throw new Error('Folder ID is required');
    }

    console.log('Fetching contents for folder ID:', folderId);
    
    // Query for files in the specified folder
    const response = await window.gapi.client.drive.files.list({
      q: `'${folderId}' in parents and trashed = false`,
      fields: 'files(id, name, mimeType, size, modifiedTime, webViewLink, webContentLink, thumbnailLink)',
      orderBy: 'name',
      pageSize: 1000
    });

    console.log('Drive API response:', response);

    if (!response || !response.result || !response.result.files) {
      throw new Error('Invalid response from Google Drive API');
    }

    // Process and format the files
    const files = response.result.files.map(file => ({
      id: file.id,
      name: file.name,
      type: file.mimeType.includes('folder') ? 'folder' : 'file',
      size: formatFileSize(file.size),
      modifiedDate: new Date(file.modifiedTime).toLocaleDateString(),
      webViewLink: file.webViewLink,
      webContentLink: file.webContentLink,
      embedLink: `https://drive.google.com/file/d/${file.id}/preview`,
      thumbnailLink: file.thumbnailLink
    }));

    console.log('Processed files:', files);
    return files;

  } catch (error) {
    console.error('Error in getFolderContents:', error);
    if (error.status === 404) {
      throw new Error('Folder not found or you do not have access to it');
    }
    throw error;
  }
};

// Get file metadata
export const getFileMetadata = async (fileId) => {
  try {
    const response = await window.gapi.client.drive.files.get({
      fileId: fileId,
      fields: 'id, name, mimeType, modifiedTime, size, webViewLink, webContentLink'
    });
    return response.result;
  } catch (error) {
    console.error('Error fetching file metadata:', error);
    throw error;
  }
};

// Download file
export const downloadFile = async (fileId, fileName) => {
  try {
    const response = await window.gapi.client.drive.files.get({
      fileId: fileId,
      alt: 'media'
    });

    // Create blob from response
    const blob = new Blob([response.body], { type: response.headers['Content-Type'] });
    
    // Create download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};

// Preview file
export const previewFile = (fileId) => {
  const previewUrl = `https://drive.google.com/file/d/${fileId}/preview`;
  return previewUrl;
};

// Helper function to format file size
const formatFileSize = (bytes) => {
  if (!bytes) return 'N/A';
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = parseInt(bytes, 10);
  let unitIndex = 0;
  
  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }
  
  return `${size.toFixed(1)} ${units[unitIndex]}`;
}; 