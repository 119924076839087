import React from 'react';
import { Card, CardHeader, CardContent } from '../ui/card.jsx';
import { motion } from 'framer-motion';

const SummaryCards = ({ data }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };

  const totalRevenue = data.artistData.reduce((sum, item) => sum + item.revenue, 0);
  const totalStreams = data.artistData.reduce((sum, item) => sum + item.streams, 0);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Card className="overflow-hidden transition-colors hover:bg-accent/5">
          <CardHeader className="bg-card p-4 border-b">
            <h3 className="text-lg font-semibold text-card-foreground flex items-center gap-2">
              <span className="inline-block w-2 h-2 rounded-full bg-chart-1" />
              Total Revenue
            </h3>
          </CardHeader>
          <CardContent className="p-4">
            <p className="text-4xl font-bold text-chart-1">
              {formatCurrency(totalRevenue)}
            </p>
            <p className="text-sm text-muted-foreground mt-1">
              Total earnings across all artists
            </p>
          </CardContent>
        </Card>
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <Card className="overflow-hidden transition-colors hover:bg-accent/5">
          <CardHeader className="bg-card p-4 border-b">
            <h3 className="text-lg font-semibold text-card-foreground flex items-center gap-2">
              <span className="inline-block w-2 h-2 rounded-full bg-chart-2" />
              Total Streams
            </h3>
          </CardHeader>
          <CardContent className="p-4">
            <p className="text-4xl font-bold text-chart-2">
              {formatNumber(totalStreams)}
            </p>
            <p className="text-sm text-muted-foreground mt-1">
              Total streams across all platforms
            </p>
          </CardContent>
        </Card>
      </motion.div>
    </div>
  );
};

export default SummaryCards;