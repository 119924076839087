import React, { useState } from 'react';
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { Label } from "../ui/label.jsx";
import { Badge } from "../ui/badge.jsx";
import { motion } from "framer-motion";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select.jsx";
import {
  EditIcon,
  MicIcon,
  ActivityIcon,
  CheckCircleIcon,
  UserIcon,
  XIcon,
  UserPlusIcon,
  LinkIcon
} from "lucide-react";
import { TRACK_STATUSES } from '../../data/projectConstants.js';

const TrackForm = ({ 
  isEditing,
  track,
  onTrackChange,
  onSubmit,
  onCancel,
  isLoading 
}) => {
  const [newCollaborator, setNewCollaborator] = useState('');

  const handleAddCollaborator = () => {
    if (newCollaborator.trim()) {
      const updatedFeatures = [...(track.features || []), newCollaborator.trim()];
      onTrackChange({ 
        ...track, 
        features: updatedFeatures
      });
      setNewCollaborator('');
    }
  };

  const handleRemoveCollaborator = (collaborator) => {
    const updatedFeatures = track.features?.filter(f => f !== collaborator) || [];
    onTrackChange({ 
      ...track, 
      features: updatedFeatures
    });
  };

  const handleSubmitTrack = async () => {
    await onSubmit({
      ...track,
      features: track.features || []
    });
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <Label>Title</Label>
        <Input
          value={track.title}
          onChange={(e) => onTrackChange({ ...track, title: e.target.value })}
          placeholder="Track title"
        />
      </div>
      <div>
        <Label>Status</Label>
        <Select
          value={track.status}
          onValueChange={(value) => onTrackChange({ ...track, status: value })}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(TRACK_STATUSES).map(([key, { label }]) => (
              <SelectItem key={key} value={key}>{label}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label>Duration</Label>
        <Input
          value={track.duration}
          onChange={(e) => onTrackChange({ ...track, duration: e.target.value })}
          placeholder="e.g., 3:45"
        />
      </div>
      <div>
        <Label>BPM</Label>
        <Input
          type="number"
          value={track.bpm}
          onChange={(e) => onTrackChange({ ...track, bpm: e.target.value })}
          placeholder="Tempo"
        />
      </div>
      <div>
        <Label>Key</Label>
        <Input
          value={track.key}
          onChange={(e) => onTrackChange({ ...track, key: e.target.value })}
          placeholder="e.g., Am"
        />
      </div>
      <div>
        <Label>Recording Location</Label>
        <Input
          value={track.recordingLocation}
          onChange={(e) => onTrackChange({ ...track, recordingLocation: e.target.value })}
          placeholder="Studio name"
        />
      </div>
      <div className="col-span-2">
        <Label>Demo Link (Google Drive)</Label>
        <div className="flex gap-2">
          <Input
            value={track.demoLink}
            onChange={(e) => onTrackChange({ ...track, demoLink: e.target.value })}
            placeholder="Paste Google Drive audio file link..."
            className="flex-1"
          />
          {track.demoLink && (
            <Button
              variant="outline"
              size="icon"
              className="shrink-0"
              onClick={() => window.open(track.demoLink, '_blank')}
            >
              <LinkIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
        <p className="text-xs text-muted-foreground mt-1">
          Make sure the Google Drive link is publicly accessible and points to an audio file
        </p>
      </div>
      <div className="col-span-2">
        <Label className="mb-2 block">Collaborators</Label>
        <div className="flex flex-wrap gap-2 mb-4">
          {track.features?.map((collaborator, index) => (
            <Badge 
              key={index} 
              variant="secondary" 
              className="flex items-center gap-1 px-2 py-1"
            >
              <UserIcon className="h-3 w-3" />
              {collaborator}
              <button
                onClick={() => handleRemoveCollaborator(collaborator)}
                className="ml-1 hover:text-destructive"
              >
                <XIcon className="h-3 w-3" />
              </button>
            </Badge>
          ))}
        </div>
        <div className="flex gap-2">
          <Input
            value={newCollaborator}
            onChange={(e) => setNewCollaborator(e.target.value)}
            placeholder="Add collaborator..."
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddCollaborator();
              }
            }}
          />
          <Button onClick={handleAddCollaborator}>
            <UserPlusIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <div className="col-span-2">
        <Label>Notes</Label>
        <textarea
          className="w-full p-2 rounded-md border min-h-[80px]"
          value={track.notes}
          onChange={(e) => onTrackChange({ ...track, notes: e.target.value })}
          placeholder="Track notes..."
        />
      </div>
      <div className="col-span-2 flex justify-end gap-2 mt-4">
        <Button
          variant="outline"
          onClick={onCancel}
          className="transition-all duration-300 hover:scale-105"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmitTrack}
          className="min-w-[120px] transition-all duration-300 hover:scale-105"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                className="mr-2"
              >
                <ActivityIcon className="h-4 w-4" />
              </motion.div>
              {isEditing ? 'Updating...' : 'Adding...'}
            </div>
          ) : (
            isEditing ? 'Update Track' : 'Add Track'
          )}
        </Button>
      </div>
    </div>
  );
};

export default TrackForm; 