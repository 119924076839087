import React, { useState } from 'react';
import { Button } from "../ui/button.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { motion, AnimatePresence } from "framer-motion";
import { 
  InfoIcon, 
  LogInIcon, 
  SidebarIcon, 
  CalendarIcon, 
  FileTextIcon,
  DownloadIcon,
  BarChartIcon,
  ChevronRightIcon,
  LayoutDashboardIcon,
  UserIcon,
  X,
  UploadIcon
} from "lucide-react";
import DataUpload from '../believereport/DataUpload.js';
import Dashboard from '../believereport/Dashboard.js';
import ReportGenerator from '../believereport/ReportGenerator.js';
import { ThemeProvider } from '../theme-provider.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select.jsx";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.jsx";
import { Separator } from "../ui/separator.jsx";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs.jsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "../ui/dialog.jsx";
import { cn } from "../../lib/utils.js";

const MobileBelieveReport = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [activeTab, setActiveTab] = useState("dashboard");

  const handleDataProcessed = (data) => {
    setReportData(data);
    if (data.artistData && data.artistData.length > 0) {
      setSelectedArtist(data.artistData[0].name);
    }
  };

  const handleArtistChange = (value) => {
    setSelectedArtist(value);
    setActiveTab("artist");
  };

  const steps = [
    {
      icon: <LogInIcon className="h-5 w-5" />,
      title: "Login to Believe Backstage",
      description: "Access your Believe Backstage account",
    },
    {
      icon: <SidebarIcon className="h-5 w-5" />,
      title: "Navigate to Reports",
      description: "Find 'Financial Reports' section",
    },
    {
      icon: <CalendarIcon className="h-5 w-5" />,
      title: "Select Quarter",
      description: "Choose the quarter period",
    },
    {
      icon: <FileTextIcon className="h-5 w-5" />,
      title: "Generate Report",
      description: "Select 'Single Report' option",
    },
    {
      icon: <DownloadIcon className="h-5 w-5" />,
      title: "Download CSV",
      description: "Download the report file",
    },
    {
      icon: <BarChartIcon className="h-5 w-5" />,
      title: "Upload & Generate",
      description: "Upload CSV for analysis",
    },
  ];

  const content = (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="flex items-center justify-between">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold tracking-tight">Believe Report</h1>
            {reportData && (
              <p className="text-sm text-muted-foreground">
                {reportData.artistData.length} artists • {new Date().toLocaleDateString()}
              </p>
            )}
          </div>
          <Button
            variant="outline"
            size="icon"
            onClick={() => setDialogOpen(true)}
            className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
          >
            <InfoIcon className="h-4 w-4" />
          </Button>
        </div>
        {reportData && (
          <div className="mt-4 space-y-3">
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
              <TabsList className="grid w-full grid-cols-2 p-1">
                <TabsTrigger 
                  value="dashboard" 
                  className={cn(
                    "flex items-center gap-2 data-[state=active]:bg-primary/10",
                    "transition-all duration-200 active:scale-95 touch-manipulation"
                  )}
                >
                  <LayoutDashboardIcon className="h-4 w-4" />
                  Dashboard
                </TabsTrigger>
                <TabsTrigger 
                  value="artist" 
                  className={cn(
                    "flex items-center gap-2 data-[state=active]:bg-primary/10",
                    "transition-all duration-200 active:scale-95 touch-manipulation"
                  )}
                >
                  <UserIcon className="h-4 w-4" />
                  Artist Report
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <Select
              value={selectedArtist}
              onValueChange={handleArtistChange}
            >
              <SelectTrigger className="w-full h-10 px-3 bg-muted/50">
                <SelectValue placeholder="Select an artist" />
              </SelectTrigger>
              <SelectContent>
                {reportData.artistData.map((artist) => (
                  <SelectItem 
                    key={artist.name} 
                    value={artist.name}
                    className="cursor-pointer focus:bg-primary/10"
                  >
                    {artist.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
      </div>

      <div className="flex-grow p-4">
        {!reportData ? (
          <Card className="border-dashed">
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <UploadIcon className="h-5 w-5 text-primary" />
                Upload Report
              </CardTitle>
              <CardDescription>
                Upload your Believe Backstage CSV report to generate insights
              </CardDescription>
            </CardHeader>
            <CardContent>
              <DataUpload 
                onDataProcessed={handleDataProcessed}
                className="touch-manipulation"
              />
            </CardContent>
          </Card>
        ) : (
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="touch-manipulation"
            >
              {activeTab === "dashboard" ? (
                <Dashboard data={reportData} />
              ) : selectedArtist && (
                <ReportGenerator 
                  data={reportData} 
                  selectedArtist={selectedArtist}
                  uploadDate={new Date()}
                />
              )}
            </motion.div>
          </AnimatePresence>
        )}
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-lg sm:max-w-xl">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2 text-xl">
              <InfoIcon className="h-5 w-5 text-primary" />
              How to Use Believe Report
            </DialogTitle>
            <DialogDescription className="text-base pt-2">
              Follow these steps to generate your report:
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start gap-3"
              >
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                  {step.icon}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="font-semibold">
                      Step {index + 1}
                    </span>
                    <ChevronRightIcon className="h-4 w-4 text-muted-foreground" />
                    <span className="font-medium">{step.title}</span>
                  </div>
                  <p className="text-sm text-muted-foreground mt-1">
                    {step.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          <div className="bg-muted/50 p-4 rounded-lg mt-2">
            <p className="text-sm text-muted-foreground">
              <span className="font-medium text-primary">Note:</span> Make sure to download the CSV report from Believe Backstage in the correct format. The report should contain all necessary fields including Artist Name, Net Revenue, Quantity, Platform, Sales Type, Reporting Month, Track Title, and Release Title.
            </p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="system"
      enableSystem
      disableTransitionOnChange
    >
      {content}
    </ThemeProvider>
  );
};

export default MobileBelieveReport; 