import React from 'react';
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { Badge } from "../ui/badge.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Separator } from "../ui/separator.jsx";
import { Card } from "../ui/card.jsx";
import {
  UserIcon,
  TagIcon,
  CalendarIcon,
  ClockIcon,
  FileTextIcon,
  UserPlusIcon,
  XIcon,
  InfoIcon,
  ArrowLeftIcon,
  Trash2Icon
} from "lucide-react";
import { motion } from "framer-motion";

const ProjectDetailsCollapsible = ({
  project,
  onToggle,
  getStatusBadge,
  newCollaborator,
  setNewCollaborator,
  handleAddCollaborator,
  handleRemoveCollaborator,
  newTask,
  setNewTask,
  handleAddTask,
  handleToggleTask,
  handleRemoveTask,
  renderTrackSection
}) => {
  const onAddTask = (e) => {
    e.stopPropagation();
    if (newTask.trim()) {
      handleAddTask({
        id: Date.now(),
        text: newTask.trim(),
        completed: false
      });
    }
  };

  const onAddCollaborator = (e) => {
    e.stopPropagation();
    if (newCollaborator.trim()) {
      handleAddCollaborator(newCollaborator.trim());
    }
  };

  return (
    <Card className="p-6 bg-card/50 backdrop-blur-sm border shadow-lg">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onToggle}
            className="hover:bg-muted/50"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-2" />
            Back
          </Button>
          <div className="flex items-center gap-2">
            <h3 className="text-lg font-semibold">{project.title}</h3>
            {getStatusBadge(project.status)}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Project Management */}
        <div className="space-y-6">
          <div>
            <h4 className="font-medium text-sm text-muted-foreground mb-4">Task Management</h4>
            <div className="space-y-2 bg-muted/50 p-4 rounded-lg">
              {project.tasks?.map(task => (
                <div key={task.id} className="flex items-center justify-between gap-2 p-2 rounded-md hover:bg-background/50 transition-colors group">
                  <div className="flex items-center gap-2 flex-1">
                    <input
                      type="checkbox"
                      checked={task.completed}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleToggleTask(task.id);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      className="rounded"
                    />
                    <span className={task.completed ? 'line-through text-muted-foreground' : ''}>
                      {task.text}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveTask(task.id);
                    }}
                    className="opacity-0 group-hover:opacity-100 transition-opacity h-6 w-6 p-0 hover:bg-destructive/10 hover:text-destructive"
                  >
                    <Trash2Icon className="h-3 w-3" />
                  </Button>
                </div>
              ))}
              <div className="flex gap-2 mt-4">
                <Input
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  placeholder="Add new task..."
                  onClick={(e) => e.stopPropagation()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onAddTask(e);
                    }
                  }}
                  className="bg-background/50"
                />
                <Button 
                  onClick={onAddTask}
                  size="sm"
                >
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div>
            <h4 className="font-medium text-sm text-muted-foreground mb-4">Team Management</h4>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {project.collaborators?.map((collaborator, index) => (
                  <Badge 
                    key={index} 
                    variant="secondary" 
                    className="flex items-center gap-1 px-2 py-1 bg-muted/50"
                  >
                    <UserIcon className="h-3 w-3" />
                    {collaborator}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveCollaborator(collaborator);
                      }}
                      className="ml-1 hover:text-destructive"
                    >
                      <XIcon className="h-3 w-3" />
                    </button>
                  </Badge>
                ))}
              </div>
              <div className="flex gap-2">
                <Input
                  value={newCollaborator}
                  onChange={(e) => setNewCollaborator(e.target.value)}
                  placeholder="Add collaborator..."
                  onClick={(e) => e.stopPropagation()}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      onAddCollaborator(e);
                    }
                  }}
                  className="bg-muted/50"
                />
                <Button 
                  onClick={onAddCollaborator}
                  size="sm"
                >
                  <UserPlusIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Track Management */}
        <div className="space-y-4">
          <h4 className="font-medium text-sm text-muted-foreground">Track Management</h4>
          {renderTrackSection(project)}
        </div>
      </div>
    </Card>
  );
};

export default ProjectDetailsCollapsible; 