import React, { useState } from 'react';
import { Card, CardContent } from "../ui/card.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { cn } from "../../lib/utils.js";
import { motion, AnimatePresence } from "framer-motion";
import { 
  ChevronLeft,
  Calendar,
  Clock,
  Users,
  Music,
  CheckCircle2,
  CircleDashed,
  CircleDot,
  Plus,
  Trash2,
  Edit,
  PlayCircle,
  Link,
  Hash,
  Mic,
  Timer,
  CheckCircle,
  XCircle,
  MoreVertical,
  Pencil,
  DollarSign,
  Check
} from "lucide-react";
import { PROJECT_STATUSES, TRACK_STATUSES } from '../../data/projectConstants.js';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu.jsx";
import MobileTrackForm from './MobileTrackForm.js';

const INITIAL_TRACK = {
  title: '',
  status: 'WRITING',
  duration: '',
  bpm: '',
  key: '',
  recordingLocation: '',
  demoLink: '',
  features: [],
  notes: ''
};

const getStatusIcon = (status) => {
  switch (status.toLowerCase()) {
    case 'complete':
      return <CheckCircle2 className="h-4 w-4 text-green-500" />;
    case 'in_progress':
      return <CircleDot className="h-4 w-4 text-blue-500" />;
    default:
      return <CircleDashed className="h-4 w-4 text-muted-foreground" />;
  }
};

const getStatusColor = (status) => {
  const statusInfo = PROJECT_STATUSES[status] || PROJECT_STATUSES.PLANNING;
  return `${statusInfo.lightColor} ${statusInfo.textColor} border ${statusInfo.borderColor}`;
};

const getTrackStatusColor = (status) => {
  const statusInfo = TRACK_STATUSES[status] || TRACK_STATUSES.WRITING;
  return `${statusInfo.lightColor} ${statusInfo.textColor} border ${statusInfo.borderColor}`;
};

const AudioPlayer = ({ demoLink }) => {
  if (!demoLink) return null;

  return (
    <div className="flex items-center gap-2 mt-2">
      <a
        href={demoLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-xs text-primary hover:text-primary/80 flex items-center gap-1"
      >
        <PlayCircle className="h-3 w-3" />
        Play Demo
      </a>
    </div>
  );
};

const getProgressBarColor = (progress) => {
  if (progress === 100) return 'bg-green-500';
  if (progress >= 80) return 'bg-emerald-500';
  if (progress >= 60) return 'bg-blue-500';
  if (progress >= 40) return 'bg-amber-500';
  if (progress >= 20) return 'bg-orange-500';
  return 'bg-red-500';
};

const calculateProgress = (project) => {
  let totalWeight = 0;
  let completedWeight = 0;

  if (project.tasks && project.tasks.length > 0) {
    const completedTasks = project.tasks.filter(task => task.completed).length;
    totalWeight += 40;
    completedWeight += (completedTasks / project.tasks.length) * 40;
  }

  if (project.tracks && project.tracks.length > 0) {
    totalWeight += 60;
    project.tracks.forEach(track => {
      let trackProgress = 0;
      switch (track.status) {
        case 'WRITING': trackProgress = 0.2; break;
        case 'RECORDING': trackProgress = 0.4; break;
        case 'MIXING': trackProgress = 0.6; break;
        case 'MASTERING': trackProgress = 0.8; break;
        case 'COMPLETE': trackProgress = 1; break;
        default: trackProgress = 0;
      }
      completedWeight += (trackProgress * 60) / project.tracks.length;
    });
  }

  return totalWeight === 0 ? 0 : Math.round((completedWeight / totalWeight) * 100);
};

const MobileProjectDetails = ({ 
  project, 
  onClose,
  onAddTask,
  onToggleTask,
  onDeleteTask,
  onAddTrack,
  onEditTrack,
  onDeleteTrack,
  onAddCollaborator,
  onRemoveCollaborator
}) => {
  const [activeSection, setActiveSection] = useState('overview');
  const [showTrackForm, setShowTrackForm] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(INITIAL_TRACK);
  const [isEditingTrack, setIsEditingTrack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newTask, setNewTask] = useState('');
  const [newCollaborator, setNewCollaborator] = useState('');

  const handleTrackFormSubmit = async (trackData) => {
    setIsLoading(true);
    try {
      if (isEditingTrack) {
        await onEditTrack(project.id, currentTrack.id, trackData);
      } else {
        await onAddTrack(trackData);
      }
      setShowTrackForm(false);
      setCurrentTrack(INITIAL_TRACK);
      setIsEditingTrack(false);
    } catch (error) {
      console.error('Error handling track:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTrackClick = (track) => {
    setCurrentTrack(track);
    setIsEditingTrack(true);
    setShowTrackForm(true);
  };

  const handleAddTrackClick = () => {
    setCurrentTrack(INITIAL_TRACK);
    setIsEditingTrack(false);
    setShowTrackForm(true);
  };

  const handleAddTask = (e) => {
    e.preventDefault();
    if (newTask.trim()) {
      const task = {
        id: Date.now(),
        text: newTask.trim(),
        completed: false
      };
      onAddTask(task);
      setNewTask('');
    }
  };

  const handleToggleTask = (taskId) => {
    const task = project.tasks.find(t => t.id === taskId);
    if (task) {
      onToggleTask(taskId);
    }
  };

  const handleAddCollaborator = (e) => {
    e.preventDefault();
    if (newCollaborator.trim()) {
      onAddCollaborator(newCollaborator);
      setNewCollaborator('');
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="sticky top-0 z-10 bg-background/95 backdrop-blur-lg border-b">
        <div className="p-4">
          <div className="flex items-center gap-3 mb-4">
            <Button
              variant="ghost"
              size="icon"
              onClick={onClose}
              className="shrink-0 rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
            >
              <ChevronLeft className="h-5 w-5" />
            </Button>
            <div className="flex-1 min-w-0">
              <h2 className="font-semibold truncate">{project.title}</h2>
              <p className="text-sm text-muted-foreground truncate">{project.artist}</p>
            </div>
            <Badge className={cn("shrink-0", getStatusColor(project.status))}>
              <span className="flex items-center gap-1.5">
                {getStatusIcon(project.status)}
                {PROJECT_STATUSES[project.status]?.label || project.status}
              </span>
            </Badge>
          </div>

          <div className="flex gap-2">
            {['overview', 'tasks', 'tracks', 'collaborators'].map((section) => (
              <Button
                key={section}
                variant={activeSection === section ? 'secondary' : 'ghost'}
                size="sm"
                onClick={() => setActiveSection(section)}
                className={cn(
                  "flex-1 transition-all duration-200 active:scale-95 touch-manipulation",
                  activeSection === section && "bg-primary/10"
                )}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Button>
            ))}
          </div>
        </div>
      </div>

      <ScrollArea className="flex-1 pb-32 touch-manipulation">
        <div className="p-4">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeSection}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="pb-16"
            >
              {activeSection === 'overview' && (
                <div className="space-y-6">
                  {/* Progress */}
                  <Card>
                    <CardContent className="p-4 space-y-3">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium">Progress</h3>
                        <span className="text-sm font-medium">{calculateProgress(project)}%</span>
                      </div>
                      <div className="w-full bg-muted rounded-full h-2.5 overflow-hidden">
                        <motion.div
                          className={`${getProgressBarColor(calculateProgress(project))} h-2.5 rounded-full`}
                          initial={{ width: 0 }}
                          animate={{ width: `${calculateProgress(project)}%` }}
                          transition={{ duration: 0.5, ease: "easeOut" }}
                        />
                      </div>
                      {project.tracks?.length > 0 && (
                        <div className="flex items-center justify-between text-sm text-muted-foreground">
                          <span>Completed Tracks</span>
                          <span>
                            {project.tracks.filter(t => t.status === 'COMPLETE').length}/{project.tracks.length}
                          </span>
                        </div>
                      )}
                    </CardContent>
                  </Card>

                  {/* Details */}
                  <Card>
                    <CardContent className="p-4 space-y-4">
                      <h3 className="font-medium">Project Details</h3>
                      <div className="grid gap-4">
                        <div className="flex items-center gap-3 text-sm">
                          <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                            <Calendar className="h-4 w-4 text-primary" />
                          </div>
                          <div>
                            <p className="text-muted-foreground">Start Date</p>
                            <p className="font-medium">
                              {new Date(project.startDate).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center gap-3 text-sm">
                          <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                            <Clock className="h-4 w-4 text-primary" />
                          </div>
                          <div>
                            <p className="text-muted-foreground">Deadline</p>
                            <p className="font-medium">
                              {new Date(project.deadline).toLocaleDateString()}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center gap-3 text-sm">
                          <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                            <Users className="h-4 w-4 text-primary" />
                          </div>
                          <div>
                            <p className="text-muted-foreground">Producer</p>
                            <p className="font-medium">
                              {project.producer || 'Not assigned'}
                            </p>
                          </div>
                        </div>
                        {project.budget && (
                          <div className="flex items-center gap-3 text-sm">
                            <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                              <DollarSign className="h-4 w-4 text-primary" />
                            </div>
                            <div>
                              <p className="text-muted-foreground">Budget</p>
                              <p className="font-medium">
                                RM {project.budget}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}

              {activeSection === 'tasks' && (
                <div className="space-y-4">
                  {/* Add Task Form */}
                  <Card>
                    <CardContent className="p-4">
                      <form onSubmit={handleAddTask} className="flex gap-2">
                        <Input
                          value={newTask}
                          onChange={(e) => setNewTask(e.target.value)}
                          placeholder="Add a new task..."
                          className="flex-1 text-base"
                          style={{ fontSize: '16px' }}
                        />
                        <Button 
                          type="submit" 
                          disabled={!newTask.trim()}
                          className="shrink-0 active:scale-95 transition-transform duration-200"
                        >
                          <Plus className="h-4 w-4" />
                        </Button>
                      </form>
                    </CardContent>
                  </Card>

                  {/* Tasks List */}
                  <div className="space-y-2">
                    {project.tasks?.map((task) => (
                      <motion.div
                        key={task.id}
                        layout
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                      >
                        <Card>
                          <CardContent className="p-4">
                            <div className="flex items-start gap-3">
                              <div 
                                className="mt-1 touch-manipulation"
                                onClick={() => handleToggleTask(task.id)}
                              >
                                <div className={cn(
                                  "w-5 h-5 rounded-full border-2 flex items-center justify-center transition-colors",
                                  task.completed ? "bg-primary border-primary" : "border-muted-foreground"
                                )}>
                                  {task.completed && <Check className="h-3 w-3 text-primary-foreground" />}
                                </div>
                              </div>
                              <span className={cn(
                                "flex-1 text-sm transition-colors",
                                task.completed && "line-through text-muted-foreground"
                              )}>
                                {task.text}
                              </span>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => onDeleteTask(task.id)}
                                className="shrink-0 h-6 w-6 p-0 hover:text-destructive active:scale-95 transition-transform duration-200"
                              >
                                <XCircle className="h-5 w-5" />
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </motion.div>
                    ))}
                  </div>
                </div>
              )}

              {activeSection === 'tracks' && (
                <div className="space-y-4">
                  {!showTrackForm ? (
                    <>
                      <div className="flex justify-between items-center">
                        <h3 className="font-medium">Project Tracks</h3>
                        <Button
                          size="sm"
                          onClick={handleAddTrackClick}
                          className="flex items-center gap-1"
                        >
                          <Plus className="h-4 w-4" />
                          Add Track
                        </Button>
                      </div>

                      {project.tracks?.length > 0 ? (
                        <div className="space-y-3">
                          {project.tracks.map((track) => (
                            <Card key={track.id} className="p-4">
                              <div className="flex items-start justify-between">
                                <div>
                                  <h4 className="font-medium">{track.title}</h4>
                                  <p className="text-sm text-muted-foreground">
                                    {track.description || 'No description'}
                                  </p>
                                  {track.status && (
                                    <Badge className={cn("mt-2", getTrackStatusColor(track.status))}>
                                      {TRACK_STATUSES[track.status]?.label || track.status}
                                    </Badge>
                                  )}
                                  <AudioPlayer demoLink={track.demoLink} />
                                </div>
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" size="icon">
                                      <MoreVertical className="h-4 w-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    <DropdownMenuItem onClick={() => handleEditTrackClick(track)}>
                                      <Pencil className="h-4 w-4 mr-2" />
                                      Edit
                                    </DropdownMenuItem>
                                    <DropdownMenuItem 
                                      className="text-destructive"
                                      onClick={() => onDeleteTrack(project.id, track.id)}
                                    >
                                      <Trash2 className="h-4 w-4 mr-2" />
                                      Delete
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            </Card>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center py-8 text-muted-foreground">
                          <Music className="h-12 w-12 mx-auto mb-3 opacity-50" />
                          <p>No tracks added yet</p>
                          <p className="text-sm">Add tracks to start managing your project</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <div className="flex items-center gap-2 mb-4">
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => setShowTrackForm(false)}
                          className="flex items-center gap-1"
                        >
                          <ChevronLeft className="h-4 w-4" />
                          Back to Tracks
                        </Button>
                        <h3 className="font-medium">{isEditingTrack ? 'Edit Track' : 'Add New Track'}</h3>
                      </div>
                      <MobileTrackForm
                        isEditing={isEditingTrack}
                        track={currentTrack}
                        onTrackChange={setCurrentTrack}
                        onSubmit={handleTrackFormSubmit}
                        onCancel={() => {
                          setShowTrackForm(false);
                          setCurrentTrack(INITIAL_TRACK);
                          setIsEditingTrack(false);
                        }}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </div>
              )}

              {activeSection === 'collaborators' && (
                <div className="space-y-4">
                  {/* Add Collaborator Form */}
                  <Card>
                    <CardContent className="p-4">
                      <form onSubmit={handleAddCollaborator} className="flex gap-2">
                        <Input
                          value={newCollaborator}
                          onChange={(e) => setNewCollaborator(e.target.value)}
                          placeholder="Add collaborator..."
                          className="flex-1 text-base"
                          style={{ fontSize: '16px' }}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleAddCollaborator();
                            }
                          }}
                        />
                        <Button type="submit" disabled={!newCollaborator.trim()}>
                          <Plus className="h-4 w-4" />
                        </Button>
                      </form>
                    </CardContent>
                  </Card>

                  {/* Collaborators List */}
                  <div className="space-y-2">
                    {project.collaborators?.map((collaborator, index) => (
                      <Card key={index}>
                        <CardContent className="p-4">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              <Users className="h-4 w-4 text-muted-foreground" />
                              <span>{collaborator}</span>
                            </div>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => onRemoveCollaborator(collaborator)}
                              className="h-8 w-8 p-0 hover:text-destructive"
                            >
                              <XCircle className="h-5 w-5" />
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </div>
              )}
            </motion.div>
          </AnimatePresence>
        </div>
      </ScrollArea>
    </div>
  );
};

export default MobileProjectDetails; 