import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function openWhatsAppNotification(artistName, title, type = 'track') {
  const message = encodeURIComponent(`Hi nur, sy ada update ${type === 'track' ? 'lagu' : 'shows'} baru untuk ${artistName} - ${title} ${new Date().toLocaleDateString()}, boleh check https://dashboard.slatan.world untuk details`);
  const whatsappUrl = `https://wa.me/60176897314?text=${message}`;
  window.open(whatsappUrl, '_blank');
}
