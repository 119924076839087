import { useState, useEffect } from 'react';

export const useSearch = (initialCatalog) => {
  const [catalog, setCatalog] = useState(initialCatalog);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCatalog, setFilteredCatalog] = useState(catalog);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortedCatalog, setSortedCatalog] = useState(null);

  // Update local catalog when initialCatalog changes
  useEffect(() => {
    console.log('Initial catalog updated:', initialCatalog);
    setCatalog(initialCatalog);
    if (!searchTerm) {
      setFilteredCatalog(initialCatalog);
    } else {
      // Re-run search with new catalog
      searchCatalog(searchTerm, initialCatalog);
    }
  }, [initialCatalog]);

  const searchCatalog = (term, catalogToUse = catalog) => {
    console.log('Search initiated with term:', term);
    const effectiveCatalog = sortedCatalog || catalogToUse;
    console.log('Searching in catalog:', effectiveCatalog);
    
    if (!term || term.trim() === '') {
      setFilteredCatalog(effectiveCatalog);
      return;
    }

    const normalizedTerm = term.toLowerCase().trim();
    const results = {};
    
    Object.entries(effectiveCatalog).forEach(([artist, songs]) => {
      if (!songs) return; // Skip if songs array is undefined
      
      console.log(`Processing artist: ${artist}, Songs count: ${songs.length}`);
      
      const filteredSongs = songs.filter(song => {
        if (!song || !song.songDetails) return false;
        
        // Check song title
        const titleMatch = song.songDetails.title?.toLowerCase().includes(normalizedTerm);
        
        // Check artist name
        const artistMatch = artist.toLowerCase().includes(normalizedTerm);
        
        // Check release date
        const releaseDateMatch = song.songDetails.releaseDate?.includes(normalizedTerm);
        
        // Additional checks for ISRC and UPC
        const isrcMatch = song.songDetails.isrc?.toLowerCase().includes(normalizedTerm);
        const upcMatch = song.songDetails.upc?.toLowerCase().includes(normalizedTerm);

        // Check song type and language
        const typeMatch = song.songDetails.type?.toLowerCase().includes(normalizedTerm);
        const languageMatch = song.songDetails.language?.toLowerCase().includes(normalizedTerm);

        return titleMatch || artistMatch || releaseDateMatch || isrcMatch || upcMatch || typeMatch || languageMatch;
      });
      
      if (filteredSongs.length > 0) {
        results[artist] = filteredSongs;
      }
    });
    
    console.log('Search results:', results);
    setFilteredCatalog(Object.keys(results).length > 0 ? results : { 'No Results': [] });
  };

  const handleSearch = (term) => {
    console.log('handleSearch called with term:', term);
    setSearchTerm(term);
    searchCatalog(term);
  };

  const sortByReleaseDate = () => {
    const catalogToSort = sortedCatalog || filteredCatalog;
    const sorted = {};
    Object.entries(catalogToSort).forEach(([artist, songs]) => {
      if (!songs) return; // Skip if songs array is undefined
      sorted[artist] = [...songs].sort((a, b) => {
        const dateA = a.songDetails?.releaseDate ? new Date(a.songDetails.releaseDate) : new Date('9999-12-31');
        const dateB = b.songDetails?.releaseDate ? new Date(b.songDetails.releaseDate) : new Date('9999-12-31');
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
    });
    setFilteredCatalog(sorted);
    setSortedCatalog(sorted);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return { searchTerm, setSearchTerm, catalog, setCatalog, filteredCatalog, setFilteredCatalog, handleSearch, sortByReleaseDate, sortOrder };
};