import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './lib/AuthContext.js';
import ProtectedRoute from './components/auth/ProtectedRoute.js';
import AdminRoute from './components/auth/AdminRoute.js';
import Login from './components/auth/Login.js';
import './App.css';
import { ThemeProvider } from "next-themes";
import ArtistCatalog from './components/catalogmanagement/ArtistCatalog.js';
import ProjectManagementTab from './components/projectmanagement/ProjectManagementTab.js';
import { Toaster } from './components/ui/toaster.jsx';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          
          {/* Protected routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
                  <div className="min-h-screen bg-background font-sans antialiased">
                    <ArtistCatalog />
                    <Toaster />
                  </div>
                </ThemeProvider>
              </ProtectedRoute>
            }
          />

          {/* Admin-only routes */}
          <Route
            path="/admin/*"
            element={
              <AdminRoute>
                <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
                  <div className="min-h-screen bg-background font-sans antialiased">
                    <ProjectManagementTab />
                    <Toaster />
                  </div>
                </ThemeProvider>
              </AdminRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;