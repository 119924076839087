import React from 'react';
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { Label } from "../ui/label.jsx";
import { motion } from "framer-motion";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select.jsx";
import {
  PlusIcon,
  XIcon,
  EditIcon,
  ActivityIcon,
  TimerIcon,
  AlertCircleIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  BanIcon
} from "lucide-react";
import { PROJECT_STATUSES } from '../../data/projectConstants.js';

const ProjectForm = ({ 
  isEditMode, 
  newProject, 
  setNewProject, 
  handleAddProject, 
  onCancel,
  isLoading 
}) => {
  const statusIcons = {
    PLANNING: <TimerIcon className="h-4 w-4" />,
    IN_PROGRESS: <AlertCircleIcon className="h-4 w-4" />,
    REVIEW: <ChevronDownIcon className="h-4 w-4" />,
    COMPLETE: <CheckCircleIcon className="h-4 w-4" />,
    ON_HOLD: <BanIcon className="h-4 w-4" />
  };

  const handleSubmit = async () => {
    const projectData = {
      ...newProject,
      tasks: newProject.tasks || [],
      collaborators: newProject.collaborators || [],
      tracks: newProject.tracks || []
    };
    await handleAddProject(projectData, isEditMode);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="title" className="text-sm font-medium">Project Title</Label>
          <Input
            id="title"
            value={newProject.title}
            onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
            placeholder="Album/EP Title"
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="artist" className="text-sm font-medium">Artist</Label>
          <Input
            id="artist"
            value={newProject.artist}
            onChange={(e) => setNewProject({ ...newProject, artist: e.target.value })}
            placeholder="Artist Name"
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="status" className="text-sm font-medium">Status</Label>
          <Select 
            value={newProject.status}
            onValueChange={(value) => setNewProject({ ...newProject, status: value })}
          >
            <SelectTrigger className="mt-1">
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(PROJECT_STATUSES).map(([key, { label }]) => (
                <SelectItem key={key} value={key}>
                  <div className="flex items-center">
                    {statusIcons[key]}
                    <span className="ml-2">{label}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <Label htmlFor="startDate" className="text-sm font-medium">Start Date</Label>
          <Input
            id="startDate"
            type="date"
            value={newProject.startDate}
            onChange={(e) => setNewProject({ ...newProject, startDate: e.target.value })}
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="deadline" className="text-sm font-medium">Deadline</Label>
          <Input
            id="deadline"
            type="date"
            value={newProject.deadline}
            onChange={(e) => setNewProject({ ...newProject, deadline: e.target.value })}
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="budget" className="text-sm font-medium">Budget (RM)</Label>
          <Input
            id="budget"
            type="text"
            value={newProject.budget}
            onChange={(e) => {
              const value = e.target.value.replace(/[^0-9]/g, '');
              setNewProject({ ...newProject, budget: value });
            }}
            placeholder="Project Budget"
            className="mt-1"
          />
        </div>
      </div>
      <div className="md:col-span-2 space-y-4">
        <div>
          <Label htmlFor="genre" className="text-sm font-medium">Genre</Label>
          <Input
            id="genre"
            value={newProject.genre}
            onChange={(e) => setNewProject({ ...newProject, genre: e.target.value })}
            placeholder="Music Genre"
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="producer" className="text-sm font-medium">Producer</Label>
          <Input
            id="producer"
            value={newProject.producer}
            onChange={(e) => setNewProject({ ...newProject, producer: e.target.value })}
            placeholder="Producer Name"
            className="mt-1"
          />
        </div>
        <div>
          <Label htmlFor="notes" className="text-sm font-medium">Notes</Label>
          <textarea
            id="notes"
            className="w-full p-2 rounded-md border min-h-[100px] mt-1"
            value={newProject.notes}
            onChange={(e) => setNewProject({ ...newProject, notes: e.target.value })}
            placeholder="Project notes..."
          />
        </div>
      </div>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="md:col-span-2 flex justify-end space-x-2 mt-6"
      >
        <Button 
          variant="outline" 
          onClick={onCancel}
          className="transition-all duration-300 hover:scale-105"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          className="min-w-[120px] transition-all duration-300 hover:scale-105"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                className="mr-2"
              >
                <ActivityIcon className="h-4 w-4" />
              </motion.div>
              {isEditMode ? 'Updating...' : 'Adding...'}
            </div>
          ) : (
            isEditMode ? 'Update Project' : 'Add Project'
          )}
        </Button>
      </motion.div>
    </div>
  );
};

export default ProjectForm; 