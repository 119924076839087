import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTheme } from 'next-themes';
import { motion, AnimatePresence } from 'framer-motion';

const formatRevenue = (value) => {
  if (value >= 1000000) return `€${(value / 1000000).toFixed(2).replace('.', ',')}M`;
  if (value >= 1000) return `€${(value / 1000).toFixed(2)}K`;
  return `€${value.toFixed(2)}`;
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-background border border-border p-3 rounded-lg shadow-lg">
        <p className="font-semibold text-foreground">{data.name}</p>
        <p className="text-sm text-muted-foreground">Revenue: {formatRevenue(data.revenue)}</p>
        <p className="text-sm text-muted-foreground">Share: {data.percentage}%</p>
      </div>
    );
  }
  return null;
};

const ArtistBreakdown = ({ data }) => {
  const { theme } = useTheme();
  const [expandedArtist, setExpandedArtist] = useState(null);

  const totalRevenue = data.reduce((sum, item) => sum + item.revenue, 0);

  const formattedData = data.map((item, index) => ({
    ...item,
    formattedRevenue: formatRevenue(item.revenue),
    percentage: ((item.revenue / totalRevenue) * 100).toFixed(2),
    fill: `hsl(var(--chart-${(index % 10) + 1}))`
  }));

  return (
    <div className="space-y-8">
      <div className="h-[400px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={formattedData}
              dataKey="revenue"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
              labelLine={false}
            >
              {formattedData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={entry.fill}
                  className="stroke-background dark:stroke-background"
                  strokeWidth={2}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend 
              formatter={(value, entry) => (
                <span className="text-foreground">{value}</span>
              )}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead>
            <tr className="border-b border-border bg-muted/40">
              <th scope="col" className="px-6 py-3 text-left font-medium text-muted-foreground">Artist</th>
              <th scope="col" className="px-6 py-3 text-right font-medium text-muted-foreground">Revenue</th>
              <th scope="col" className="px-6 py-3 text-right font-medium text-muted-foreground">% of Total</th>
              <th scope="col" className="px-6 py-3 text-center font-medium text-muted-foreground">Details</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-border">
            {formattedData.map((artist, index) => (
              <React.Fragment key={artist.name}>
                <motion.tr
                  initial={false}
                  animate={{ backgroundColor: expandedArtist === artist.name ? 'hsl(var(--muted))' : 'transparent' }}
                  className="group hover:bg-muted/50 transition-colors"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <span 
                        className="w-2 h-2 rounded-full" 
                        style={{ backgroundColor: artist.fill }}
                      />
                      <span className="font-medium text-foreground">{artist.name}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 text-right font-medium text-foreground">{artist.formattedRevenue}</td>
                  <td className="px-6 py-4 text-right text-foreground">{artist.percentage}%</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => setExpandedArtist(expandedArtist === artist.name ? null : artist.name)}
                      className="mx-auto flex items-center justify-center w-8 h-8 rounded-full hover:bg-accent/50 transition-colors text-muted-foreground hover:text-accent-foreground"
                    >
                      {expandedArtist === artist.name ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </button>
                  </td>
                </motion.tr>
                <AnimatePresence>
                  {expandedArtist === artist.name && (
                    <motion.tr
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      className="bg-muted/30"
                    >
                      <td colSpan="4" className="px-6 py-4">
                        <div className="text-sm space-y-2">
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <p className="text-muted-foreground">Number of tracks</p>
                              <p className="font-medium text-foreground">{artist.trackCount}</p>
                            </div>
                            <div>
                              <p className="text-muted-foreground">Top track</p>
                              <p className="font-medium text-foreground">{artist.topTrack}</p>
                            </div>
                            <div>
                              <p className="text-muted-foreground">Average revenue per track</p>
                              <p className="font-medium text-foreground">{formatRevenue(artist.revenue / artist.trackCount)}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </motion.tr>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ArtistBreakdown;