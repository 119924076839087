import React, { useState } from 'react';
import { Card, CardHeader, CardContent } from '../ui/card.jsx';
import { Button } from '../ui/button.jsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Fixed exchange rate for demonstration (1 EUR = 4.70 MYR as of August 15, 2024)
const EUR_TO_MYR_RATE = 4.70;

const ReportGenerator = ({ data, selectedArtist, uploadDate }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationSuccess, setGenerationSuccess] = useState(false);

  if (!data || !data.artistData || !data.trackData || !data.serviceData || !data.usageData || !selectedArtist) {
    return <div>Error: Incomplete or invalid data. Please ensure all required data is uploaded.</div>;
  }

  const artist = data.artistData.find(a => a.name === selectedArtist);
  if (!artist) return <div>Error: Selected artist not found in the data.</div>;

  const logoUrl = "https://d2vwpu9ddd6iwd.cloudfront.net/creators/8cb165db-9d13-47c3-8cb9-888c5f8b98ef/1696565541.png";
  const totalRevenueEUR = artist.revenue.toFixed(2);
  const totalRevenueMYR = (artist.revenue * EUR_TO_MYR_RATE).toFixed(2);
  const payeeSplitEUR = (artist.revenue * 0.2).toFixed(2);
  const payeeSplitMYR = (artist.revenue * 0.2 * EUR_TO_MYR_RATE).toFixed(2);
  const amountPayableEUR = (artist.revenue * 0.8).toFixed(2);
  const amountPayableMYR = (artist.revenue * 0.8 * EUR_TO_MYR_RATE).toFixed(2);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
  };

  const currentDate = new Date();
  const reportMonth = formatDate(currentDate);
  const mostRecentDate = new Date(Math.max(...data.trackData
    .filter(track => track.artist === selectedArtist)
    .map(track => new Date(track.date))));
  const lastReportQuarter = Math.floor((mostRecentDate.getMonth() + 3) / 3);
  const lastReportYear = mostRecentDate.getFullYear();
  const royaltiesUntil = formatDate(mostRecentDate);
  const artistTracks = data.trackData.filter(track => track.artist === selectedArtist);
  const artistServices = data.serviceData.filter(service => service.artist === selectedArtist);
  const artistUsages = data.usageData.filter(usage => usage.artist === selectedArtist);

  const tracksByMonth = artistTracks.reduce((acc, track) => {
    const month = new Date(track.date).toLocaleString('default', { month: 'short', year: 'numeric' });
    if (!acc[month]) acc[month] = [];
    acc[month].push(track);
    return acc;
  }, {});

  const generatePDF = () => {
    setIsGenerating(true);
    setGenerationSuccess(false);

    const doc = new jsPDF();

    // Add logo placeholder
    doc.addImage(logoUrl, 'PNG', 14, 15, 40, 40);

    // Add title and company details
    doc.setFontSize(18);
    doc.setFont(undefined, 'bold');
    doc.text('0108 SLATAN SOLUTION (003029866-H)', 60, 30);
    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    doc.text([
      '24, Jalan SS 1/5',
      'Petaling Jaya',
      'Malaysia',
      'invoice@slatan.world',
      'https://slatan.world'
    ], 60, 40);

    // Add horizontal line
    doc.setLineWidth(0.5);
    doc.line(14, 65, 196, 65);

    // Add report details with MYR conversion
    doc.setFontSize(12);
    doc.text([
      `Inv: ${selectedArtist} Q${lastReportQuarter} ${lastReportYear} Report (${reportMonth})`,
      `Account: ${selectedArtist} (Artist)`,
      `Royalties until: ${royaltiesUntil}`,
      `Royalties total: € ${totalRevenueEUR} (RM ${totalRevenueMYR})`,
      `Payee Split (20%): € ${payeeSplitEUR} (RM ${payeeSplitMYR})`,
      `This Period Amount Payable: € ${amountPayableEUR} (RM ${amountPayableMYR})`
    ], 14, 75);

    // Add horizontal line
    doc.line(14, 100, 196, 100);

    // Add Track Earning Summary
    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text('Track Earning Summary', 14, 110);

    let yOffset = 120;
    Object.entries(tracksByMonth).forEach(([month, tracks]) => {
      doc.setFontSize(12);
      doc.setFont(undefined, 'bold');
      doc.text(month, 14, yOffset);

      doc.autoTable({
        startY: yOffset + 5,
        head: [['Artist', 'Release', 'Track', '€ Amount', 'Split %', '€ Total']],
        body: tracks.map(track => [
          selectedArtist,
          track.release,
          track.name,
          track.revenue.toFixed(2),
          '20.00%',
          (track.revenue * 0.8).toFixed(2)
        ]),
        theme: 'grid',
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0], fontStyle: 'bold' }
      });

      yOffset = doc.lastAutoTable.finalY + 10;
    });

    // Add Service and Usage Earning Summary
    const summaries = [
      { title: 'Service Earning Summary', data: artistServices, headers: [['Last reported', 'Service', 'Quantity', '€ Amount']] },
      { title: 'Usage Earning Summary', data: artistUsages, headers: [['Last reported', 'Usage', 'Quantity', 'EUR Amount']] }
    ];

    summaries.forEach(({ title, data, headers }, index) => {
      doc.addPage();
      doc.setFontSize(14);
      doc.setFont(undefined, 'bold');
      doc.text(title, 14, 20);

      doc.autoTable({
        startY: 30,
        head: headers,
        body: data.map(item => [
          formatDate(item.date),
          item.name,
          item.quantity.toLocaleString(),
          item.revenue.toFixed(2)
        ]),
        theme: 'grid',
        headStyles: { fillColor: [200, 200, 200], textColor: [0, 0, 0], fontStyle: 'bold' }
      });
    });

    // Save the PDF
    const fileName = `${selectedArtist} Q${lastReportQuarter} ${lastReportYear} Report.pdf`;
    doc.save(fileName);
    setIsGenerating(false);
    setGenerationSuccess(true);
  };

  return (
    <div>
      <Button
        onClick={generatePDF}
        className="mb-4"
        disabled={isGenerating}
      >
        {isGenerating ? 'Generating PDF...' : 'Export Report as PDF'}
      </Button>
      {generationSuccess && (
        <div className="text-green-600 mb-4">PDF generated and downloaded successfully!</div>
      )}
      <Card id="artist-report" className="w-full mx-auto overflow-hidden">
        <CardHeader className="flex items-start justify-between mb-4 bg-card p-6">
          <div className="w-40 h-40 flex items-center justify-center mr-4">
            <img src={logoUrl} alt="Slatan Logo" className="max-w-full max-h-full" />
          </div>
          <h2 className="text-2xl font-bold text-primary">0108 SLATAN SOLUTION (003029866-H)</h2>
          <div className="space-y-2 text-muted-foreground">
            <p>24, Jalan SS 1/5</p>
            <p>Petaling Jaya</p>
            <p>Malaysia</p>
            <p>invoice@slatan.world</p>
            <p>https://slatan.world</p>
          </div>
        </CardHeader>
        <CardContent className="p-6 space-y-6">
          <hr className="border-t border-gray-300" />

          <div className="space-y-2">
            <p className="font-semibold">Inv: {selectedArtist} Q{lastReportQuarter} {lastReportYear} Report ({reportMonth})</p>
            <p><span className="font-semibold">Account:</span> {selectedArtist} (Artist)</p>
            <p><span className="font-semibold">Royalties until:</span> {royaltiesUntil}</p>
            <p><span className="font-semibold">Royalties total:</span> €{totalRevenueEUR} (RM {totalRevenueMYR})</p>
            <p><span className="font-semibold">Payee Split (20%):</span> €{payeeSplitEUR} (RM {payeeSplitMYR})</p>
            <p><span className="font-semibold">This Period Amount Payable:</span> €{amountPayableEUR} (RM {amountPayableMYR})</p>
          </div>

          <hr className="border-t border-gray-300" />

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Track Earning Summary</h3>
            <p className="text-sm text-gray-600">See accompanying spreadsheet for more detail.</p>

            {Object.entries(tracksByMonth).map(([month, tracks]) => (
              <div key={month} className="space-y-2">
                <h4 className="font-semibold">{month}</h4>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="px-4 py-2 text-left font-bold">Artist</th>
                        <th className="px-4 py-2 text-left font-bold">Release</th>
                        <th className="px-4 py-2 text-left font-bold">Track</th>
                        <th className="px-4 py-2 text-right font-bold">€ Amount</th>
                        <th className="px-4 py-2 text-right font-bold">Split %</th>
                        <th className="px-4 py-2 text-right font-bold">€ Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tracks.map((track, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                          <td className="px-4 py-2">{selectedArtist}</td>
                          <td className="px-4 py-2">{track.release}</td>
                          <td className="px-4 py-2">{track.name}</td>
                          <td className="px-4 py-2 text-right">{track.revenue.toFixed(2)}</td>
                          <td className="px-4 py-2 text-right">20.00%</td>
                          <td className="px-4 py-2 text-right">{(track.revenue * 0.8).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>

          <hr className="border-t border-gray-300" />

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Service Earning Summary</h3>
            <p className="text-sm text-gray-600">See accompanying spreadsheet for more detail.</p>
            <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left font-bold">Last reported</th>
                    <th className="px-4 py-2 text-left font-bold">Service</th>
                    <th className="px-4 py-2 text-right font-bold">Quantity</th>
                    <th className="px-4 py-2 text-right font-bold">€ Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {artistServices.map((service, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-2">{formatDate(service.date)}</td>
                      <td className="px-4 py-2">{service.name}</td>
                      <td className="px-4 py-2 text-right">{service.quantity.toLocaleString()}</td>
                      <td className="px-4 py-2 text-right">{service.revenue.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <hr className="border-t border-gray-300" />

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Usage Earning Summary</h3>
            <p className="text-sm text-gray-600">See accompanying spreadsheet for more detail.</p>
            <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-2 text-left font-bold">Last reported</th>
                    <th className="px-4 py-2 text-left font-bold">Usage</th>
                    <th className="px-4 py-2 text-right font-bold">Quantity</th>
                    <th className="px-4 py-2 text-right font-bold">EUR Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {artistUsages.map((usage, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-4 py-2">{formatDate(usage.date)}</td>
                      <td className="px-4 py-2">{usage.name}</td>
                      <td className="px-4 py-2 text-right">{usage.quantity.toLocaleString()}</td>
                      <td className="px-4 py-2 text-right">{usage.revenue.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ReportGenerator;