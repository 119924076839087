import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../ui/card.jsx";
import { Button } from "../ui/button.jsx";
import { Input } from "../ui/input.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { Badge } from "../ui/badge.jsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select.jsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../ui/dialog.jsx";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "../../lib/utils.js";
import { SHOW_TYPES, SHOW_STATUS, VENUE_TYPES, DATE_RANGES } from '../../data/constants.js';
import { supabase } from '../../lib/supabase.js';
import { useAuth } from '../../lib/AuthContext.js';
import AddEditShowModal from '../catalogmanagement/AddEditShowModal.js';
import { toast } from 'react-hot-toast';
import { 
  Calendar, 
  MapPin, 
  Clock, 
  Users, 
  Plus, 
  Music, 
  ListMusic, 
  Download, 
  Edit2,
  Filter,
  Search,
  X
} from 'lucide-react';

const SetlistModal = ({ isOpen, onClose, setlist, show }) => {
  const calculateTotalDuration = (songs) => {
    if (!songs?.length) return '0:00';
    
    const totalSeconds = songs.reduce((total, song) => {
      const duration = song.duration.replace(/'/g, ':').replace(/"/g, '');
      const [mins, secs] = duration.split(':').map(num => parseInt(num));
      return total + (mins * 60) + (secs || 0);
    }, 0);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <ListMusic className="h-5 w-5" />
              {show?.title}
            </div>
            <Badge variant="secondary" className="bg-primary/10 text-primary">
              Total: {calculateTotalDuration(setlist)}
            </Badge>
          </DialogTitle>
          <DialogDescription className="text-muted-foreground">
            {show?.artists?.join(', ')}
          </DialogDescription>
        </DialogHeader>

        <ScrollArea className="h-[400px]">
          <div className="space-y-2">
            {setlist?.map((song, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-3 rounded-lg bg-muted/50 hover:bg-muted/70 transition-colors group"
              >
                <div className="flex items-center gap-3">
                  <div className="text-sm text-muted-foreground w-6">{index + 1}</div>
                  <div>
                    <div className="font-medium">{song.title}</div>
                    <div className="text-sm text-muted-foreground flex items-center gap-2">
                      <span>{song.duration}</span>
                      <span>•</span>
                      <span>{song.artist}</span>
                    </div>
                  </div>
                </div>
                <Badge 
                  variant="secondary" 
                  className={cn(
                    "bg-primary/10 text-primary",
                    song.type === "Cover" && "bg-blue-500/10 text-blue-500"
                  )}
                >
                  {song.type}
                </Badge>
              </div>
            ))}

            {(!setlist || setlist.length === 0) && (
              <div className="text-center py-8 text-muted-foreground">
                <ListMusic className="h-8 w-8 mx-auto mb-2 opacity-50" />
                <p>No songs in setlist</p>
              </div>
            )}
          </div>
        </ScrollArea>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const ShowCard = ({ show, onEdit, isGuestMode }) => {
  const [showSetlist, setShowSetlist] = useState(false);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'upcoming':
        return 'bg-blue-500/10 text-blue-500 border-blue-500/20';
      case 'ongoing':
        return 'bg-green-500/10 text-green-500 border-green-500/20';
      case 'completed':
        return 'bg-gray-500/10 text-gray-500 border-gray-500/20';
      case 'cancelled':
        return 'bg-red-500/10 text-red-500 border-red-500/20';
      default:
        return 'bg-primary/10 text-primary border-primary/20';
    }
  };

  const getStatusText = (status) => {
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  const calculateTotalDuration = (songs) => {
    if (!songs?.length) return '0:00';
    
    const totalSeconds = songs.reduce((total, song) => {
      const duration = song.duration.replace(/'/g, ':').replace(/"/g, '');
      const [mins, secs] = duration.split(':').map(num => parseInt(num));
      return total + (mins * 60) + (secs || 0);
    }, 0);

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <motion.div
        layout
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
      >
        <Card className="bg-card hover:shadow-md transition-all duration-200 active:scale-[0.98]">
          <CardHeader className="pb-2">
            <div className="flex items-start justify-between">
              <div className="space-y-1 flex-1 min-w-0">
                <CardTitle className="text-lg flex items-center gap-2 truncate">
                  {show.title}
                </CardTitle>
                <CardDescription className="text-sm flex items-center gap-2 truncate">
                  <Users className="h-4 w-4 flex-shrink-0" />
                  {show.artists.join(', ')}
                </CardDescription>
              </div>
              <div className="flex items-center gap-2 flex-shrink-0">
                {!isGuestMode && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8 active:scale-95 transition-transform duration-200"
                    onClick={() => onEdit(show)}
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                )}
                <Badge 
                  variant="outline" 
                  className={cn(
                    "bg-primary/5 border-primary/20 text-primary",
                    getStatusColor(show.status)
                  )}
                >
                  {getStatusText(show.status)}
                </Badge>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2 text-sm">
                  <Calendar className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                  <span className="truncate">{show.date}</span>
                </div>
                {show.setlist?.length > 0 && (
                  <div className="flex items-center gap-2 text-sm justify-end">
                    <Clock className="h-4 w-4 text-muted-foreground flex-shrink-0" />
                    <span className="truncate">Total: {calculateTotalDuration(show.setlist)}</span>
                  </div>
                )}
              </div>
              
              <div className="flex items-start gap-2 text-sm">
                <MapPin className="h-4 w-4 text-muted-foreground mt-0.5 flex-shrink-0" />
                <div className="flex-1 min-w-0">
                  <div className="truncate">{show.venue}</div>
                  <div className="text-muted-foreground truncate">{show.location}</div>
                </div>
              </div>

              <div className="flex items-center justify-between pt-4 border-t">
                <div className="flex items-center gap-2">
                  {show.venueType && (
                    <Badge variant="secondary" className="bg-muted">
                      {show.venueType}
                    </Badge>
                  )}
                  {show.setlist?.length > 0 && (
                    <Badge variant="secondary" className="bg-muted">
                      <Music className="h-3 w-3 mr-1" />
                      {show.setlist.length} songs
                    </Badge>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  <Button 
                    variant="outline" 
                    size="sm"
                    className="hover:bg-primary/10 active:scale-95 transition-transform duration-200"
                    onClick={() => setShowSetlist(true)}
                  >
                    Setlist
                  </Button>
                  {show.ticketLink && (
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="hover:bg-primary hover:text-primary-foreground transition-colors active:scale-95 transition-transform duration-200"
                      onClick={() => window.open(show.ticketLink, '_blank')}
                    >
                      Event
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      <SetlistModal
        isOpen={showSetlist}
        onClose={() => setShowSetlist(false)}
        setlist={show.setlist}
        show={show}
      />
    </>
  );
};

const MobileShows = () => {
  const { isGuestMode } = useAuth();
  const [shows, setShows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedVenue, setSelectedVenue] = useState('all');
  const [selectedDateRange, setSelectedDateRange] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [editingShow, setEditingShow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  const handleDownload = () => {
    try {
      const headers = ['Title', 'Type', 'Status', 'Artists', 'Date', 'Venue', 'Venue Type', 'Location', 'Description', 'Ticket Link'];
      const csvData = shows.map(show => [
        show.title,
        show.type,
        show.status,
        show.artists.join(', '),
        show.date,
        show.venue,
        show.venueType,
        show.location,
        show.description,
        show.ticketLink
      ]);

      const csvContent = [
        headers.join(','),
        ...csvData.map(row => row.map(cell => `"${cell || ''}"`).join(','))
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'shows.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading shows:', error);
      toast.error('Failed to download shows data');
    }
  };

  useEffect(() => {
    const fetchShows = async () => {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('shows')
          .select('*')
          .order('date', { ascending: false });

        if (error) throw error;

        const formattedData = (data || []).map(show => ({
          id: show.id,
          title: show.title,
          type: show.type,
          status: show.status,
          artists: show.artists || [],
          date: show.date,
          venue: show.venue,
          venueType: show.venue_type,
          location: show.location,
          description: show.description,
          ticketLink: show.ticket_link,
          setlist: show.setlist || []
        }));

        setShows(formattedData);
      } catch (error) {
        console.error('Error fetching shows:', error);
        toast.error('Failed to load shows');
      } finally {
        setIsLoading(false);
      }
    };

    fetchShows();
  }, []);

  const handleEdit = (show) => {
    const showToEdit = {
      id: show.id,
      title: show.title,
      type: show.type,
      status: show.status,
      artists: show.artists || [],
      date: show.date,
      venue: show.venue,
      venueType: show.venueType,
      location: show.location,
      description: show.description,
      ticketLink: show.ticketLink,
      setlist: show.setlist || []
    };
    setEditingShow(showToEdit);
    setShowAddEdit(true);
  };

  const handleSave = async (updatedShow) => {
    try {
      const showData = {
        title: updatedShow.title,
        type: updatedShow.type,
        status: updatedShow.status,
        artists: updatedShow.artists,
        date: updatedShow.date,
        venue: updatedShow.venue,
        venue_type: updatedShow.venueType,
        location: updatedShow.location,
        description: updatedShow.description,
        ticket_link: updatedShow.ticketLink,
        setlist: updatedShow.setlist
      };

      if (editingShow) {
        const { error } = await supabase
          .from('shows')
          .update(showData)
          .eq('id', editingShow.id);

        if (error) throw error;

        setShows(prevShows => 
          prevShows.map(show => 
            show.id === editingShow.id ? {
              ...show,
              ...updatedShow,
              venueType: updatedShow.venueType,
              ticketLink: updatedShow.ticketLink
            } : show
          )
        );
        toast.success('Show updated successfully');
      } else {
        const { data, error } = await supabase
          .from('shows')
          .insert([showData])
          .select()
          .single();

        if (error) throw error;

        const newShow = {
          ...data,
          venueType: data.venue_type,
          ticketLink: data.ticket_link
        };
        setShows(prevShows => [...prevShows, newShow]);
        toast.success('Show added successfully');
      }
      setShowAddEdit(false);
      setEditingShow(null);
    } catch (error) {
      console.error('Error saving show:', error);
      toast.error('Failed to save show');
    }
  };

  const filterShows = () => {
    let filtered = shows;

    if (selectedType !== 'all') {
      filtered = filtered.filter(show => show.type === selectedType);
    }

    if (selectedVenue !== 'all') {
      filtered = filtered.filter(show => show.venueType === selectedVenue);
    }

    if (selectedStatus !== 'all') {
      filtered = filtered.filter(show => show.status === selectedStatus);
    }

    if (selectedDateRange !== 'all') {
      const today = new Date();
      switch (selectedDateRange) {
        case DATE_RANGES.TODAY:
          filtered = filtered.filter(show => show.date === today.toISOString().split('T')[0]);
          break;
        case DATE_RANGES.THIS_WEEK:
          const weekEnd = new Date(today);
          weekEnd.setDate(today.getDate() + 7);
          filtered = filtered.filter(show => {
            const showDate = new Date(show.date);
            return showDate >= today && showDate <= weekEnd;
          });
          break;
        case DATE_RANGES.THIS_MONTH:
          filtered = filtered.filter(show => {
            const showDate = new Date(show.date);
            return showDate.getMonth() === today.getMonth() && 
                   showDate.getFullYear() === today.getFullYear();
          });
          break;
        case DATE_RANGES.NEXT_MONTH:
          const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1);
          filtered = filtered.filter(show => {
            const showDate = new Date(show.date);
            return showDate.getMonth() === nextMonth.getMonth() && 
                   showDate.getFullYear() === nextMonth.getFullYear();
          });
          break;
      }
    }

    if (searchTerm) {
      filtered = filtered.filter(show =>
        show.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        show.artists.some(artist => 
          artist.toLowerCase().includes(searchTerm.toLowerCase())
        ) ||
        show.venue.toLowerCase().includes(searchTerm.toLowerCase()) ||
        show.location.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  };

  const filteredShows = filterShows();

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <div className="flex-none px-4 py-3 border-b border-border bg-card/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="flex items-center justify-between mb-3">
          <div className="flex flex-col">
            <h1 className="text-lg font-semibold tracking-tight">Shows</h1>
            <p className="text-sm text-muted-foreground">
              {filteredShows.length} shows • {shows.length} total
            </p>
          </div>
          {!isGuestMode && (
            <div className="flex items-center gap-2">
              <Button 
                variant="outline"
                size="icon"
                onClick={handleDownload}
                className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
              >
                <Download className="h-4 w-4" />
              </Button>
              <Button 
                onClick={() => {
                  setEditingShow(null);
                  setShowAddEdit(true);
                }}
                size="icon"
                className="rounded-full h-9 w-9 active:scale-95 transition-transform duration-200"
              >
                <Plus className="h-4 w-4" />
              </Button>
            </div>
          )}
        </div>

        <div className="flex items-center gap-2">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground pointer-events-none" />
            <Input
              placeholder="Search shows..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-9 pr-4 h-10 text-base"
              style={{ fontSize: '16px' }}
            />
            {searchTerm && (
              <Button
                variant="ghost"
                size="icon"
                className="absolute right-1 top-1/2 -translate-y-1/2 h-7 w-7 rounded-full"
                onClick={() => setSearchTerm('')}
              >
                <X className="h-4 w-4" />
              </Button>
            )}
          </div>
          <Button
            variant={showFilters ? "secondary" : "outline"}
            size="icon"
            onClick={() => setShowFilters(!showFilters)}
            className="rounded-full h-10 w-10 active:scale-95 transition-transform duration-200"
          >
            <Filter className="h-4 w-4" />
          </Button>
        </div>

        <AnimatePresence>
          {showFilters && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="grid grid-cols-2 gap-3 mt-3">
                <Select value={selectedType} onValueChange={setSelectedType}>
                  <SelectTrigger className="h-10 bg-muted/50">
                    <SelectValue placeholder="Show Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Types</SelectItem>
                    {Object.values(SHOW_TYPES).map(type => (
                      <SelectItem key={type} value={type}>{type}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select value={selectedVenue} onValueChange={setSelectedVenue}>
                  <SelectTrigger className="h-10 bg-muted/50">
                    <SelectValue placeholder="Venue Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Venues</SelectItem>
                    {Object.values(VENUE_TYPES).map(type => (
                      <SelectItem key={type} value={type}>{type}</SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select value={selectedStatus} onValueChange={setSelectedStatus}>
                  <SelectTrigger className="h-10 bg-muted/50">
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Status</SelectItem>
                    {Object.values(SHOW_STATUS).map(status => (
                      <SelectItem key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <Select value={selectedDateRange} onValueChange={setSelectedDateRange}>
                  <SelectTrigger className="h-10 bg-muted/50">
                    <SelectValue placeholder="Date Range" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Dates</SelectItem>
                    {Object.entries(DATE_RANGES).map(([key, value]) => (
                      <SelectItem key={value} value={value}>
                        {key.split('_').map(word => 
                          word.charAt(0) + word.slice(1).toLowerCase()
                        ).join(' ')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <ScrollArea className="flex-grow touch-manipulation">
        <div className="p-4 space-y-4">
          <AnimatePresence>
            {filteredShows.map((show) => (
              <ShowCard 
                key={show.id} 
                show={show}
                onEdit={handleEdit}
                isGuestMode={isGuestMode}
              />
            ))}
          </AnimatePresence>

          {filteredShows.length === 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Card className="bg-muted/50">
                <CardContent className="flex flex-col items-center justify-center py-8 text-center">
                  <Calendar className="h-12 w-12 text-muted-foreground mb-4" />
                  <h3 className="text-lg font-medium">No Shows Found</h3>
                  <p className="text-muted-foreground">Try adjusting your filters</p>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </div>
      </ScrollArea>

      <AddEditShowModal
        show={editingShow}
        isOpen={showAddEdit}
        onClose={() => {
          setShowAddEdit(false);
          setEditingShow(null);
        }}
        onSave={handleSave}
      />
    </div>
  );
};

export default MobileShows; 