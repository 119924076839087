import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Environment variables:', {
    REACT_APP_SUPABASE_URL: supabaseUrl,
    REACT_APP_SUPABASE_ANON_KEY: supabaseAnonKey
  })
  throw new Error('Missing Supabase credentials in environment variables')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

// Authentication helpers
export const signIn = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  })
  return { data, error }
}

const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  return { error }
}

const getCurrentUser = async () => {
  const { data: { user }, error } = await supabase.auth.getUser()
  return { user, error }
}

// Role checking
const isAdmin = async () => {
  const { user, error } = await getCurrentUser()
  if (error || !user) return false
  return user.app_metadata?.role === 'admin'
}

const checkAuth = async () => {
  const { user, error } = await getCurrentUser()
  if (error || !user) {
    throw new Error('Not authenticated')
  }
  return user
}

const checkAdminAuth = async () => {
  const user = await checkAuth()
  const admin = await isAdmin()
  if (!admin) {
    throw new Error('Not authorized - Admin access required')
  }
  return user
}

// Database operations with auth checks
const getCatalogs = async () => {
  await checkAuth()
  const { data, error } = await supabase
    .from('catalogs')
    .select('*')
    .order('updated_at', { ascending: false })

  if (error) {
    console.error('Error fetching catalogs:', error)
    return []
  }
  return data
}

const addCatalog = async (catalog) => {
  await checkAdminAuth()
  const { data, error } = await supabase
    .from('catalogs')
    .insert([catalog])
    .select()
    .single()

  if (error) {
    console.error('Error adding catalog:', error)
    return null
  }
  return data
}

const updateCatalog = async (updatedCatalog) => {
  await checkAdminAuth()
  const { error } = await supabase
    .from('catalogs')
    .update(updatedCatalog)
    .eq('id', updatedCatalog.id)

  if (error) {
    console.error('Error updating catalog:', error)
    return false
  }
  return true
}

const deleteCatalog = async (catalogId) => {
  await checkAdminAuth()
  const { error } = await supabase
    .from('catalogs')
    .delete()
    .eq('id', catalogId)

  if (error) {
    console.error('Error deleting catalog:', error)
    return false
  }
  return true
}

// Show operations with auth checks
const getShows = async () => {
  await checkAuth()
  const { data, error } = await supabase
    .from('shows')
    .select('*')
    .order('date', { ascending: true })

  if (error) {
    console.error('Error fetching shows:', error)
    return []
  }
  return data
}

const addShow = async (show) => {
  await checkAdminAuth()
  const { data, error } = await supabase
    .from('shows')
    .insert([show])
    .select()
    .single()

  if (error) {
    console.error('Error adding show:', error)
    return null
  }
  return data
}

const updateShow = async (updatedShow) => {
  await checkAdminAuth()
  const { error } = await supabase
    .from('shows')
    .update(updatedShow)
    .eq('id', updatedShow.id)

  if (error) {
    console.error('Error updating show:', error)
    return false
  }
  return true
}

const deleteShow = async (showId) => {
  await checkAdminAuth()
  const { error } = await supabase
    .from('shows')
    .delete()
    .eq('id', showId)

  if (error) {
    console.error('Error deleting show:', error)
    return false
  }
  return true
}

// Project operations with auth checks
const getProjects = async () => {
  try {
    console.log('Fetching projects...')
    const { data, error } = await supabase
      .from('projects')
      .select('*')
      .order('updated_at', { ascending: false })

    if (error) {
      console.error('Error fetching projects:', error)
      throw new Error(error.message)
    }

    // Convert start_date to startDate for frontend consistency
    const formattedData = data.map(project => ({
      ...project,
      startDate: project.start_date
    }))

    console.log('Projects data from Supabase:', formattedData)
    return formattedData || []
  } catch (err) {
    console.error('Error in getProjects:', err)
    throw err
  }
}

const addProject = async (project) => {
  try {
    // Format the project data
    const formattedProject = {
      title: project.title,
      artist: project.artist,
      status: project.status,
      deadline: project.deadline,
      start_date: project.start_date || project.startDate, // Handle both field names
      budget: project.budget ? parseFloat(project.budget) : null,
      genre: project.genre,
      producer: project.producer,
      notes: project.notes,
      tasks: Array.isArray(project.tasks) ? project.tasks : [],
      collaborators: Array.isArray(project.collaborators) ? project.collaborators : [],
      tracks: Array.isArray(project.tracks) ? project.tracks : []
    }

    console.log('Adding project to Supabase:', formattedProject)

    const { data, error } = await supabase
      .from('projects')
      .insert([formattedProject])
      .select()

    if (error) {
      console.error('Error adding project:', error)
      throw new Error(error.message)
    }

    // Convert start_date back to startDate for frontend consistency
    const formattedData = {
      ...data[0],
      startDate: data[0].start_date
    }

    console.log('Project added successfully:', formattedData)
    return formattedData
  } catch (err) {
    console.error('Error in addProject:', err)
    throw err
  }
}

const updateProject = async (updatedProject) => {
  try {
    // Format the project data
    const formattedProject = {
      title: updatedProject.title,
      artist: updatedProject.artist,
      status: updatedProject.status,
      deadline: updatedProject.deadline,
      start_date: updatedProject.start_date || updatedProject.startDate, // Handle both field names
      budget: updatedProject.budget ? parseFloat(updatedProject.budget) : null,
      genre: updatedProject.genre,
      producer: updatedProject.producer,
      notes: updatedProject.notes,
      tasks: Array.isArray(updatedProject.tasks) ? updatedProject.tasks : [],
      collaborators: Array.isArray(updatedProject.collaborators) ? updatedProject.collaborators : [],
      tracks: Array.isArray(updatedProject.tracks) ? updatedProject.tracks : []
    }

    console.log('Updating project in Supabase:', { id: updatedProject.id, project: formattedProject })

    const { data, error } = await supabase
      .from('projects')
      .update(formattedProject)
      .eq('id', updatedProject.id)
      .select()

    if (error) {
      console.error('Error updating project:', error)
      throw new Error(error.message)
    }

    // Convert start_date back to startDate for frontend consistency
    const formattedData = {
      ...data[0],
      startDate: data[0].start_date
    }

    console.log('Project updated successfully:', formattedData)
    return formattedData
  } catch (err) {
    console.error('Error in updateProject:', err)
    throw err
  }
}

const deleteProject = async (projectId) => {
  await checkAdminAuth()
  const { error } = await supabase
    .from('projects')
    .delete()
    .eq('id', projectId)

  if (error) {
    console.error('Error deleting project:', error)
    return false
  }
  return true
}

// Task operations with auth checks
const addTaskToProject = async (projectId, taskText) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('tasks')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return null
  }

  const newTask = {
    id: Date.now(),
    text: taskText,
    completed: false
  }

  const tasks = [...(project.tasks || []), newTask]

  const { error: updateError } = await supabase
    .from('projects')
    .update({ tasks })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating project tasks:', updateError)
    return null
  }

  return newTask
}

const toggleTaskCompletion = async (projectId, taskId) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('tasks')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return false
  }

  const tasks = project.tasks.map(task => 
    task.id === taskId ? { ...task, completed: !task.completed } : task
  )

  const { error: updateError } = await supabase
    .from('projects')
    .update({ tasks })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating task:', updateError)
    return false
  }

  return true
}

// Collaborator operations with auth checks
const addCollaborator = async (projectId, collaborator) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('collaborators')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return false
  }

  const collaborators = [...(project.collaborators || []), collaborator]

  const { error: updateError } = await supabase
    .from('projects')
    .update({ collaborators })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating collaborators:', updateError)
    return false
  }

  return true
}

const removeCollaborator = async (projectId, collaborator) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('collaborators')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return false
  }

  const collaborators = project.collaborators.filter(c => c !== collaborator)

  const { error: updateError } = await supabase
    .from('projects')
    .update({ collaborators })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating collaborators:', updateError)
    return false
  }

  return true
}

// Track operations with auth checks
const addTrackToProject = async (projectId, track) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('tracks')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return null
  }

  const newTrack = {
    ...track,
    id: Date.now()
  }

  const tracks = [...(project.tracks || []), newTrack]

  const { error: updateError } = await supabase
    .from('projects')
    .update({ tracks })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating tracks:', updateError)
    return null
  }

  return newTrack
}

const updateTrack = async (projectId, trackId, updatedTrack) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('tracks')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return false
  }

  const tracks = project.tracks.map(track =>
    track.id === trackId ? { ...track, ...updatedTrack } : track
  )

  const { error: updateError } = await supabase
    .from('projects')
    .update({ tracks })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating track:', updateError)
    return false
  }

  return true
}

const deleteTrack = async (projectId, trackId) => {
  await checkAdminAuth()
  const { data: project, error: fetchError } = await supabase
    .from('projects')
    .select('tracks')
    .eq('id', projectId)
    .single()

  if (fetchError) {
    console.error('Error fetching project:', fetchError)
    return false
  }

  const tracks = project.tracks.filter(track => track.id !== trackId)

  const { error: updateError } = await supabase
    .from('projects')
    .update({ tracks })
    .eq('id', projectId)

  if (updateError) {
    console.error('Error updating tracks:', updateError)
    return false
  }

  return true
}

export {
  signOut,
  getCurrentUser,
  isAdmin,
  checkAuth,
  checkAdminAuth,
  getCatalogs,
  addCatalog,
  updateCatalog,
  deleteCatalog,
  getShows,
  addShow,
  updateShow,
  deleteShow,
  getProjects,
  addProject,
  updateProject,
  deleteProject,
  addTaskToProject,
  toggleTaskCompletion,
  addCollaborator,
  removeCollaborator,
  addTrackToProject,
  updateTrack,
  deleteTrack
} 