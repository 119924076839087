import React, { useState } from 'react';
import { parse } from 'papaparse';
import { Card, CardHeader, CardContent } from "../ui/card.jsx"
import { Button } from "../ui/button.jsx"
import { Alert, AlertDescription, AlertTitle } from "../ui/alert.jsx"
import { Input } from "../ui/input.jsx"
import { Upload, FileUp, CheckCircle2 } from "lucide-react"
import { Badge } from "../ui/badge.jsx"
import { motion, AnimatePresence } from 'framer-motion';

const DataUpload = ({ onDataProcessed }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessed, setIsProcessed] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  const safeParseFloat = (value) => isNaN(value) ? 0 : parseFloat(value.replace(',', '.'));
  const safeParseInt = (value) => isNaN(value) ? 0 : parseInt(value, 10);

  const processData = (results) => {
    let minDate = new Date();
    let maxDate = new Date(0);

    const processedData = results.reduce((acc, fileData) => {
      const data = fileData.data;

      data.forEach(row => {
        const { 'Artist Name': artist, 'Net Revenue': revenueString, 'Quantity': streamsString, 'Platform': service, 'Sales Type': usage, 'Reporting month': reportingMonth, 'Track title': track, 'Release title': release } = row;
        const revenue = safeParseFloat(revenueString);
        const streams = safeParseInt(streamsString);
        const date = new Date(reportingMonth);

        // Update min and max dates
        minDate = date < minDate ? date : minDate;
        maxDate = date > maxDate ? date : maxDate;

        // Process artist data
        if (!acc.artistData[artist]) {
          acc.artistData[artist] = { name: artist, revenue: 0, streams: 0, trackCount: 0, tracks: {} };
        }
        acc.artistData[artist].revenue += revenue;
        acc.artistData[artist].streams += streams;

        // Track count and top track
        if (!acc.artistData[artist].tracks[track]) {
          acc.artistData[artist].trackCount++;
          acc.artistData[artist].tracks[track] = { revenue: 0, streams: 0 };
        }
        acc.artistData[artist].tracks[track].revenue += revenue;
        acc.artistData[artist].tracks[track].streams += streams;

        // Process track data
        const trackKey = `${artist}-${track}`;
        if (!acc.trackData[trackKey]) {
          acc.trackData[trackKey] = { name: track, artist, release, revenue: 0, streams: 0, date };
        }
        acc.trackData[trackKey].revenue += revenue;
        acc.trackData[trackKey].streams += streams;
        acc.trackData[trackKey].date = date > acc.trackData[trackKey].date ? date : acc.trackData[trackKey].date;

        // Process service data
        const serviceKey = `${artist}-${service}`;
        if (!acc.serviceData[serviceKey]) {
          acc.serviceData[serviceKey] = { name: service, artist, revenue: 0, quantity: 0, date };
        }
        acc.serviceData[serviceKey].revenue += revenue;
        acc.serviceData[serviceKey].quantity += streams;
        acc.serviceData[serviceKey].date = date > acc.serviceData[serviceKey].date ? date : acc.serviceData[serviceKey].date;

        // Process usage data
        const usageKey = `${artist}-${usage}`;
        if (!acc.usageData[usageKey]) {
          acc.usageData[usageKey] = { name: usage, artist, revenue: 0, quantity: 0, date };
        }
        acc.usageData[usageKey].revenue += revenue;
        acc.usageData[usageKey].quantity += streams;
        acc.usageData[usageKey].date = date > acc.usageData[usageKey].date ? date : acc.usageData[usageKey].date;
      });

      return acc;
    }, { artistData: {}, trackData: {}, serviceData: {}, usageData: {} });

    // Convert to arrays and sort
    const artistData = Object.values(processedData.artistData).map(artist => {
      const topTrack = Object.entries(artist.tracks).reduce((a, b) => a[1].revenue > b[1].revenue ? a : b)[0];
      return { ...artist, topTrack };
    }).sort((a, b) => b.revenue - a.revenue);
    const trackData = Object.values(processedData.trackData).sort((a, b) => b.revenue - a.revenue);
    const serviceData = Object.values(processedData.serviceData).sort((a, b) => b.revenue - a.revenue);
    const usageData = Object.values(processedData.usageData).sort((a, b) => b.revenue - a.revenue);

    // Calculate date range
    const getQuarter = (date) => Math.floor((date.getMonth() + 3) / 3);
    const formatDateRange = (start, end) => `Q${getQuarter(start)} ${start.getFullYear()} - Q${getQuarter(end)} ${end.getFullYear()}`;
    const newDateRange = formatDateRange(minDate, maxDate);
    setDateRange(newDateRange);

    onDataProcessed({ artistData, trackData, serviceData, usageData, dateRange: newDateRange });
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
    setError(null);
    setIsProcessed(false);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError('Please select at least one file to upload.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const parsePromises = files.map(file => 
        new Promise((resolve, reject) => {
          parse(file, {
            complete: (results) => resolve(results),
            header: true,
            error: (error) => reject(error)
          });
        })
      );

      const results = await Promise.all(parsePromises);
      processData(results);
      setIsProcessed(true);
    } catch (error) {
      setError('Error parsing files: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFiles([]);
    setIsProcessed(false);
    setError(null);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full"
    >
      <div className="flex flex-col items-center justify-center w-full gap-6">
        {!isProcessed ? (
          <>
            <div className="flex flex-col items-center justify-center w-full">
              <label 
                htmlFor="dropzone-file" 
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-card hover:bg-accent/5 border-border transition-colors"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <FileUp className="w-10 h-10 mb-3 text-muted-foreground" />
                  <p className="mb-2 text-sm text-muted-foreground">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-xs text-muted-foreground">CSV files (MAX. 10MB each)</p>
                </div>
                <Input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  accept=".csv"
                  multiple
                />
              </label>
            </div>

            <AnimatePresence>
              {files.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="w-full"
                >
                  <div className="flex items-center justify-between p-4 rounded-lg bg-card border">
                    <div className="flex items-center gap-3">
                      <Upload className="w-5 h-5 text-muted-foreground" />
                      <div className="text-sm">
                        <p className="font-medium text-card-foreground">
                          Selected files: {files.map(f => f.name).join(', ')}
                        </p>
                        <p className="text-muted-foreground">
                          Ready to process
                        </p>
                      </div>
                    </div>
                    <Button 
                      onClick={handleUpload} 
                      disabled={isLoading}
                      className="ml-4"
                    >
                      {isLoading ? 'Processing...' : 'Process Files'}
                    </Button>
                  </div>
                </motion.div>
              )}

              {error && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="w-full"
                >
                  <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                </motion.div>
              )}
            </AnimatePresence>
          </>
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center space-y-4"
          >
            <div className="flex flex-col items-center gap-2">
              <div className="h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center">
                <CheckCircle2 className="h-6 w-6 text-primary" />
              </div>
              <h3 className="text-lg font-semibold text-card-foreground">
                Data Processed Successfully
              </h3>
              <p className="text-sm text-muted-foreground">
                Your report data has been processed and is ready to view
              </p>
              {dateRange && (
                <Badge variant="outline" className="mt-2">
                  {dateRange}
                </Badge>
              )}
            </div>
            <Button onClick={handleReset} variant="outline" className="mt-4">
              Upload More Files
            </Button>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default DataUpload;