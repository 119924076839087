import React, { useState } from 'react';
import { Button } from "../ui/button.jsx";
import { Badge } from "../ui/badge.jsx";
import { 
  Search, Share2, Bell, Shield, Eye, 
  BarChart2, FileCode, ExternalLink 
} from 'lucide-react';
import { useToast } from "../ui/use-toast.js";

const CopyrightTrackingSection = ({ song, artist }) => {
  const { toast } = useToast();

  const trackingMethods = [
    {
      id: 'searchEngines',
      name: 'Search Engines',
      icon: Search,
      description: 'Monitor search engines for unauthorized use of content',
      actions: [
        {
          label: 'Google Search',
          handler: async () => {
            const searchQuery = `"${song.songDetails.title}" "${artist}" music`;
            window.open(`https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "Search Initiated",
              description: `Searching for "${song.songDetails.title}" on Google`
            });
          }
        },
        {
          label: 'YouTube Search',
          handler: async () => {
            const searchQuery = `${song.songDetails.title} ${artist}`;
            window.open(`https://www.youtube.com/results?search_query=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "YouTube Search",
              description: `Searching for "${song.songDetails.title}" on YouTube`
            });
          }
        }
      ]
    },
    {
      id: 'googleAlerts',
      name: 'Google Alerts',
      icon: Bell,
      description: 'Set up automated alerts for content appearances',
      actions: [
        {
          label: 'Set up Alert',
          handler: async () => {
            const alertQuery = `"${song.songDetails.title}" "${artist}"`;
            window.open(`https://www.google.com/alerts?q=${encodeURIComponent(alertQuery)}`, '_blank');
            toast({
              title: "Google Alert",
              description: `Setting up alert for "${song.songDetails.title}"`
            });
          }
        }
      ]
    },
    {
      id: 'socialMedia',
      name: 'Social Media',
      icon: Share2,
      description: 'Track content usage across social platforms',
      actions: [
        {
          label: 'TikTok',
          handler: async () => {
            const searchQuery = `${song.songDetails.title} ${artist}`;
            window.open(`https://www.tiktok.com/search?q=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "TikTok Search",
              description: "Searching for content using your song"
            });
          }
        },
        {
          label: 'YouTube Music',
          handler: async () => {
            const searchQuery = `${song.songDetails.title} ${artist}`;
            window.open(`https://music.youtube.com/search?q=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "YouTube Music Search",
              description: "Checking official music presence and covers"
            });
          }
        },
        {
          label: 'Facebook Search',
          handler: async () => {
            const searchQuery = `${song.songDetails.title} ${artist}`;
            window.open(`https://www.facebook.com/search/videos/?q=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "Facebook Video Search",
              description: "Searching for unauthorized video content using the song"
            });
          }
        },
        {
          label: 'SoundCloud',
          handler: async () => {
            const searchQuery = `${song.songDetails.title} ${artist}`;
            window.open(`https://soundcloud.com/search?q=${encodeURIComponent(searchQuery)}`, '_blank');
            toast({
              title: "SoundCloud Search",
              description: "Checking for unauthorized uploads and remixes"
            });
          }
        }
      ]
    },
    {
      id: 'macp',
      name: 'MACP Work Search',
      icon: Shield,
      description: "Search and monitor works registered with Music Authors' Copyright Protection (MACP)",
      actions: [
        {
          label: 'Search Work / Check Registration',
          handler: async () => {
            const params = new URLSearchParams({
              'reset': '0',
              'title': song.songDetails.title,
              'performer': artist,
              'publisher_writer': '' // Optional: can be filled if publisher info is available
            });
            window.open(`https://macp.com.my/search?${params.toString()}`, '_blank');
            toast({
              title: "MACP Search",
              description: `Searching for "${song.songDetails.title}" in MACP database`
            });
          }
        }
      ]
    }
  ];

  return (
    <div className="space-y-4 mt-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {trackingMethods.map((method) => (
          <div key={method.id} className="border rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <method.icon className="h-5 w-5 text-primary" />
                <h3 className="font-semibold">{method.name}</h3>
              </div>
            </div>
            <p className="text-sm text-muted-foreground mb-3">{method.description}</p>
            <div className="flex flex-wrap gap-2">
              {method.actions.map((action, idx) => (
                <Button
                  key={idx}
                  variant="outline"
                  size="sm"
                  onClick={action.handler}
                  className="flex items-center gap-1"
                >
                  {action.label}
                  <ExternalLink className="h-3 w-3" />
                </Button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CopyrightTrackingSection; 