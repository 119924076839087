import * as React from "react"
import { ChevronRightIcon } from "lucide-react"
import { cn } from "../../lib/utils.js"
 
const Breadcrumb = React.forwardRef(({ ...props }, ref) => (
  <nav
    ref={ref}
    aria-label="breadcrumb"
    {...props}
  />
))
Breadcrumb.displayName = "Breadcrumb"
 
const BreadcrumbList = React.forwardRef(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      "flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground",
      className
    )}
    {...props}
  />
))
BreadcrumbList.displayName = "BreadcrumbList"
 
const BreadcrumbItem = React.forwardRef(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn("inline-flex items-center gap-1.5", className)}
    {...props}
  />
))
BreadcrumbItem.displayName = "BreadcrumbItem"
 
const BreadcrumbLink = React.forwardRef(({ className, ...props }, ref) => (
  <button
    ref={ref}
    className={cn("hover:text-foreground transition-colors cursor-pointer", className)}
    {...props}
  />
))
BreadcrumbLink.displayName = "BreadcrumbLink"
 
const BreadcrumbSeparator = ({ className, ...props }) => (
  <ChevronRightIcon
    className={cn("h-4 w-4", className)}
    {...props}
  />
)
BreadcrumbSeparator.displayName = "BreadcrumbSeparator"
 
export { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } 