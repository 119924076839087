import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase.js';
import { normalizeDate } from '../utils/dateUtils.js';

export const useCatalog = () => {
  const [catalog, setCatalog] = useState({});
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchCatalog();
  }, []);

  const fetchCatalog = async () => {
    try {
      console.log('Fetching catalog data...');
      const { data, error } = await supabase
        .from('catalogs')
        .select('*');

      if (error) {
        console.error('Supabase error:', error);
        throw error;
      }

      console.log('Raw catalog data:', data);

      // Transform the flat data into the nested catalog structure
      const normalizedCatalog = {};
      data.forEach(entry => {
        const artistKey = Array.isArray(entry.artist) ? entry.artist[0] : entry.artist;
        if (!normalizedCatalog[artistKey]) {
          normalizedCatalog[artistKey] = [];
        }
        normalizedCatalog[artistKey].push({
          songDetails: {
            title: entry.title,
            releaseDate: normalizeDate(entry.release_date),
            duration: entry.duration,
            remark: entry.remark,
            type: entry.type,
            language: entry.language,
            isrc: entry.isrc,
            upc: entry.upc,
            link: entry.link
          },
          publishingDetails: {
            roles: entry.publishing_details?.roles || []
          },
          masterRecordingDetails: entry.master_recording_details || {
            version: '',
            isrc: '',
            upc: '',
            repertoireOwner: ''
          },
          id: entry.id
        });
      });

      console.log('Normalized catalog:', normalizedCatalog);
      setCatalog(normalizedCatalog);
    } catch (error) {
      console.error('Error fetching catalog:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveChanges = async () => {
    try {
      console.log('Saving changes...');
      // Flatten the catalog structure for Supabase
      const updates = Object.entries(catalog).flatMap(([artist, songs]) =>
        songs.map(song => {
          console.log('Processing song for save:', song);
          return {
            id: song.id,
            artist: [artist],
            title: song.songDetails.title,
            release_date: song.songDetails.releaseDate,
            duration: song.songDetails.duration,
            remark: song.songDetails.remark,
            type: song.songDetails.type,
            language: song.songDetails.language,
            isrc: song.songDetails.isrc,
            upc: song.songDetails.upc,
            link: song.songDetails.link,
            repertoire_owner: song.songDetails.repertoireOwner,
            publishing_details: {
              roles: song.publishingDetails?.roles || []
            },
            master_recording_details: {
              version: song.masterRecordingDetails?.version || '',
              isrc: song.masterRecordingDetails?.isrc || '',
              upc: song.masterRecordingDetails?.upc || '',
              repertoireOwner: song.masterRecordingDetails?.repertoireOwner || ''
            }
          };
        })
      );

      console.log('Updates to save:', updates);

      // Update all records in the catalog table
      const { error } = await supabase
        .from('catalogs')
        .upsert(updates, { onConflict: 'id' });

      if (error) {
        console.error('Error saving to Supabase:', error);
        throw error;
      }

      console.log('Changes saved successfully');
      await fetchCatalog(); // Refresh the data after saving
      setEditMode(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const handleAddTrack = async (artist, newTrack) => {
    try {
      console.log('Adding new track:', { artist, newTrack });
      // Prepare the new track data for Supabase
      const trackData = {
        artist: [artist],
        title: newTrack.songDetails.title,
        release_date: newTrack.songDetails.releaseDate,
        duration: newTrack.songDetails.duration,
        remark: newTrack.songDetails.remark,
        type: newTrack.songDetails.type,
        language: newTrack.songDetails.language,
        isrc: newTrack.songDetails.isrc,
        upc: newTrack.songDetails.upc,
        link: newTrack.songDetails.link,
        publishing_details: newTrack.publishingDetails,
        master_recording_details: newTrack.masterRecordingDetails
      };

      console.log('Track data to insert:', trackData);

      // Insert the new track into Supabase
      const { data, error } = await supabase
        .from('catalogs')
        .insert([trackData])
        .select()
        .single();

      if (error) {
        console.error('Error inserting to Supabase:', error);
        throw error;
      }

      console.log('Track added successfully:', data);

      // Update the local state with the new track
      setCatalog(prevCatalog => ({
        ...prevCatalog,
        [artist]: [...(prevCatalog[artist] || []), {
          songDetails: {
            title: data.title,
            releaseDate: normalizeDate(data.release_date),
            duration: data.duration,
            remark: data.remark,
            type: data.type,
            language: data.language,
            isrc: data.isrc,
            upc: data.upc,
            link: data.link
          },
          publishingDetails: data.publishing_details || { roles: [] },
          masterRecordingDetails: data.master_recording_details || {
            version: '',
            isrc: '',
            upc: '',
            repertoireOwner: ''
          },
          id: data.id
        }]
      }));
    } catch (error) {
      console.error('Error adding track:', error);
    }
  };

  const downloadArtistCatalog = (selectedArtist) => {
    const artistData = JSON.stringify(catalog[selectedArtist], null, 2);
    const blob = new Blob([artistData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedArtist}-catalog.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return { 
    catalog, 
    setCatalog,
    editMode, 
    setEditMode, 
    saveChanges, 
    downloadArtistCatalog, 
    handleAddTrack,
    loading
  };
};