import { 
  FileTextIcon, 
  FileImageIcon, 
  FileSpreadsheetIcon,
  MusicIcon,
  FileIcon,
  BarChartIcon,
  CalendarIcon
} from "lucide-react";

// Folder Categories
export const FOLDER_CATEGORIES = {
  LEGAL: 'legal',
  MARKETING: 'marketing',
  FINANCE: 'finance',
  MUSIC: 'music',
  REPORTS: 'reports',
  EVENTS: 'events'
};

// Folder Configuration
export const FOLDERS = [
  {
    id: 'kbb-agreements',
    name: 'Kalau Begitu, Baiklah Agreements',
    type: 'folder',
    category: FOLDER_CATEGORIES.LEGAL,
    description: 'Publishing Contracts and Agreements',
    folderId: "16sa8QBsxMUjNPdsZ-hpsJwDSpOiha2UF",
    icon: FileTextIcon,
    restricted: true
  },
  {
    id: 'skl-music',
    name: 'SKL',
    type: 'folder',
    category: FOLDER_CATEGORIES.MUSIC,
    description: 'Music Files and Audio Content',
    folderId: "1Jlegi9BZ01fn71FZS_MWEhlwoJOXmIGh",
    icon: MusicIcon,
    restricted: true
  },
  {
    id: 'kbb-music',
    name: 'Kalau Begitu, Baiklah Music',
    type: 'folder',
    category: FOLDER_CATEGORIES.MUSIC,
    description: 'Music Files and Audio Content',
    folderId: "1-i4IhoJ1RgLYy0apJH9l1Jpxx_ZtE4PF",
    icon: MusicIcon,
    restricted: true
  },
  {
    id: 'logos',
    name: 'Logos',
    type: 'folder',
    category: FOLDER_CATEGORIES.MARKETING,
    description: 'Logos and Branding Materials',
    folderId: "1w8GQbqLybydi-wsIYY1aLKW4vBCwt7E9",
    icon: FileImageIcon,
    restricted: true
  },
  // Add more folders here following the same structure
];

// Helper function to get folder icon by category
export const getFolderIcon = (category) => {
  switch (category) {
    case FOLDER_CATEGORIES.LEGAL:
      return FileTextIcon;
    case FOLDER_CATEGORIES.MARKETING:
      return FileImageIcon;
    case FOLDER_CATEGORIES.FINANCE:
      return FileSpreadsheetIcon;
    case FOLDER_CATEGORIES.MUSIC:
      return MusicIcon;
    case FOLDER_CATEGORIES.REPORTS:
      return BarChartIcon;
    case FOLDER_CATEGORIES.EVENTS:
      return CalendarIcon;
    default:
      return FileIcon;
  }
};

// Helper function to add a new folder
export const createFolder = ({
  name,
  category,
  description,
  folderId,
  restricted = true
}) => {
  const id = name.toLowerCase().replace(/\s+/g, '-');
  const Icon = getFolderIcon(category);

  return {
    id,
    name,
    type: 'folder',
    category,
    description,
    folderId,
    icon: Icon,
    restricted
  };
};

// Example usage:
/*
const newFolder = createFolder({
  name: 'Marketing Assets 2024',
  category: FOLDER_CATEGORIES.MARKETING,
  description: 'Marketing materials and assets for 2024 campaigns',
  folderId: 'your_google_drive_folder_id',
  restricted: true
});

FOLDERS.push(newFolder);
*/ 