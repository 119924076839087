import React, { useState, useEffect, useRef } from 'react';
import { Button } from "../ui/button.jsx";
import { Card, CardContent } from "../ui/card.jsx";
import { Input } from "../ui/input.jsx";
import { Label } from "../ui/label.jsx";
import { ScrollArea } from "../ui/scroll-area.jsx";
import { motion, AnimatePresence } from "framer-motion";
import ProjectForm from './ProjectForm.js';
import TrackForm from './TrackForm.js';
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table.jsx";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog.jsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select.jsx";
import { Badge } from "../ui/badge.jsx";
import { 
  PlusIcon, 
  Trash2Icon, 
  EditIcon, 
  SearchIcon,
  CalendarIcon,
  ClockIcon,
  UserIcon,
  FileTextIcon,
  TagIcon,
  UserPlusIcon,
  XIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  AlertCircleIcon,
  TimerIcon,
  BanIcon,
  MusicIcon,
  HashIcon,
  MicIcon,
  Activity as ActivityIcon,
  ChevronRightIcon,
  SettingsIcon,
  FolderIcon,
  BarChart2Icon,
  PlayIcon,
  PauseIcon,
  LinkIcon
} from "lucide-react";
import {
  PROJECT_STATUSES,
  TRACK_STATUSES,
  INITIAL_PROJECT
} from '../../data/projectConstants.js';
import {
  getProjects,
  addProject,
  updateProject,
  deleteProject,
  addTaskToProject,
  toggleTaskCompletion,
  addCollaborator,
  removeCollaborator,
  addTrackToProject,
  updateTrack,
  deleteTrack
} from '../../lib/supabase.js';
import ProjectDetailsCollapsible from './ProjectDetailsCollapsible.js';
import { Separator } from "../ui/separator.jsx";

// Update the helper function to handle Google Drive preview links
const getGoogleDrivePreviewLink = (driveLink) => {
  // Handle different Google Drive link formats
  const fileId = driveLink.match(/[-\w]{25,}/);
  if (!fileId) return null;
  // Use the preview URL instead of direct download
  return `https://drive.google.com/file/d/${fileId[0]}/preview`;
};

const AudioPlayer = ({ demoLink }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [error, setError] = useState(null);
  const iframeRef = useRef(null);

  if (!demoLink) return null;

  const previewLink = getGoogleDrivePreviewLink(demoLink);

  if (!previewLink) {
    return (
      <div className="text-xs text-destructive mt-2">
        Invalid Google Drive link format
      </div>
    );
  }

  return (
    <div className="space-y-2 mt-2">
      <div className="flex items-center gap-2">
        <a
          href={demoLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs text-primary hover:text-primary/80 flex items-center gap-1"
        >
          <LinkIcon className="h-3 w-3" />
          Play in Drive
        </a>
        <iframe
          ref={iframeRef}
          src={previewLink}
          width="100%"
          height="50"
          allow="autoplay"
          className="border-0 hidden"
        />
      </div>
      {error && (
        <div className="text-xs text-destructive">
          {error}
        </div>
      )}
    </div>
  );
};

// Add CompactAudioPlayer component for the overview
const CompactAudioPlayer = ({ demoLink }) => {
  if (!demoLink) return null;

  const previewLink = getGoogleDrivePreviewLink(demoLink);
  if (!previewLink) return null;

  return (
    <a
      href={demoLink}
      target="_blank"
      rel="noopener noreferrer"
      className="text-orange-500 hover:text-orange-600 transition-colors"
      title="Open demo track"
    >
      <PlayIcon className="h-3 w-3" />
    </a>
  );
};

const ProjectManagementTab = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState(INITIAL_PROJECT);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('ALL');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [newTask, setNewTask] = useState('');
  const [newCollaborator, setNewCollaborator] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [newTrack, setNewTrack] = useState({
    title: '',
    status: 'WRITING',
    duration: '',
    bpm: '',
    key: '',
    features: [],
    notes: '',
    recordingLocation: '',
    demoLink: ''
  });
  const [isAddingTrack, setIsAddingTrack] = useState(false);
  const [editingTrack, setEditingTrack] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openProjectId, setOpenProjectId] = useState(null);
  const [selectedProjectSummary, setSelectedProjectSummary] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await getProjects();
      console.log('Fetched projects:', data); // Debug log
      setProjects(data);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching projects:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddProject = async (project, isEdit) => {
    if (!project.title || !project.artist || !project.status) {
      setError('Please fill in all required fields (Title, Artist, and Status)');
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      // Format dates and ensure all required fields are present
      const formattedProject = {
        ...project,
        deadline: project.deadline || null,
        startDate: project.startDate || null,
        budget: project.budget ? parseFloat(project.budget) : null,
        tasks: Array.isArray(project.tasks) ? project.tasks : [],
        collaborators: Array.isArray(project.collaborators) ? project.collaborators : [],
        tracks: Array.isArray(project.tracks) ? project.tracks : []
      };

      console.log('Submitting project:', formattedProject);

      if (isEdit) {
        const updatedProject = await updateProject(formattedProject);
        if (updatedProject) {
          await fetchProjects();
        }
      } else {
        const newProject = await addProject(formattedProject);
        if (newProject) {
          await fetchProjects();
        }
      }
      setShowAddForm(false);
      setIsEditMode(false);
      setNewProject(INITIAL_PROJECT);
      setSelectedProject(null);
    } catch (err) {
      setError(err.message);
      console.error('Error adding/updating project:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditProject = (project) => {
    setNewProject({
      ...INITIAL_PROJECT,
      ...project,
    });
    setIsEditMode(true);
    setSelectedProject(project);
    setShowAddForm(true);
    setError(null);
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await deleteProject(projectId);
        if (success) {
          await fetchProjects();
          if (openProjectId === projectId) {
            setOpenProjectId(null);
            setSelectedProjectSummary(null);
          }
        }
      } catch (err) {
        setError(err.message);
        console.error('Error deleting project:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAddTask = async (projectId) => {
    if (newTask.trim()) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await addTaskToProject(projectId, newTask);
        if (success) {
          await fetchProjects();
          setNewTask('');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error adding task:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleToggleTask = async (projectId, taskId) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await toggleTaskCompletion(projectId, taskId);
      if (success) {
        await fetchProjects();
      }
    } catch (err) {
      setError(err.message);
      console.error('Error toggling task:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCollaborator = async (projectId) => {
    if (newCollaborator.trim()) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await addCollaborator(projectId, newCollaborator);
        if (success) {
          await fetchProjects();
          setNewCollaborator('');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error adding collaborator:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleRemoveCollaborator = async (projectId, collaborator) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await removeCollaborator(projectId, collaborator);
      if (success) {
        await fetchProjects();
      }
    } catch (err) {
      setError(err.message);
      console.error('Error removing collaborator:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddTrack = async (projectId) => {
    if (newTrack.title) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await addTrackToProject(projectId, newTrack);
        if (success) {
          await fetchProjects();
          handleTrackFormCancel();
        }
      } catch (err) {
        setError(err.message);
        console.error('Error adding track:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUpdateTrack = async (projectId, trackId, updatedTrack) => {
    setIsLoading(true);
    setError(null);
    try {
      const success = await updateTrack(projectId, trackId, updatedTrack);
      if (success) {
        await fetchProjects();
        handleTrackFormCancel();
      }
    } catch (err) {
      setError(err.message);
      console.error('Error updating track:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTrack = async (projectId, trackId) => {
    if (window.confirm('Are you sure you want to delete this track?')) {
      setIsLoading(true);
      setError(null);
      try {
        const success = await deleteTrack(projectId, trackId);
        if (success) {
          await fetchProjects();
        }
      } catch (err) {
        setError(err.message);
        console.error('Error deleting track:', err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTrackFormCancel = () => {
    setIsAddingTrack(false);
    setEditingTrack(null);
    setSelectedProject(null);
    setNewTrack({
      title: '',
      status: 'WRITING',
      duration: '',
      bpm: '',
      key: '',
      features: [],
      notes: '',
      recordingLocation: '',
      demoLink: ''
    });
  };

  const filteredProjects = projects
    .filter(project => {
      const matchesSearch = 
        project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.artist.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.producer?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = statusFilter === 'ALL' || project.status === statusFilter;
      
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => new Date(b.deadline) - new Date(a.deadline));

  const getStatusBadge = (status) => {
    const statusInfo = PROJECT_STATUSES[status];
    return (
      <div className="flex items-center gap-2">
        <Badge 
          className={`${statusInfo.lightColor} ${statusInfo.textColor} border ${statusInfo.borderColor} hover:${statusInfo.color} hover:text-white transition-colors`}
        >
          <div className="flex items-center gap-1.5">
            {statusIcons[status]}
            {statusInfo.label}
          </div>
        </Badge>
      </div>
    );
  };

  const getStatusSelectItem = (key, { label, lightColor, textColor }) => (
    <SelectItem key={key} value={key}>
      <div className="flex items-center gap-2">
        <div className={`w-2 h-2 rounded-full ${PROJECT_STATUSES[key].color}`} />
        <span className={`${textColor}`}>{label}</span>
      </div>
    </SelectItem>
  );

  const calculateProgress = (project) => {
    let totalWeight = 0;
    let completedWeight = 0;

    // Calculate tasks progress (40% weight)
    if (project.tasks && project.tasks.length > 0) {
      const completedTasks = project.tasks.filter(task => task.completed).length;
      totalWeight += 40;
      completedWeight += (completedTasks / project.tasks.length) * 40;
    }

    // Calculate tracks progress (60% weight)
    if (project.tracks && project.tracks.length > 0) {
      totalWeight += 60;
      project.tracks.forEach(track => {
        // Calculate progress based on track status
        let trackProgress = 0;
        switch (track.status) {
          case 'WRITING':
            trackProgress = 0.2; // 20%
            break;
          case 'RECORDING':
            trackProgress = 0.4; // 40%
            break;
          case 'MIXING':
            trackProgress = 0.6; // 60%
            break;
          case 'MASTERING':
            trackProgress = 0.8; // 80%
            break;
          case 'COMPLETE':
            trackProgress = 1; // 100%
            break;
          default:
            trackProgress = 0;
        }
        completedWeight += (trackProgress * 60) / project.tracks.length;
      });
    }

    // If no tasks or tracks, return 0
    if (totalWeight === 0) return 0;

    // Calculate total progress percentage
    return Math.round((completedWeight / totalWeight) * 100);
  };

  const getProgressBarColor = (status, progress) => {
    if (progress === 100) return PROJECT_STATUSES.COMPLETE.color;
    if (progress >= 80) return 'bg-emerald-500';
    if (progress >= 60) return 'bg-blue-500';
    if (progress >= 40) return 'bg-amber-500';
    if (progress >= 20) return 'bg-orange-500';
    return 'bg-red-500';
  };

  // Status icon mapping
  const statusIcons = {
    PLANNING: <TimerIcon className="h-4 w-4" />,
    IN_PROGRESS: <AlertCircleIcon className="h-4 w-4" />,
    REVIEW: <ChevronDownIcon className="h-4 w-4" />,
    COMPLETE: <CheckCircleIcon className="h-4 w-4" />,
    ON_HOLD: <BanIcon className="h-4 w-4" />
  };

  const getTrackStatusBadge = (status) => {
    const statusInfo = TRACK_STATUSES[status];
    return (
      <Badge 
        className={`${statusInfo.lightColor} ${statusInfo.textColor} border ${statusInfo.borderColor} hover:${statusInfo.color} hover:text-white transition-colors`}
      >
        <div className="flex items-center gap-1.5">
          {status === 'WRITING' && <EditIcon className="h-3 w-3" />}
          {status === 'RECORDING' && <MicIcon className="h-3 w-3" />}
          {status === 'MIXING' && <ActivityIcon className="h-3 w-3" />}
          {status === 'MASTERING' && <ActivityIcon className="h-3 w-3" />}
          {status === 'COMPLETE' && <CheckCircleIcon className="h-3 w-3" />}
          {statusInfo.label}
        </div>
      </Badge>
    );
  };

  const handleProjectFormCancel = () => {
    setIsEditMode(false);
    setNewProject(INITIAL_PROJECT);
    setSelectedProject(null);
    setShowAddForm(false);
  };

  const renderTrackSection = (project) => (
    <div className="col-span-2 mt-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-semibold">Tracks</h3>
        <Button
          size="sm"
          onClick={() => handleAddTrackClick(project)}
          className="h-8"
        >
          <PlusIcon className="h-4 w-4 mr-1" />
          Add Track
        </Button>
      </div>

      <div className="space-y-2">
        {project.tracks?.map(track => (
          <Card
            key={track.id}
            className={`p-4 ${TRACK_STATUSES[track.status].lightColor} bg-opacity-30`}
          >
            <div className="flex items-start justify-between">
              <div className="space-y-1">
                <div className="flex items-center gap-2">
                  <MusicIcon className="h-4 w-4 text-muted-foreground" />
                  <span className="font-medium">{track.title}</span>
                </div>
                <div className="flex flex-wrap items-center gap-3 text-sm text-muted-foreground">
                  {getTrackStatusBadge(track.status)}
                  {track.duration && (
                    <div className="flex items-center gap-1">
                      <TimerIcon className="h-3 w-3" />
                      {track.duration}
                    </div>
                  )}
                  {track.bpm && (
                    <div className="flex items-center gap-1">
                      <HashIcon className="h-3 w-3" />
                      {track.bpm} BPM
                    </div>
                  )}
                  {track.key && (
                    <div className="flex items-center gap-1">
                      <MusicIcon className="h-3 w-3" />
                      Key: {track.key}
                    </div>
                  )}
                </div>
                {track.notes && (
                  <p className="text-sm text-muted-foreground mt-2">
                    {track.notes}
                  </p>
                )}
                {track.recordingLocation && (
                  <div className="flex items-center gap-1 text-sm text-muted-foreground mt-1">
                    <MicIcon className="h-3 w-3" />
                    {track.recordingLocation}
                  </div>
                )}
                {track.demoLink && <AudioPlayer demoLink={track.demoLink} />}
              </div>
              <div className="flex gap-1">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleEditTrackClick(project, track)}
                  className="h-8 w-8 p-0"
                >
                  <EditIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleDeleteTrack(project.id, track.id)}
                  className="h-8 w-8 p-0 hover:text-destructive hover:bg-destructive/10"
                >
                  <Trash2Icon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );

  const ProjectSummary = ({ project }) => {
    // Get unique collaborators from all tracks
    const allCollaborators = project.tracks?.reduce((acc, track) => {
      if (track.features) {
        return [...acc, ...track.features];
      }
      return acc;
    }, []);
    
    const uniqueCollaborators = [...new Set(allCollaborators || [])];

    return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="p-6 bg-muted/30 rounded-lg"
    >
      {/* Project Header */}
      <div className="flex items-start justify-between mb-6">
        <div className="space-y-1">
          <h3 className="text-lg font-semibold flex items-center gap-2">
            <MusicIcon className="h-5 w-5 text-primary" />
            Project Overview
          </h3>
          <p className="text-sm text-muted-foreground">
            {project.genre} • {project.tracks?.length || 0} Tracks • {uniqueCollaborators.length} Collaborators
          </p>
        </div>
        <div className="flex items-center gap-4 text-sm text-muted-foreground">
          <div className="flex items-center gap-1">
            <CalendarIcon className="h-4 w-4" />
            <span>Due {project.deadline}</span>
          </div>
          <Badge variant="outline" className="font-normal">
            RM {project.budget}
          </Badge>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6">
        {/* Project Info */}
        <div className="space-y-4">
          <div>
            <h4 className="text-sm font-medium text-primary mb-3">Project Details</h4>
            <div className="space-y-2 bg-background/40 p-3 rounded-lg">
              <div className="flex items-center gap-2 text-sm">
                <UserIcon className="h-4 w-4 text-muted-foreground" />
                <span className="font-medium">Artist:</span> 
                <span className="text-muted-foreground">{project.artist}</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <TagIcon className="h-4 w-4 text-muted-foreground" />
                <span className="font-medium">Genre:</span> 
                <span className="text-muted-foreground">{project.genre}</span>
              </div>
              <div className="flex items-center gap-2 text-sm">
                <UserIcon className="h-4 w-4 text-muted-foreground" />
                <span className="font-medium">Producer:</span> 
                <span className="text-muted-foreground">{project.producer}</span>
              </div>
            </div>
          </div>

          {uniqueCollaborators.length > 0 && (
            <div>
              <h4 className="text-sm font-medium text-primary mb-3">Project Collaborators</h4>
              <div className="flex flex-wrap gap-1 bg-background/40 p-3 rounded-lg">
                {uniqueCollaborators.map((collaborator, index) => (
                  <Badge 
                    key={index} 
                    variant="secondary" 
                    className="text-xs bg-background/50"
                  >
                    {collaborator}
                  </Badge>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Tracks List */}
        <div className="col-span-2">
          <h4 className="text-sm font-medium text-primary mb-3">Tracks</h4>
          <div className="space-y-2">
            {project.tracks?.map((track, index) => (
              <div 
                key={index} 
                className="p-3 bg-background/40 rounded-lg hover:bg-background/60 transition-colors group"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-2">
                      <MusicIcon className="h-4 w-4 text-muted-foreground" />
                      <span className="font-medium">{track.title}</span>
                      {track.demoLink && (
                        <CompactAudioPlayer demoLink={track.demoLink} />
                      )}
                    </div>
                    {track.features && track.features.length > 0 && (
                      <div className="flex items-center gap-1 text-muted-foreground">
                        <span>with</span>
                        <div className="flex -space-x-1">
                          {track.features.map((feature, idx) => (
                            <Badge 
                              key={idx} 
                              variant="secondary" 
                              className="text-xs px-2 py-0.5 bg-background/50"
                            >
                              {feature}
                            </Badge>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex items-center gap-3">
                    {track.duration && (
                      <span className="text-xs text-muted-foreground">
                        {track.duration}
                      </span>
                    )}
                    <Badge 
                      variant="outline" 
                      className={`text-xs ${TRACK_STATUSES[track.status].textColor} ${TRACK_STATUSES[track.status].borderColor}`}
                    >
                      {TRACK_STATUSES[track.status].label}
                    </Badge>
                  </div>
                </div>
                {(track.bpm || track.key) && (
                  <div className="flex items-center gap-4 mt-2 ml-6 text-xs text-muted-foreground">
                    {track.bpm && (
                      <div className="flex items-center gap-1">
                        <HashIcon className="h-3 w-3" />
                        {track.bpm} BPM
                      </div>
                    )}
                    {track.key && (
                      <div className="flex items-center gap-1">
                        <MusicIcon className="h-3 w-3" />
                        Key: {track.key}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            {!project.tracks?.length && (
              <div className="text-sm text-muted-foreground bg-background/40 p-3 rounded-lg">
                No tracks added yet
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  )};

  const handleRemoveTask = (projectId, taskId) => {
    setProjects(currentProjects =>
      currentProjects.map(project => {
        if (project.id === projectId) {
          return {
            ...project,
            tasks: project.tasks.filter(task => task.id !== taskId)
          };
        }
        return project;
      })
    );
  };

  const handleAddTrackClick = (project) => {
    setSelectedProject(project);
    setIsAddingTrack(true);
  };

  const handleEditTrackClick = (project, track) => {
    setSelectedProject(project);
    setEditingTrack(track);
  };

  const handleStatusClick = (e, project) => {
    e.stopPropagation();
    setOpenProjectId(openProjectId === project.id ? null : project.id);
    setSelectedProjectSummary(null);
  };

  const handleProjectClick = (e, project) => {
    e.stopPropagation();
    setSelectedProjectSummary(selectedProjectSummary === project.id ? null : project.id);
    setOpenProjectId(null);
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold">Project Management</h2>
          <p className="text-muted-foreground">Track and manage your music projects</p>
        </div>
        <Button 
          onClick={() => {
            setShowAddForm(!showAddForm);
            if (!showAddForm) {
              setNewProject(INITIAL_PROJECT);
              setIsEditMode(false);
              setError(null);
            }
          }}
          className="gap-2"
          disabled={isLoading}
        >
          {showAddForm ? (
            <>
              <XIcon className="h-4 w-4" />
              Cancel
            </>
          ) : (
            <>
              <PlusIcon className="h-4 w-4" />
              Add Project
            </>
          )}
        </Button>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex items-center justify-center p-8">
          <ActivityIcon className="h-6 w-6 animate-spin" />
          <span className="ml-2">Loading...</span>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="bg-destructive/10 text-destructive p-4 rounded-lg flex items-center gap-2">
          <AlertCircleIcon className="h-5 w-5" />
          {error}
        </div>
      )}

      {/* No Projects Message */}
      {!isLoading && projects.length === 0 && (
        <div className="text-center p-8 text-muted-foreground">
          No projects found. Click "Add Project" to create one.
        </div>
      )}

      {/* Search and Filter Section */}
      <Card>
        <CardContent className="p-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search projects..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-9"
                />
              </div>
            </div>
            <div className="w-full md:w-[200px]">
              <Select
                value={statusFilter}
                onValueChange={setStatusFilter}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Filter by status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="ALL">All Projects</SelectItem>
                  {Object.entries(PROJECT_STATUSES).map(([key, status]) => 
                    getStatusSelectItem(key, status)
                  )}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Add/Edit Project Form */}
      <AnimatePresence>
        {(showAddForm || isEditMode) && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <Card>
              <CardContent className="p-6">
                <h2 className="text-2xl font-bold mb-6 flex items-center gap-2">
                  {isEditMode ? (
                    <>
                      <EditIcon className="h-5 w-5" />
                      Edit Project
                    </>
                  ) : (
                    <>
                      <PlusIcon className="h-5 w-5" />
                      Add New Project
                    </>
                  )}
                </h2>
                <ProjectForm
                  isEditMode={isEditMode}
                  newProject={newProject}
                  setNewProject={setNewProject}
                  handleAddProject={handleAddProject}
                  onCancel={handleProjectFormCancel}
                  isLoading={isLoading}
                />
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Projects List */}
      <Card>
        <CardContent className="p-0">
          <div className="p-6">
            <h2 className="text-2xl font-bold">
              Projects ({filteredProjects.length})
            </h2>
          </div>
          <div className="border-t">
            <Table>
              <TableHeader>
                <TableRow className="bg-muted/50 backdrop-blur-sm hover:bg-muted/50">
                  <TableHead className="font-semibold w-[300px]">
                    <div className="flex items-center gap-2">
                      <FileTextIcon className="h-4 w-4 text-muted-foreground" />
                      Project Title
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">
                    <div className="flex items-center gap-2">
                      <UserIcon className="h-4 w-4 text-muted-foreground" />
                      Artist
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">
                    <div className="flex items-center gap-2">
                      <ActivityIcon className="h-4 w-4 text-muted-foreground" />
                      Status
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">
                    <div className="flex items-center gap-2">
                      <CalendarIcon className="h-4 w-4 text-muted-foreground" />
                      Timeline
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">
                    <div className="flex items-center gap-2">
                      <BarChart2Icon className="h-4 w-4 text-muted-foreground" />
                      Progress
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">
                    <div className="flex items-center gap-2">
                      <UserIcon className="h-4 w-4 text-muted-foreground" />
                      Producer
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold w-[100px] text-right">
                    <div className="flex items-center gap-2 justify-end">
                      <SettingsIcon className="h-4 w-4 text-muted-foreground" />
                      Actions
                    </div>
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} className="h-24 text-center">
                      <div className="flex items-center justify-center">
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                          className="mr-2"
                        >
                          <ActivityIcon className="h-5 w-5" />
                        </motion.div>
                        Loading projects...
                      </div>
                    </TableCell>
                  </TableRow>
                ) : filteredProjects.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} className="h-24 text-center">
                      No projects found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredProjects.map((project, index) => (
                    <React.Fragment key={project.id}>
                      <motion.tr
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ delay: index * 0.05 }}
                        className={`
                          ${openProjectId === project.id ? 'bg-muted/30' : 'hover:bg-muted/50'} 
                          transition-all duration-200 
                          ${PROJECT_STATUSES[project.status].lightColor} 
                          bg-opacity-30 group cursor-pointer
                        `}
                      >
                        <TableCell className="py-4">
                          <div className="flex items-center gap-2">
                            <div 
                              onClick={(e) => handleProjectClick(e, project)}
                              className="cursor-pointer"
                            >
                              {selectedProjectSummary === project.id ? (
                                <ChevronDownIcon className="h-4 w-4 text-primary transition-transform" />
                              ) : (
                                <ChevronRightIcon className="h-4 w-4 text-muted-foreground transition-transform group-hover:text-primary" />
                              )}
                            </div>
                            <span className="font-medium group-hover:text-primary transition-colors">
                              {project.title}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <UserIcon className="h-4 w-4 text-muted-foreground" />
                            {project.artist}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div onClick={(e) => handleStatusClick(e, project)}>
                            {getStatusBadge(project.status)}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-2 text-sm">
                              <CalendarIcon className="h-4 w-4 text-muted-foreground" />
                              {project.startDate}
                            </div>
                            <div className="flex items-center gap-2 text-sm">
                              <ClockIcon className="h-4 w-4 text-muted-foreground" />
                              {project.deadline}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="space-y-2">
                            <div className="w-full bg-muted rounded-full h-2 overflow-hidden">
                              <div
                                className={`${getProgressBarColor(project.status, calculateProgress(project))} h-2 rounded-full transition-all duration-500 ease-in-out`}
                                style={{ width: `${calculateProgress(project)}%` }}
                              ></div>
                            </div>
                            <div className="flex items-center justify-between text-xs">
                              <span className={`${PROJECT_STATUSES[project.status].textColor} font-medium`}>
                                {calculateProgress(project)}%
                              </span>
                              {project.tracks?.length > 0 && (
                                <span className="text-muted-foreground">
                                  {project.tracks.filter(t => t.status === 'COMPLETE').length}/{project.tracks.length} Tracks
                                </span>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex items-center gap-2">
                            <UserIcon className="h-4 w-4 text-muted-foreground" />
                            <span className="truncate max-w-[120px]">
                              {project.producer || 'Not assigned'}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="flex justify-end space-x-2">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditProject(project);
                              }}
                              className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100 transition-all duration-200 hover:scale-110 hover:bg-primary/10 hover:text-primary"
                              disabled={isLoading}
                            >
                              <EditIcon className="h-4 w-4" />
                            </Button>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteProject(project.id);
                              }}
                              className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100 transition-all duration-200 hover:scale-110 hover:text-destructive hover:bg-destructive/10"
                              disabled={isLoading}
                            >
                              <Trash2Icon className="h-4 w-4" />
                            </Button>
                          </div>
                        </TableCell>
                      </motion.tr>
                      {selectedProjectSummary === project.id && (
                        <TableRow>
                          <TableCell colSpan={7} className="p-0 border-0">
                            <ProjectSummary project={project} />
                          </TableCell>
                        </TableRow>
                      )}
                      {openProjectId === project.id && (
                        <TableRow>
                          <TableCell colSpan={7} className="p-0 border-0">
                            <motion.div
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              transition={{ duration: 0.2 }}
                            >
                              <ProjectDetailsCollapsible
                                project={project}
                                isOpen={true}
                                onToggle={() => setOpenProjectId(null)}
                                getStatusBadge={getStatusBadge}
                                newCollaborator={newCollaborator}
                                setNewCollaborator={setNewCollaborator}
                                handleAddCollaborator={() => handleAddCollaborator(project.id)}
                                handleRemoveCollaborator={(collaborator) => handleRemoveCollaborator(project.id, collaborator)}
                                newTask={newTask}
                                setNewTask={setNewTask}
                                handleAddTask={() => handleAddTask(project.id)}
                                handleToggleTask={(taskId) => handleToggleTask(project.id, taskId)}
                                handleRemoveTask={(taskId) => handleRemoveTask(project.id, taskId)}
                                renderTrackSection={() => renderTrackSection(project)}
                              />
                            </motion.div>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>

      {/* Track Form Dialog */}
      <Dialog open={isAddingTrack || editingTrack} onOpenChange={() => handleTrackFormCancel()}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              {editingTrack ? (
                <>
                  <EditIcon className="h-5 w-5" />
                  Edit Track
                </>
              ) : (
                <>
                  <PlusIcon className="h-5 w-5" />
                  Add New Track
                </>
              )}
            </DialogTitle>
          </DialogHeader>
          <TrackForm
            isEditing={!!editingTrack}
            track={editingTrack || newTrack}
            onTrackChange={editingTrack ? setEditingTrack : setNewTrack}
            onSubmit={editingTrack 
              ? (track) => handleUpdateTrack(selectedProject.id, track.id, track)
              : () => handleAddTrack(selectedProject.id)
            }
            onCancel={handleTrackFormCancel}
            isLoading={isLoading}
          />
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};

export default ProjectManagementTab; 