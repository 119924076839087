export const PROJECT_STATUSES = {
  PLANNING: { 
    label: 'Planning', 
    color: 'bg-amber-500',
    lightColor: 'bg-amber-100',
    textColor: 'text-amber-700',
    borderColor: 'border-amber-200'
  },
  IN_PROGRESS: { 
    label: 'In Progress', 
    color: 'bg-blue-500',
    lightColor: 'bg-blue-100',
    textColor: 'text-blue-700',
    borderColor: 'border-blue-200'
  },
  REVIEW: { 
    label: 'Review', 
    color: 'bg-purple-500',
    lightColor: 'bg-purple-100',
    textColor: 'text-purple-700',
    borderColor: 'border-purple-200'
  },
  COMPLETE: { 
    label: 'Complete', 
    color: 'bg-green-500',
    lightColor: 'bg-green-100',
    textColor: 'text-green-700',
    borderColor: 'border-green-200'
  },
  ON_HOLD: { 
    label: 'On Hold', 
    color: 'bg-gray-500',
    lightColor: 'bg-gray-100',
    textColor: 'text-gray-700',
    borderColor: 'border-gray-200'
  },
};

export const TRACK_STATUSES = {
  WRITING: {
    label: 'Writing',
    color: 'bg-pink-500',
    lightColor: 'bg-pink-100',
    textColor: 'text-pink-700',
    borderColor: 'border-pink-200'
  },
  RECORDING: {
    label: 'Recording',
    color: 'bg-orange-500',
    lightColor: 'bg-orange-100',
    textColor: 'text-orange-700',
    borderColor: 'border-orange-200'
  },
  MIXING: {
    label: 'Mixing',
    color: 'bg-cyan-500',
    lightColor: 'bg-cyan-100',
    textColor: 'text-cyan-700',
    borderColor: 'border-cyan-200'
  },
  MASTERING: {
    label: 'Mastering',
    color: 'bg-indigo-500',
    lightColor: 'bg-indigo-100',
    textColor: 'text-indigo-700',
    borderColor: 'border-indigo-200'
  },
  COMPLETE: {
    label: 'Complete',
    color: 'bg-green-500',
    lightColor: 'bg-green-100',
    textColor: 'text-green-700',
    borderColor: 'border-green-200'
  }
};

export const INITIAL_PROJECT = {
  title: '',
  artist: '',
  status: 'PLANNING',
  deadline: '',
  startDate: '',
  budget: '',
  genre: '',
  producer: '',
  notes: '',
  tasks: [],
  collaborators: [],
  tracks: []
}; 